import moment from "moment-timezone";
import User from "./User";
import Attachment from "./Attachment";

const KEYS = Object.freeze({
  ID: "_id",
  CREATED_AT: "created_at",
  ADMIN_USER: "admin_user",
  ASSIGNED_USER: "assigned_user",
  ASSIGNMENT: "assignment",
  SUBMISSION: "submission",
  FEEDBACK: "feedback",
  STATUS: "status",
});

class Task {
  _id = "";
  createdAt = moment();
  adminUser = new User();
  assignedUser = new User();
  assignmentDetails = {
    attachment: new Attachment(),
    description: "",
    timestamp: moment(),
    isDone: false,
  };
  submissionDetails = {
    attachment: new Attachment(),
    description: "",
    timestamp: moment(),
    isDone: false,
  };
  feedbackDetails = {
    criteria: [
      {
        name: "Criteria 1",
        score: 0,
      },
      {
        name: "Criteria 2",
        score: 0,
      },
      {
        name: "Criteria 3",
        score: 0,
      },
    ],
    rating: 0,
    comment: "",
    status: "",
    timestamp: moment(),
    isDone: false,
    maxWeigthageScore: 10,
    weigthageScore: 0,
    weigthageScorePercent: 0,
  };
  currentStep;

  fromMap = (map = {}) => {
    if (map[KEYS.ASSIGNED_USER])
      this.assignedUser.fromMap(map[KEYS.ASSIGNED_USER]);
    if (map[KEYS.ADMIN_USER]) this.adminUser.fromMap(map[KEYS.ADMIN_USER]);
    if (moment(map[KEYS.CREATED_AT]).isValid())
      this.createdAt = moment(map[KEYS.CREATED_AT]);

    if (map[KEYS.ASSIGNMENT]) {
      const assignment = map[KEYS.ASSIGNMENT];

      if (assignment.attachment) {
        const attachment = new Attachment().fromMap(assignment.attachment);
        this.assignmentDetails.attachment = attachment;
        this.assignmentDetails.isDone = true;
      }

      if (assignment.description) {
        this.assignmentDetails.description = assignment.description;
        this.assignmentDetails.isDone = true;
      }

      if (moment(assignment.timestamp).isValid()) {
        this.assignmentDetails.timestamp = moment(assignment.timestamp);
      }
    }

    if (map[KEYS.SUBMISSION]) {
      const submission = map[KEYS.SUBMISSION];

      if (submission.attachment) {
        const attachment = new Attachment().fromMap(submission.attachment);
        this.submissionDetails.attachment = attachment;
        this.submissionDetails.isDone = true;
      }

      if (submission.description) {
        this.submissionDetails.description = submission.description;
        this.submissionDetails.isDone = true;
      }

      if (moment(submission.timestamp).isValid()) {
        this.submissionDetails.timestamp = moment(submission.timestamp);
      }
    }

    if (map[KEYS.FEEDBACK]) {
      const feedback = map[KEYS.FEEDBACK];

      if (Array.isArray(feedback.criteria) && feedback.criteria.length > 0) {
        this.feedbackDetails.criteria = feedback.criteria;

        const maxWeigthageScore = feedback.criteria.length * 5;
        let weigthageScore = 0;
        feedback.criteria.forEach((c) => {
          weigthageScore += c.score;
        });
        const weigthageScorePercent =
          (weigthageScore / maxWeigthageScore) * 100;

        this.feedbackDetails.maxWeigthageScore = maxWeigthageScore;
        this.feedbackDetails.weigthageScore = weigthageScore;
        this.feedbackDetails.weigthageScorePercent = Number.parseInt(
          weigthageScorePercent
        );
      }

      if (feedback.comment) {
        this.feedbackDetails.comment = feedback.comment;
      }
      if (feedback.rating) {
        this.feedbackDetails.rating = feedback.rating;
      }

      if (feedback.status) {
        this.feedbackDetails.status = feedback.status;
        this.feedbackDetails.isDone = true;
        this.currentStep = STEPS[feedback.status];
      }

      if (moment(feedback.timestamp).isValid()) {
        this.feedbackDetails.timestamp = moment(feedback.timestamp);
      }
    }

    this.currentStep = STEPS.assign;
    if (this.feedbackDetails.isDone) {
      this.currentStep = STEPS[this.feedbackDetails.status];
    } else if (this.submissionDetails.isDone) {
      this.currentStep = STEPS.feedback;
    } else if (this.assignmentDetails.isDone) {
      this.currentStep = STEPS.submit;
    }

    if (this.assignedUser._id) this._id = this.assignedUser._id;

    return this;
  };
}

export const STATUS = Object.freeze({
  approved: "approved",
  rejected: "rejected",
});

const STEPS = Object.freeze({
  assign: { _id: "assign", color: "warning", message: "in review" },
  submit: { _id: "submit", color: "warning", message: "assignment pending" },
  feedback: { _id: "feedback", color: "warning", message: "in review" },
  rejected: { _id: "rejected", color: "red", message: "Rejected" },
  approved: { _id: "approved", color: "green", message: "Approved" },
});

Task.STATUS = STATUS;
Task.STEPS = STEPS;
Task.KEYS = KEYS;

export default Task;
