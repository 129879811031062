import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Avatar, Col, DatePicker, Row, Select, Button, Tag, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CustomModal from "../../components/CustomModal";

import { userActions } from "../../users/duck/userReducer";
import { itemActions } from "../../item/duck/itemReducer";
import {
  CaretDownOutlined,
  StarOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import User from "../../models/User";

import constants from "../../utils/constants";
import {
  disabledDate,
  getAvatarColor,
  getNameInitials,
  getStringToOptions,
  paramsToString,
} from "../../utils/commonFunctions";

import "./SelectFreelancerModal.scss";
import moment from "moment-timezone";
import roleTransformer from "../../components/RoleTransformer";
const { PROJECT_TASK_STATUS, ROLE_IDS, INDUSTRY } = constants;

const SelectFreelancerModal = (props) => {
  const { visible, projectTaskId, editable, costObject } = props;
  const prevProps = useRef();
  const dispatch = useDispatch();

  const [boardKey, setBoardKey] = useState("");
  const [showIndustry, setShowIndustry] = useState(false);

  const itemObj = useSelector(({ item }) => item.map[projectTaskId]);

  const { users, meta, loading } = useSelector(({ user }) => {
    const userMap = user.map;

    const boards = user.boards;
    const mBoard = boards[boardKey];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let users = list.map((userId) => userMap[userId]);
    users = users.filter((user) => user);

    return { users, meta, loading };
  });

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  const mUsers = useMemo(() => {
    let data = [...users];

    const isUserExists = data?.find(
      (obj) => obj?._id === itemObj?.assigned_freelancer?._id
    );

    if (data?.length && itemObj?.assigned_freelancer?._id && !isUserExists) {
      data.push(itemObj?.assigned_freelancer);
    }

    return data;
  }, [itemObj?.assigned_freelancer, users]);

  const loadMore = useCallback(
    (offset = 0, limit = 1000) => {
      if (loading) return;
      if (offset === 0 || meta?.totalCount > users?.length) {
        const { industry } = state;
        const params = {
          role_id: [
            ROLE_IDS.ADMIN,
            ROLE_IDS.FREELANCER,
            ROLE_IDS.EDITOR,
            ROLE_IDS.MANAGER,
          ],
          showTopRated: true,
          offset,
          limit,
        };
        if (industry) params.industry = industry;
        setBoardKey(paramsToString(params));
        dispatch(userActions.onGetAllRequest(params));
      }
    },
    [loading, meta?.totalCount, users?.length, state, dispatch]
  );

  useEffect(() => {
    loadMore();
  }, [state?.industry]);

  useEffect(() => {
    if (visible) {
      if (!prevProps.current?.visible && visible) {
        const assignedWriterHistory = itemObj?.meta?.history ?? [];
        const lastAssignedWriter =
          assignedWriterHistory?.[assignedWriterHistory?.length - 1];

        const assignedFreelancer =
          itemObj?.assigned_freelancer || lastAssignedWriter?.assignedWriter;

        setState({
          assignedFreelancer: assignedFreelancer?._id || assignedFreelancer,
          deadline: itemObj?.due_date && moment(itemObj?.due_date),
          writerRemark: lastAssignedWriter?.writer_remark,
          allottedAmountToWriter: itemObj?.meta?.allottedAmountToWriter ?? null,
        });
        setError({});
      } else if (prevProps.current?.loading && !itemObj?.loading) {
        if (!itemObj?.error) {
          props.handleModal();
        }
      }
    } else {
      setState({});
      setError({});
    }

    return () => {
      prevProps.current = {
        visible: visible,
        loading: itemObj?.loading,
      };
    };
  }, [
    itemObj?.assigned_freelancer,
    itemObj?.due_date,
    itemObj?.error,
    itemObj?.loading,
    itemObj?.meta?.allottedAmountToWriter,
    itemObj?.meta?.history,
    props,
    visible,
  ]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setShowIndustry(false);
      setError({});
    },
    []
  );

  const onFilterOption = useCallback((input, option) => {
    const user = option.props.user;
    const fullName = user.fullName.toLowerCase();
    const email = user.email.toLowerCase();
    const mInput = input.toLowerCase();

    return fullName.indexOf(mInput) >= 0 || email.indexOf(mInput) >= 0;
  }, []);

  const hasError = useCallback(() => {
    const error = {};

    if (!state.assignedFreelancer) {
      error.assignedFreelancer = "Please select freelancer";
    }

    if (!state.deadline) {
      error.deadline = "Please select deadline";
    } else {
      const today = moment();
      if (today.diff(state.deadline, "days") > 0) {
        error.deadline = "Please select future date for deadline";
      }
    }

    if (costObject?.costType) {
      if (costObject?.budgetAmount <= 0) {
        error.allottedAmountToWriter =
          "Budget cannot be 0. Please increase the budget to procedd further";
      } else if (!state.allottedAmountToWriter) {
        error.allottedAmountToWriter = "Please enter cost for the task";
      } else if (Number(state.allottedAmountToWriter) <= 0) {
        error.allottedAmountToWriter = "Cost must be greater than 0";
      } else if (
        Number(state.allottedAmountToWriter) > Number(costObject?.budgetAmount)
      ) {
        error.allottedAmountToWriter = "Cost cannot be greater than budget";
      }
    }

    setError(error);

    return !!Object.keys(error).length;
  }, [
    costObject?.budgetAmount,
    costObject?.costType,
    state.allottedAmountToWriter,
    state.assignedFreelancer,
    state.deadline,
  ]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const { assignedFreelancer, deadline, allottedAmountToWriter } = state;

      const due_date = moment(deadline).isValid()
        ? moment(state.deadline).toString()
        : null;

      const payload = {
        _id: projectTaskId,
        assigned_freelancer: assignedFreelancer,
        due_date,
        status_description: PROJECT_TASK_STATUS.IN_PROGRESS,
        meta: {
          ...itemObj?.meta,
          allottedAmountToWriter: Number(allottedAmountToWriter),
        },
      };

      dispatch(itemActions.onUpdateOneRequest(payload));
    }
  }, [hasError, state, projectTaskId, itemObj?.meta, dispatch]);

  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        {editable && (
          <Button
            className="new_button mr5"
            type="primary"
            onClick={onSubmit}
            size="large"
            loading={itemObj?.loading}
          >
            Done
          </Button>
        )}
        <Button
          className="new_button"
          type="primary-outline"
          onClick={props?.handleModal}
          size="large"
        >
          Cancel
        </Button>
      </Row>
    );
  }, [props?.handleModal, editable, onSubmit, itemObj?.loading]);

  return (
    <CustomModal
      className="select-freelancer-modal-container"
      visible={visible}
      title={"Select Writer"}
      width={"45vw"}
      footer={renderFooter}
      onCancel={props.handleModal}
    >
      {/* Project Freelancer start */}
      <Col className="mb20">
        <Col className="mb10">
          <Row justify="space-between">
            <Col>
              <label className="value">
                {showIndustry ? "Industry" : "Writer"}
              </label>
              {!showIndustry && <sup style={{ color: "red" }}>*</sup>}
            </Col>

            <span
              className=" pointer"
              onClick={() => setShowIndustry(!showIndustry)}
            >
              Filter by Industry
              <SwapOutlined className="ml5" />
              {state?.industry && (
                <span className="text-blue-900"> {state?.industry}</span>
              )}
            </span>
          </Row>
        </Col>

        {showIndustry ? (
          <Select
            className="fw"
            size="large"
            dropdownMatchSelectWidth={false}
            suffixIcon={<CaretDownOutlined className="f15" />}
            placeholder={"Filter freelancer on the basis of industry"}
            value={state?.industry}
            onChange={handleChange("industry")}
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            showSearch
          >
            {getStringToOptions(INDUSTRY).map((option, i) => (
              <Select.Option key={i} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Col>
            <Select
              className="fw mch"
              size="large"
              placeholder="Select Writer"
              value={state.assignedFreelancer}
              onChange={handleChange("assignedFreelancer")}
              dropdownMatchSelectWidth={false}
              suffixIcon={<CaretDownOutlined className="f15" />}
              optionFilterProp="children"
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={onFilterOption}
              disabled={itemObj?.loading}
              allowClear
              showSearch
            >
              {mUsers?.map((userMap, index) => {
                const user = new User().fromMap(userMap);

                return (
                  <Select.Option key={index} value={user._id} user={user}>
                    <Row align="middle">
                      <Col className="mr10">
                        <Avatar
                          size={35}
                          style={{ backgroundColor: getAvatarColor(user._id) }}
                        >
                          {getNameInitials(
                            user.fullName ? user.fullName : user.email
                          )}
                        </Avatar>
                      </Col>
                      <Row className="name-container" align="stretch">
                        <Col>
                          <Col className="overflow-ellipse capitalize pri-bold">
                            {user.fullName ? `${user.fullName}` : user.email}
                            <span className="ml20">
                              <Tag>
                                {userMap?.role?.name ??
                                  roleTransformer?.(userMap?.role_id)}
                              </Tag>
                            </span>
                          </Col>
                          <Col>{user.email}</Col>
                        </Col>
                      </Row>
                      {userMap?.avg_rating && (
                        <span style={{ marginLeft: "auto" }} className="ml20">
                          {userMap?.avg_rating?.toFixed(1)}
                          <StarOutlined />
                        </span>
                      )}
                    </Row>
                  </Select.Option>
                );
              })}
            </Select>

            <Col className="error">{error.assignedFreelancer}</Col>
          </Col>
        )}
      </Col>
      {/* Project Freelancer end */}

      {/* due date start */}
      <Col className="mb20">
        <Col className="mb10">
          <label className="label">Deadline</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col>
          <DatePicker
            style={{
              borderRadius: 6,
              padding: 10,
            }}
            className="custom-input fw"
            size="large"
            format="DD-MM-YYYY"
            value={state.deadline}
            onChange={handleChange("deadline")}
            disabledDate={disabledDate}
            disabled={itemObj?.loading}
          />

          <Col className="error">{error.deadline}</Col>
        </Col>
      </Col>
      {/* due date end */}

      {/* price type start */}
      {!!costObject?.costType && (
        <Col className="mb20">
          <Col className="mb10">
            <label className="label">Cost type</label>
          </Col>

          <Col>
            <Tag style={{ fontSize: 14 }}>{costObject?.costType}</Tag>
          </Col>
        </Col>
      )}
      {/* price type end */}

      {/* price start */}
      {!!costObject?.costType && (
        <Col className="mb20">
          <Col className="mb10">
            <label className="label">Cost</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col>
            <Input
              style={{
                borderRadius: 6,
                padding: 10,
              }}
              type="number"
              placeholder="Enter Cost"
              className="fw"
              size="large"
              step={0.1}
              min={0}
              max={costObject?.budgetAmount}
              value={state?.allottedAmountToWriter}
              onChange={handleChange("allottedAmountToWriter")}
              disabled={itemObj?.loading}
            />

            <Col className="error">{error?.allottedAmountToWriter}</Col>
          </Col>

          <Col>
            <Col className="mb15">
              <label>Budget:</label>
              <span style={{ fontWeight: 500, fontSize: 16 }} className="ml5">
                {costObject.currencySymbol} {costObject?.budgetAmount}
              </span>
            </Col>
          </Col>
        </Col>
      )}

      {/* price end */}

      {/* remark start */}
      {itemObj?.status_description === PROJECT_TASK_STATUS.INITIATE &&
        state?.writerRemark && (
          <Col className="mb20">
            <Col className="mb10">
              <label className="label">Writer Remark</label>
            </Col>

            <Col>{state?.writerRemark}</Col>
          </Col>
        )}
      {/* remark end */}
    </CustomModal>
  );
};

export default React.memo(SelectFreelancerModal);
