import MSaga from "../../helper/MSaga";
import { assessmentActions } from "./assessmentReducer";
import appUrl from "../../config/appUrl";

const assessmentSaga = new MSaga({
  name: "assessment",
  apiEndPoint: appUrl.ASSESSMENTS,
  actions: assessmentActions,
  messages: {
    updateOne: {
      success: "Assessment Updated",
      error: "",
    },
  },
});

export default assessmentSaga.getWatchSaga();
