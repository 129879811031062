import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row, Button, Input, DatePicker, Checkbox, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ProjectRowItem from "./components/ProjectRowItem";
import CreateProjectModal from "./components/CreateProjectModal";
import { SearchOutlined } from "@ant-design/icons";
import PlusIcon from "../assets/images/plusIcon.svg";
import { projectActions } from "./duck/projectReducer";
import { disabledFutureDate, paramsToString } from "../utils/commonFunctions";

import CalendarIcon from "../assets/images/Calendar.svg";
import "./ProjectList.scss";
import constants from "../utils/constants";
import moment from "moment-timezone";
import routes from "../utils/routes";
import MyTable from "../components/MyTable";
import { useUserPermissions } from "../utils/customHooks";

const { PROJECT_STATUS } = constants;

const ProjectList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const prevProps = useRef();

  const mPermissions = useUserPermissions();
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [boardKey, setBoardKey] = useState("{draft: false,archive:false}");

  const [state, setState] = useState({
    search: "",
    project_status: [],
    callDebounce: true,
  });

  const project = useSelector(({ project }) => project);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = project.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [project, boardKey]);

  const filters = useMemo(
    () => project?.filters?.[location?.pathname],
    [location?.pathname, project?.filters]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 10) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { project_status, search, start_date } = state;

        const params = {
          pathname: location?.pathname,
          draft: false,
          archive: false,
          offset,
          limit,
        };
        if (search) params.search = search;
        if (start_date)
          params.start_date = moment(start_date).startOf("day").utc().toDate();
        if (project_status?.length) params.project_status = project_status;

        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, location?.pathname, meta?.totalCount, state]
  );

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      let parsedData = JSON.parse(filters);
      if (parsedData?.start_date) {
        parsedData.start_date = moment(parsedData?.start_date);
      }
      setState({
        project_status: [],
        callDebounce: false,
        ...parsedData,
      });
    } else {
      loadMore();
    }
  }, []);

  useEffect(() => {
    if (prevProps.current?.loading && !project?.loading) {
      if (!project?.error && project?.newCreatedDataId) {
        navigate(
          `${routes.CREATE_PROJECT_FLOW}?draft_id=${project?.newCreatedDataId}`
        );
      }
    }

    return () => {
      prevProps.current = { loading: project?.loading };
    };
  }, [dispatch, navigate, project]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      setState((preState) => ({
        ...preState,
        [name]: value,
        callDebounce: true,
      }));
    },
    []
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!!list && !loading && state?.callDebounce) {
        loadMore();
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [state]);

  const handleCreateProjectModal = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setShowCreateProject(show);
  }, []);

  const onCreateProject = useCallback(() => {
    dispatch(projectActions.onCreateOneRequest());
  }, [dispatch]);

  const buttonValues = useMemo(() => {
    const { project_status } = state;
    const length = project_status?.length;
    let text = [];
    let value;

    if (length === 0 || length === Object.keys(PROJECT_STATUS)?.length) {
      text = "All Projects";
      value = meta?.totalCount;
    } else {
      value = project_status?.reduce(
        (acc, value) => acc + (meta?.[value] ?? 0),
        0
      );
      Object.values(PROJECT_STATUS).forEach((obj) => {
        if (project_status?.includes(obj._id)) text.push(obj.name);
      });
      text = text?.join(", ");
    }

    return { text, value };
  }, [meta, state]);

  const columns = useMemo(
    () => [
      { key: 1, title: "Name" },
      { key: 2, title: "Progress" },
      { key: 3, title: "Word Count" },
      { key: 4, title: "Created By" },
      { key: 5, title: "Assigned To" },
      { key: 6, title: "Status" },
    ],
    []
  );

  return (
    <div className="content-body app-container project-list-container">
      <Row justify="space-between" gutter={[10, 10]}>
        <Row justify="start" wrap={false}>
          <Input.Group style={{ width: "auto", marginRight: 10 }} compact>
            <Popover
              getPopupContainer={(triggerNode) => triggerNode}
              trigger="hover"
              showArrow={false}
              overlayStyle={{
                padding: 0,
                width: 160,
              }}
              content={
                <Checkbox.Group
                  value={state?.project_status}
                  onChange={handleChange("project_status")}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: 112,
                    paddingBottom: 10,
                  }}
                >
                  {Object.values(PROJECT_STATUS).map((obj) => (
                    <Checkbox
                      style={{
                        margin: 0,
                        width: "max-content",
                      }}
                      key={obj._id}
                      value={obj._id}
                    >
                      <span className="project-counter pri-box-shadow">
                        {meta?.[obj._id] ?? 0}
                      </span>
                      &nbsp; {obj?.name}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              }
              placement="bottomLeft"
            >
              <Button className="new_button" type="primary" size="large">
                <Row align="middle" wrap={false}>
                  <span className="project-counter project-counter-btn">
                    {buttonValues?.value || 0}
                  </span>
                  &nbsp;{buttonValues?.text?.toString()}
                </Row>
              </Button>
            </Popover>
          </Input.Group>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Search Project Name"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Row>
        <Row justify="end" gutter={[10, 10]}>
          <Col
            xs={24}
            sm={mPermissions?.project?.create ? 12 : 24}
            lg={mPermissions?.project?.create ? 12 : 15}
            xl={mPermissions?.project?.create ? 10 : 15}
          >
            <DatePicker
              size="large"
              format={"DD MMM, YYYY"}
              className="transparent_picker fw"
              onChange={handleChange("start_date")}
              suffixIcon={
                <img width={15} src={CalendarIcon} alt="calendar icon" />
              }
              disabledDate={disabledFutureDate}
              placeholder="Start Date"
              value={state?.start_date}
              inputReadOnly={true}
              allowClear
            />
          </Col>
          {mPermissions?.project?.create && (
            <Col xs={24} sm={12} md={12} lg={12} xl={10}>
              <Button
                className="new_button fw"
                type="primary"
                size="large"
                onClick={onCreateProject}
              >
                <img src={PlusIcon} alt="plus icon" className="mr10" />
                New Project
              </Button>
            </Col>
          )}
        </Row>
      </Row>

      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          renderItem={(item, i) => (
            <ProjectRowItem key={i} id={item} boardKey={boardKey} />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>

      <CreateProjectModal
        visible={showCreateProject}
        handleModal={handleCreateProjectModal}
      />
    </div>
  );
};

export default React.memo(ProjectList);
