import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Avatar, Button, Col, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";

import CustomModal from "../../components/CustomModal";
import { projectActions } from "../duck/projectReducer";

import constants from "../../utils/constants";
import {
  getAvatarColor,
  getNameInitials,
  validEmail,
} from "../../utils/commonFunctions";

import "./SelectManagerModal.scss";

const ShareWithModal = (props) => {
  const { visible, projectId, isProjectEditable, sharedWith } = props;

  const prevProps = useRef();
  const dispatch = useDispatch();

  const projectObj = useSelector(({ project }) => project.map[projectId]);

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (prevProps.current?.loading && !projectObj?.loading) {
      if (!projectObj?.error) {
        props.handleModal();
      }
    } else if (!prevProps?.current?.visible && visible) {
      setState({
        sharedWith,
        email: [],
        removedIds: [],
      });
    }

    return () => {
      prevProps.current = {
        visible: props.visible,
        loading: projectObj?.loading,
      };
    };
  }, [visible, projectObj, props, sharedWith]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const handleRemove = useCallback(
    (id) => {
      const { sharedWith, removedIds } = state;

      setState((prevState) => ({
        ...prevState,
        sharedWith: sharedWith.filter((user) => user?._id !== id),
        removedIds: [...removedIds, id],
      }));
    },
    [state]
  );

  const hasError = useCallback(() => {
    const { email } = state;
    const error = {};

    if (email?.length) {
      email?.forEach((element) => {
        if (!validEmail(element)) {
          error.email = "Please enter valid email";
        }
      });
    }
    setError(error);

    return !!Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const payload = {
        _id: projectId,
        role_id: constants.ROLE_IDS.CLIENT,
        email: state.email,
        removeSharedUserIds: state.removedIds,
      };
      dispatch(projectActions.onShareWithRequest(payload));
    }
  }, [hasError, dispatch, projectId, state]);

  const renderFooter = useMemo(() => {
    return (
      <Row>
        {isProjectEditable && (
          <Button
            className="new_button"
            type="primary"
            onClick={onSubmit}
            size="large"
            loading={projectObj?.loading}
          >
            Done
          </Button>
        )}
        <Button
          className="new_button"
          type="primary-outline"
          onClick={props?.handleModal}
          size="large"
        >
          Cancel
        </Button>
      </Row>
    );
  }, [props?.handleModal, isProjectEditable, onSubmit, projectObj?.loading]);

  return (
    <CustomModal
      title="Share"
      className="select-manager-modal-container"
      visible={visible}
      footer={renderFooter}
      onCancel={props.handleModal}
      width={"max-content"}
      bodyStyle={{
        height: "auto",
        maxHeight: "60vh",
        overflowY: "scroll",
        paddingTop: 0,
      }}
    >
      <Col>
        <Row className="mt10 mb10">
          Share with people who have to collaborate on this project.
        </Row>
        {/* email start */}
        <Row className="mb10" align="middle">
          <Col span={24}>
            <Select
              className="fw"
              mode="tags"
              placeholder="Enter emails"
              value={state.email}
              onChange={handleChange("email")}
              open={false}
              showArrow={false}
              filterOption={false}
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={projectObj?.loading}
            />

            <Col className="error">{error.email}</Col>
          </Col>
        </Row>
        {/* email end */}

        {/* shared user list*/}
        <Col>
          {state?.sharedWith?.map((obj) => (
            <Row
              align="middle"
              justify="space-between"
              key={obj?._id}
              className="mb5 "
              wrap={false}
            >
              <Col className="overflow-ellipse inline-block">
                <Avatar
                  style={{
                    backgroundColor: getAvatarColor(obj._id),
                  }}
                >
                  {getNameInitials(obj?.fullName ? obj?.fullName : obj?.email)}
                </Avatar>
                <span className="ml10 ">
                  {obj.fullName ? `${obj.fullName} (${obj.email})` : obj.email}
                </span>
              </Col>
              {isProjectEditable && (
                <CloseCircleOutlined
                  style={{
                    fontSize: "1.2rem",
                    cursor: "pointer",
                    marginLeft: 20,
                  }}
                  onClick={() => handleRemove(obj._id)}
                />
              )}
            </Row>
          ))}
        </Col>
        {/* shared user list*/}
      </Col>
    </CustomModal>
  );
};

export default React.memo(ShareWithModal);
