import MSaga from "../../helper/MSaga";
import { assessmentTestActions } from "./assessmentTestReducer";
import appUrl from "../../config/appUrl";

const assessmentTestSaga = new MSaga({
  name: "assessmentTest",
  apiEndPoint: appUrl.ASSESSMENT_TEST,
  actions: assessmentTestActions,
});

export default assessmentTestSaga.getWatchSaga();
