import React, { useLayoutEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Result, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import AppUrl from "../config/appUrl";
import { isAuthenticated, showNotification } from "../utils/commonFunctions";
import routes from "../utils/routes";
import constants from "../utils/constants";

function VerifyUser() {
  const navigate = useNavigate();
  const token = useLocation()?.search?.split("=")?.[1];

  const activationEmail = async () => {
    try {
      const response = await axios.get(AppUrl.VERIFY_EMAIL, {
        params: { token },
      });
      const data = response.data;

      const meta = data?.meta;
      if (meta?.success !== true || meta?.status !== 200) {
        showNotification("error", meta?.message);
      } else {
        const { token, user } = data.data;

        localStorage.setItem(constants.ACCESS_TOKEN, token);

        navigate(routes.DASHBOARD, {
          state: { autoNavigate: true },
          replace: true,
        });
      }
    } catch (error) {
      showNotification("error", error);
    }
  };

  useLayoutEffect(() => {
    if (token) {
      activationEmail();
    }
  }, [token]);

  if (isAuthenticated()) {
    return (
      <Navigate to={routes.DASHBOARD} state={{ autoNavigate: true }} replace />
    );
  }

  return (
    <Row justify="center" align="middle" className="fh">
      <Result
        icon={<SettingOutlined spin />}
        title="Please wait, your account is getting activated!"
      />
    </Row>
  );
}

export default VerifyUser;
