import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../components/Loading";
import NotFound from "../components/NotFound";
import {Col} from "antd";
import FormBuilder from "../formBuilder/FormBuilder"
import {templateActions} from "./duck/templateReducer";


const TemplateDetails = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const templateObj = useSelector(({ template }) => template.map[id]);

    useEffect(() => {
        dispatch(templateActions.onGetOneRequest({ _id: id }));
    }, [id, dispatch]);
    // check if project is fetching
    if (!templateObj?._id || !templateObj?.fields) {
        return <Loading />;
    }
    // check if project exist
    else if (!templateObj?._id) {
        return <NotFound subTitle={"Sorry, this template does not exist."} />;
    }

    return (
        <FormBuilder template={templateObj} />
    )




}


export default React.memo(TemplateDetails);