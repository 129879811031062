import { Avatar, Col, Row, Tag, Tooltip } from "antd";
import React, { memo } from "react";
import {
  getAvatarColor,
  getNameInitials,
  textWrapper,
} from "../utils/commonFunctions";
import { UserOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import roleTransformer from "./RoleTransformer";

const NameWithAvatar = (props) => {
  const {
    user = {},
    avatarSize = "default",
    onClick = () => {},
    showFullName = false,
    showFirstName = false,
    avatarOnly = false,
    showToolTip = false,
    showRole = false,
  } = props;
  const nameOrEmail = user?.fullName ? user?.fullName : user?.email;

  const handleName = useCallback(() => {
    if (showFirstName) {
      return user?.firstName;
    } else if (showFullName) {
      return user?.fullName;
    } else {
      return textWrapper(user?.fullName);
    }
  }, [showFirstName, showFullName, user?.firstName, user?.fullName]);

  if (!user?.email) {
    return (
      <>
        <Avatar icon={<UserOutlined />} />
        <span className="ml10">Unassigned</span>
      </>
    );
  }
  return (
    <Tooltip title={showToolTip ? nameOrEmail : ""}>
      <Avatar
        className="pointer"
        style={{
          backgroundColor: getAvatarColor(user?._id),
        }}
        size={avatarSize}
        onClick={onClick}
      >
        {getNameInitials(nameOrEmail)}
      </Avatar>
      {!avatarOnly && <span className="ml10">{handleName()}</span>}
      {showRole && (
        <Col style={{ position: "relative", bottom: 8, left: 40 }}>
          <Tag>{roleTransformer(user?.role_id)}</Tag>
        </Col>
      )}
    </Tooltip>
  );
};

export default memo(NameWithAvatar);
