import React from "react";
import { Col, Row, Switch } from "antd";

import SubsectionItem from "./SubsectionItem";

const SectionItem = ({ section = {}, handleChange, disabled }) => {
  const onChange = (name) => (value) => {
    let updatedSection = { ...section, [name]: value };

    if (name === "show" && !value) {
      Object.keys(updatedSection).forEach((s) => {
        let mSubsection = updatedSection[s];

        if (typeof updatedSection[s] === "boolean") {
          updatedSection[s] = false;
        } else if (typeof mSubsection === "object") {
          Object.keys(mSubsection).forEach((mSKey) => {
            if (typeof mSubsection[mSKey] === "boolean") {
              mSubsection[mSKey] = false;
            }
          });
        }
      });
    } else if (name === "show" || name === "editable" || name === "download") {
      Object.keys(updatedSection).forEach((s) => {
        let mSubsection = updatedSection[s];
        if (typeof mSubsection === "object") {
          mSubsection[name] = value;
        }
      });
    } else {
      let show = true;
      let download = true;
      let editable = true;

      Object.keys(updatedSection).forEach((s) => {
        let mSubsection = updatedSection[s];
        if (typeof mSubsection === "object") {
          show = show && mSubsection?.show;
          download = show && download && mSubsection?.download;
          editable = show && editable && mSubsection?.editable;
        }
      });

      updatedSection = { ...updatedSection, show, download, editable };
    }

    handleChange && handleChange(updatedSection);
  };

  return (
    <>
      <thead>
        <tr>
          <th>
            <Row className="text-left pl15">{section?.name}</Row>
          </th>

          <th>
            <Row align="middle">
              <Col className="text-right" span={9}>
                Hide
              </Col>

              <Col span={6}>
                <Switch
                  disabled={disabled}
                  onChange={onChange("show")}
                  checked={section?.show}
                />
              </Col>

              <Col className="text-left" span={9}>
                Show
              </Col>
            </Row>
          </th>

          <th>
            <Row align="middle">
              <Col className="text-right" span={9}></Col>

              <Col span={6}>
                <Switch
                  onChange={onChange("editable")}
                  disabled={!section?.show || disabled}
                  checked={section?.editable}
                />
              </Col>

              <Col className="text-left" span={9}>
                Edit
              </Col>
            </Row>
          </th>
        </tr>
      </thead>

      <tbody>
        {Object.keys(section).map(
          (subSection) =>
            subSection &&
            typeof section[subSection] === "object" && (
              <SubsectionItem
                key={subSection}
                section={section[subSection]}
                handleChange={onChange(subSection)}
                disabled={disabled}
              />
            )
        )}
      </tbody>
    </>
  );
};

export default SectionItem;
