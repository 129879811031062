import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import rolePermissions from "./rolePermissions";

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useUserPermissions = () => {
  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);

  return useMemo(() => {
    const permissions = loggedInUser?.role?.permissions;

    return Object.assign({}, rolePermissions, {
      ...permissions,
      module: Object.assign({}, rolePermissions.module, permissions?.module),
    });
  }, [loggedInUser]);
};

export const useDebounce = () => {
  const [state, setState] = useState({});

  useEffect(() => {
    const { callback, delay } = state;

    let handlerTimer;
    if (callback) handlerTimer = setTimeout(callback, delay);

    return () => clearTimeout(handlerTimer);
  }, [state]);

  return useCallback((callback = () => {}, delay = 1500) => {
    setState((prevState) => ({ ...prevState, callback, delay }));
  }, []);
};
