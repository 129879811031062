import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Input, Row, Select, Checkbox } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ReloadOutlined, LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import GenerateContentContainer from "./components/GenerateContentContainer";
import MRadioGroup from "../components/MRadioGroup";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import Loading from "../components/Loading";

import { openAiActions } from "./duck/openAiReducer";
import { useQuery } from "../utils/customHooks";

import {
  arrayToMap,
  hasApiCallError,
  showNotification,
} from "../utils/commonFunctions";
import appUrl from "../config/appUrl";
import constants from "../utils/constants";
import routes from "../utils/routes";

import "./OpenAi.scss";

const {
  CONTENT_VOICE_TONES,
  AUTO_GENERATE_CONTENT_TYPES,
  OPEN_AI_TASK_FIELDS,
} = constants;

const setps = AUTO_GENERATE_CONTENT_TYPES.BLOG.steps;

const DEFAULT_STEP = 1;
const MAX_STEPS = 4;

const GenerateBlog = () => {
  const prevProps = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const openAi = useSelector(({ openAi }) => openAi);
  const mTask = useMemo(() => {
    const taskId = query.get("task_id");
    return openAi.map[taskId] || {};
  }, [query, openAi.map]);

  const [state, setState] = useState({
    currentStep: DEFAULT_STEP,
    tone: CONTENT_VOICE_TONES[3],
    subheadings: [],
  });
  const [error, setError] = useState({});
  const [responseTitles, setResponseTitles] = useState([]);
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.isLoadingExpandAllSubheading) {
      const { subheadings } = state;
      const allExpandSubheadingLoading = subheadings.map(
        (subheading) => state[subheading]?.loading
      );
      if (allExpandSubheadingLoading.every((loading) => !loading)) {
        setState((prevState) => ({
          ...prevState,
          isLoadingExpandAllSubheading: false,
          currentStep: prevState.currentStep + 1,
        }));
      }
    }
  }, [state]);

  const hasError = useCallback(() => {
    let { currentStep, metaTagDescription, tone, keywords, title } = state;
    metaTagDescription = metaTagDescription?.trim?.();
    tone = tone?.trim?.();
    keywords = keywords?.trim?.();
    title = title?.trim?.();

    const error = {};

    if (currentStep === 1) {
      if (!metaTagDescription) {
        error.metaTagDescription =
          "Please enter description your product or service";
      }

      if (!tone) {
        error.tone = "Please select Tone of the content";
      }

      if (!keywords) {
        error.keywords = "Please enter some primary keywords";
      }
    } else if (currentStep === 2) {
      if (!title) {
        error.title = "Please select title";
        showNotification("error", "Please select Blog Topic");
      }
    }

    setError(error);
    return Object.keys(error).length > 0;
  }, [state]);

  const generateTitlesApi = useCallback(
    (temperature) => {
      if (temperature || !hasError()) {
        // setLoading(true);

        let { currentStep, metaTagDescription, tone, keywords } = state;
        metaTagDescription = metaTagDescription?.trim?.();
        tone = tone?.trim?.();
        keywords = keywords?.trim?.();

        let text = `Generate 5 blog topics on ${metaTagDescription} using tone ${tone}, and keywords ${keywords}.`;
        const payload = { text };
        if (temperature) payload.temperature = 0.5;
        if (mTask?._id) payload.task_id = mTask?._id;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              setResponses((prevResponses) => ({
                ...prevResponses,
                [currentStep]: resData,
              }));

              const choiceText = resData?.data?.content;

              let titles = choiceText?.split?.("\n") || [];
              if (Array.isArray(titles)) {
                titles = titles.filter((title) => title?.trim?.());
                titles = titles.map((title, i) => {
                  title =
                    title
                      ?.match?.(/(?:"[^"]*"|^[^"]*$)/)?.[0]
                      ?.replace?.(/"/g, "") || title;

                  if (title.startsWith(`${i + 1}.`)) {
                    title = title?.replace(`${i + 1}.`, "");
                  }

                  return title?.trim?.();
                });
                titles = titles.filter((title) => title?.trim?.());

                setResponseTitles(titles);
              }

              setState((prevState) => ({
                ...prevState,
                title: "",
                isTitleRegenerate: false,
                currentStep: temperature
                  ? prevState.currentStep
                  : prevState.currentStep + 1,
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            setLoading(false);
            setState((prevState) => ({
              ...prevState,
              isTitleRegenerate: false,
            }));
          });
      }
    },
    [state, mTask, hasError]
  );

  const generateSubheadingApi = useCallback(
    (temperature) => {
      if (temperature || !hasError()) {
        setLoading(true);

        let { currentStep, title } = state;
        title = title?.trim?.();

        let text = `generate 3 subheading on ${title} with 5 words.`;
        text = `${text} First subheading should be based on Interest of the topic.`;
        text = `${text} The second subheading should be based on the user's desire for the topic.`;
        text = `${text} third subheading should be some call to action related to the topic.`;
        text = `${text} Don't repeat the company name or common words in every subheading.`;
        text = `${text} Don't use words like CTA, Action, Interest and Desire.`;

        const payload = { text };
        if (temperature) payload.temperature = 0.5;
        if (mTask?._id) payload.task_id = mTask?._id;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              const choiceText = resData?.data?.content;
              let subheadings = [];
              if (typeof choiceText === "string") {
                subheadings = choiceText?.split?.("\n") || [];
                subheadings = subheadings.filter(
                  (title) => title?.trim?.() && title !== ", etc."
                );
                subheadings = subheadings.map((title, i) => {
                  title =
                    title
                      ?.match?.(/(?:"[^"]*"|^[^"]*$)/)?.[0]
                      ?.replace?.(/"/g, "") || title;

                  if (title.startsWith(`${i + 1}.`)) {
                    title = title?.replace(`${i + 1}.`, "");
                  }

                  return title?.trim?.();
                });

                subheadings = subheadings.filter((title) => title?.trim?.());
                subheadings = [
                  "Introduction",
                  ...subheadings,
                  "Conclusion",
                  // "Faqs",
                ];
              }

              setResponses((prevResponses) =>
                Object.assign({}, prevResponses, { [currentStep]: resData })
              );
              setState((prevState) => ({
                ...prevState,
                subheadings,
                isSubheadingRegenerate: false,
                currentStep: temperature
                  ? prevState.currentStep
                  : prevState.currentStep + 1,
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log("generate content subheadings error", err);
            showNotification("error", "Something went wrong");
            setLoading(false);
            setState((prevState) => ({
              ...prevState,
              isSubheadingRegenerate: false,
            }));
          });
      }
    },
    [state, mTask, hasError]
  );

  const generateExpandSubheadingApi = useCallback(
    (subheading = "", index = 0, temperature) => {
      if (temperature || !hasError()) {
        setState((prevState) => ({
          ...prevState,
          [subheading]: Object.assign({}, prevState[subheading], {
            loading: true,
          }),
        }));

        let { currentStep, title, metaTagDescription } = state;
        subheading = subheading?.trim?.();

        let text = "";
        if (index === 0) {
          text = `Give an ${subheading} on the ${title} in 200 words to hook in the users.`;
        } else if (index === 1) {
          text = `Expand ${subheading} in 300 words. Use some bulletin points, facts or statistics.`;
        } else if (subheading === "Faqs") {
          text = `${metaTagDescription} Write 7 copomon faqs on ${title}`;
        } else {
          text = `Give an ${subheading} on the ${title} in 300 words to hook in the users.`;
        }

        const payload = { text };
        if (temperature) payload.temperature = 0.5;
        if (mTask?._id) payload.task_id = mTask?._id;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              let choiceText = resData?.data?.content;
              if (typeof choiceText === "string") {
                choiceText = choiceText.replace(/Interest:/g, "");
                choiceText = choiceText.replace(/Desire:/g, "");
                choiceText = choiceText.replace(/Action:/g, "");

                while (choiceText.startsWith("\n")) {
                  choiceText = choiceText.replace("\n", "");
                }
              }

              setResponses((prevResponses) =>
                Object.assign({}, prevResponses, {
                  [`${currentStep}-${subheading}`]: resData,
                })
              );
              setState((prevState) => ({
                ...prevState,
                [subheading]: Object.assign({}, prevState[subheading], {
                  description: choiceText || prevState.description,
                  loading: false,
                }),
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            setState((prevState) => ({
              ...prevState,
              [subheading]: Object.assign({}, prevState[subheading], {
                loading: false,
              }),
            }));
          });
      }
    },
    [state, mTask, hasError]
  );

  const generateExpandAllSubheadingApi = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isLoadingExpandAllSubheading: true,
    }));

    let subheadings = [...state.subheadings];
    if (state.isAddFAQ) {
      subheadings.push("Faqs");
    }

    subheadings.forEach(
      (subheading, i) =>
        subheading && generateExpandSubheadingApi(subheading, i)
    );
  }, [state.subheadings, state.isAddFAQ, generateExpandSubheadingApi]);

  const generateFinalDraft = useCallback(() => {
    let { subheadings } = state;
    let finalDraft = "";
    subheadings.forEach((subheading) => {
      if (finalDraft) finalDraft = `${finalDraft}<br><br>`;
      finalDraft = `${finalDraft}<h2><strong>${subheading}</strong></h2>`;

      let description = state[subheading]?.description;
      if (typeof description === "string") {
        description = description.replaceAll?.("\n", "<br>");
        finalDraft = `${finalDraft}<p>${description}</p>`;
      }
    });

    setState((prevState) => ({
      ...prevState,
      finalDraft,
      currentStep: prevState.currentStep + 1,
    }));
  }, [state]);

  const onGoBack = useCallback(() => {
    if (state.currentStep <= 1) {
      navigate(-1);
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  }, [state.currentStep, navigate]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const handleSubheadingDescriptionChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => ({
        ...prevState,
        [name]: Object.assign({}, prevState[name], {
          description: value,
        }),
      }));
      setError({});
    },
    []
  );

  const currentStepProgress = useMemo(() => {
    return ((state.currentStep - 1) / MAX_STEPS) * 100;
  }, [state.currentStep]);

  const onSubmit = useCallback(() => {
    if (state.currentStep === 1) {
      setLoading(true);
      generateTitlesApi();
    } else if (state.currentStep === 2) {
      generateSubheadingApi();
    } else if (state.currentStep === 3) {
      generateExpandAllSubheadingApi();
    } else if (state.currentStep === 4) {
      generateFinalDraft();
    }
  }, [
    state.currentStep,
    generateTitlesApi,
    generateSubheadingApi,
    generateExpandAllSubheadingApi,
    generateFinalDraft,
  ]);

  const onRegenerate = useCallback(() => {
    const temperature = Number(Math.random().toFixed(2));

    if (state.currentStep === 2) {
      setState((prevState) => ({
        ...prevState,
        title: null,
        isTitleRegenerate: true,
      }));
      generateTitlesApi(temperature);
    } else if (state.currentStep === 3) {
      setState((prevState) => ({
        ...prevState,
        isSubheadingRegenerate: true,
      }));
      generateSubheadingApi(temperature);
    }
  }, [state.currentStep, generateTitlesApi, generateSubheadingApi]);

  const disabledAll = loading;

  useEffect(() => {
    if (
      prevProps.current?.loading &&
      !openAi?.loading &&
      state.isTaskCreating
    ) {
      const searchParams = {
        task_id: openAi?.newCreatedDataId,
      };

      const navigateTo = {
        search: `?${createSearchParams(searchParams)}`,
      };
      navigate(navigateTo, { replace: true });
      setState((prevState) => ({ ...prevState, isTaskCreating: false }));
    } else if (
      prevProps.current?.loading &&
      !mTask?.loading &&
      state.isTaskSaving
    ) {
      if (!mTask?.error) {
        const navigateTo = routes.OPEN_AI_DASHBOARD;
        navigate(navigateTo, { replace: true });
      }
    }

    return () => {
      prevProps.current = {
        loading: openAi?.loading || mTask?.loading,
        isTaskCreating: state.isTaskCreating,
        isTaskSaving: state.isTaskSaving,
      };
    };
  }, [
    openAi?.newCreatedDataId,
    openAi?.loading,
    state?.isTaskCreating,
    state?.isTaskSaving,
    mTask?.loading,
    mTask?.error,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    const taskId = query.get("task_id");
    if (!taskId) {
      setState((prevState) => ({ ...prevState, isTaskCreating: true }));
      const payload = {
        fields: [...OPEN_AI_TASK_FIELDS],
      };
      payload.fields.forEach((field) => {
        if (field._id === "content_type") {
          field.value = AUTO_GENERATE_CONTENT_TYPES.BLOG.name;
        }
      });
      dispatch(openAiActions.onCreateOneRequest(payload));
    } else {
      dispatch(openAiActions.onGetOneRequest({ _id: taskId }));
    }
  }, [query, dispatch]);

  const handleShowOpenAiTaskModal = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
    }));
  }, []);

  const onSave = useCallback(
    (fields = []) => {
      const { _id } = mTask;

      const payload = {
        _id: _id,
        name: "",
        fields: [],
        draft: false,
      };

      const fieldMap = Object.assign(
        {},
        arrayToMap(mTask?.fields),
        arrayToMap(fields)
      );
      payload.fields = mTask?.fields?.map((field) => {
        const newField = Object.assign({}, field, fieldMap[field?._id]);
        if (newField?._id === "content_type") {
          newField.value = AUTO_GENERATE_CONTENT_TYPES.BLOG.name;
        } else if (newField?._id === "task_name") {
          payload.name = newField.value;
        } else if (newField?._id === "content") {
          newField.value = state.finalDraft;
        }

        return newField;
      });

      setState((prevState) => ({
        ...prevState,
        showOpenAiTaskModal: false,
        isTaskSaving: true,
      }));
      dispatch(openAiActions.onUpdateOneRequest(payload));
    },
    [mTask, state, dispatch]
  );

  if (
    (!openAi.newCreatedDataId && openAi.loading) ||
    state.isTaskCreating ||
    (!mTask?._id && mTask?.loading)
  ) {
    return <Loading />;
  }

  return (
    <GenerateContentContainer
      containerClassName="generate-blog-container set-container"
      steps={setps}
      currentStep={state.currentStep - 1}
    >
      <Row className="content-container">
        {state.currentStep === 1 && (
          <Row className="fw" justify="space-between">
            <Col span={24}>
              <Col className="title-container mb-4">
                <Row className="d-flex-open-ai" align="middle">
                  <div className="back-icon mr-3">
                    <LeftOutlined className="text-2xl" onClick={onGoBack} />
                  </div>

                  <span>Generate your Blog Article</span>

                  {/* <Button
                    type="primary-outline"
                    size="large"
                    onClick={onGoBack}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </Button> */}
                </Row>
              </Col>

              <Col className="mb-4">
                <Col className="mb5">
                  <label className="label">
                    Add your primary or secondary keywords
                  </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Input
                  className="input input-area"
                  placeholder="Add your primary or secoundry keywords here"
                  value={state.keywords}
                  onChange={handleChange("keywords")}
                  size="large"
                  disabled={loading}
                />

                <Row className="error mt5">{error.keywords}</Row>
              </Col>

              <Col className="mb-4">
                <Col className="mb5">
                  <label className="label">
                    Describe your topic, product or service
                  </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Input.TextArea
                  className="input input-area"
                  placeholder="Let us know your topic, brand here"
                  value={state.metaTagDescription}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={handleChange("metaTagDescription")}
                  size="large"
                  disabled={loading}
                />

                <Row className="error mt5">{error.metaTagDescription}</Row>
              </Col>

              <Col className="mb-4">
                <Col className="mb5">
                  <label className="label">Select tone of the content</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Select
                  className="custom-select fw"
                  placeholder="Select tone of the content"
                  value={state.tone}
                  onChange={handleChange("tone")}
                  size="large"
                  disabled={loading}
                >
                  {CONTENT_VOICE_TONES.map((tone, i) => (
                    <Select.Option key={i} title={tone} value={tone} />
                  ))}
                </Select>

                <Row className="error mt5">{error.tone}</Row>
              </Col>

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw mt-4"
                    type="primary"
                    loading={loading}
                    onClick={onSubmit}
                    size="large"
                  >
                    Suggest Topic
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {state.currentStep === 2 && (
          <Row className="fw" justify="space-between">
            <Col span={24}>
              <Col className="title-container mb-4">
                <Row className="d-flex-open-ai" align="middle">
                  <div className="back-icon mr-3">
                    <LeftOutlined className="text-2xl" onClick={onGoBack} />
                  </div>

                  <span>Choose Your Topic</span>

                  {/* <Button
                    type="primary-outline"
                    size="large"
                    onClick={onGoBack}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </Button> */}
                </Row>
              </Col>

              <Col className="mb-4">
                <MRadioGroup
                  className="fw"
                  values={responseTitles}
                  value={state.title}
                  canTextEdit
                  onChange={handleChange("title")}
                  onChangeValues={setResponseTitles}
                />

                {/* <Row className="error mt5">{error.title}</Row> */}
              </Col>

              <Col className="mt-4 mb-4">
                <Row align="middle" justify="space-between">
                  <Col>
                    <Button
                      className="mr-2"
                      type="primary-link"
                      onClick={() =>
                        setResponseTitles((prevResponseTitles) => [
                          ...prevResponseTitles,
                          "New Blog Topic",
                        ])
                      }
                      disabled={loading || state.isLoadingExpandAllSubheading}
                      size="large"
                    >
                      Add Your Own Topic
                    </Button>
                  </Col>

                  <Button
                    type="primary-outline"
                    onClick={onRegenerate}
                    disabled={loading}
                    size="large"
                  >
                    <ReloadOutlined spin={state.isTitleRegenerate} />
                    Regenerate
                  </Button>
                </Row>
              </Col>

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw mt-4"
                    type="primary"
                    disabled={loading || state.isTitleRegenerate}
                    loading={loading && !state.isTitleRegenerate}
                    onClick={onSubmit}
                    size="large"
                  >
                    Suggest Heading
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {state.currentStep === 3 && (
          <Row className="fw" justify="space-between">
            <Col span={24}>
              <Col className="title-container mb-4">
                <Row align="middle">
                  <LeftOutlined className="text-2xl mr-3" onClick={onGoBack} />

                  <Col className="title">Your Blog Outline</Col>

                  {/* <Button
                    type="primary-outline"
                    size="large"
                    onClick={onGoBack}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </Button> */}
                </Row>
              </Col>

              <Col className="mb-4">
                <MRadioGroup
                  values={state.subheadings}
                  canTextEdit={true}
                  onChangeValues={handleChange("subheadings")}
                  showSelectBtn={false}
                />
              </Col>

              <Col className="mt-4 mb-4" />

              <Col className="mb-4">
                <Row align="middle" justify="space-between">
                  <Col>
                    <Button
                      className="mr-8"
                      type="primary-link"
                      onClick={() =>
                        handleChange("subheadings")([
                          ...state.subheadings,
                          "New Blog Outline",
                        ])
                      }
                      disabled={
                        loading ||
                        state.isLoadingExpandAllSubheading ||
                        state.isSubheadingRegenerate
                      }
                      size="large"
                    >
                      Add Your Own Blog Outline
                    </Button>

                    <Checkbox
                      onChange={handleChange("isAddFAQ")}
                      disabled={
                        loading ||
                        state.isLoadingExpandAllSubheading ||
                        state.isSubheadingRegenerate
                      }
                    >
                      Add FAQ
                    </Checkbox>
                  </Col>

                  <Button
                    type="primary-outline"
                    onClick={onRegenerate}
                    disabled={
                      loading ||
                      state.isLoadingExpandAllSubheading ||
                      state.isSubheadingRegenerate
                    }
                    size="large"
                  >
                    <ReloadOutlined spin={state.isSubheadingRegenerate} />
                    Regenerate
                  </Button>
                </Row>
              </Col>

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw mt-4"
                    type="primary"
                    loading={state.isLoadingExpandAllSubheading}
                    disabled={
                      state.isLoadingExpandAllSubheading ||
                      state.isSubheadingRegenerate
                    }
                    onClick={onSubmit}
                    size="large"
                  >
                    Generate First Draft
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {state.currentStep === 4 && (
          <Row className="fw" justify="space-between">
            <Col span={24}>
              <Col className="title-container mb-4">
                <Row align="middle">
                  <LeftOutlined className="text-2xl mr-3" onClick={onGoBack} />

                  <Col className="title">First Draft</Col>

                  {/* <Button
                    type="primary-outline"
                    size="large"
                    onClick={onGoBack}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </Button> */}
                </Row>
              </Col>

              <Col className="mb-4">
                {state.subheadings?.map?.((subheading, i) => (
                  <Col key={i} className="mb-4">
                    <Col className="mb5">
                      <h3 className="label">{subheading}</h3>
                    </Col>

                    <Col className="mb-4">
                      <Input.TextArea
                        className="input input-area"
                        value={state[subheading]?.description}
                        onChange={handleSubheadingDescriptionChange(subheading)}
                        autoSize={{ minRows: 5, maxRows: 15 }}
                        disabled={state[subheading]?.loading}
                        size="large"
                      />
                    </Col>

                    <Col className="mt-4 mb-4" />

                    <Col className="mb-4">
                      <Row
                        className="fw"
                        align="middle"
                        justify="space-between"
                      >
                        <Col />

                        <Button
                          type="primary-outline"
                          onClick={() =>
                            generateExpandSubheadingApi(subheading, i, 0.5)
                          }
                          disabled={state[subheading]?.loading}
                          size="large"
                        >
                          <ReloadOutlined spin={state[subheading]?.loading} />
                          Regenerate
                        </Button>
                      </Row>
                    </Col>
                  </Col>
                ))}
              </Col>

              <Col className="mt-4 mb-4" />

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw"
                    type="primary"
                    size="large"
                    onClick={onSubmit}
                  >
                    See The Magic!
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {state.currentStep === 5 && (
          <Row className="fw" justify="space-between">
            <Col span={24}>
              <Col className="title-container mb-4">
                <Row align="middle">
                  <LeftOutlined className="text-2xl mr-3" onClick={onGoBack} />

                  <Col className="title">Final Draft</Col>

                  {/* <Button
                    type="primary-outline"
                    size="large"
                    onClick={onGoBack}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </Button> */}
                </Row>
              </Col>

              <Col className="mb-24">
                <ReactQuill
                  className="fh"
                  value={state.finalDraft}
                  onChange={handleChange("finalDraft")}
                  theme="snow"
                />
              </Col>

              <Col className="mt-4 mb-4" />

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw mt-4"
                    type="success"
                    size="large"
                    loading={mTask?.loading}
                    onClick={() => handleShowOpenAiTaskModal(true)}
                  >
                    {mTask?.loading ? "Saving" : "Save"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Row>

      <OpenAiTaskModal
        visible={state.showOpenAiTaskModal}
        taskId={mTask?._id}
        fields={mTask?.fields?.filter((field) => field?._id === "task_name")}
        onSubmit={onSave}
        handleModal={handleShowOpenAiTaskModal}
      />
    </GenerateContentContainer>
  );
};

export default GenerateBlog;
