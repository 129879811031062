const { REACT_APP_API_ENDPOINT, REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const BASE_URL = REACT_APP_API_ENDPOINT;
const API_BASE_URL = `${BASE_URL}/api/v1`;

export const GOOGLE_CLIENT_ID = REACT_APP_GOOGLE_CLIENT_ID;

const AppUrl = {
  SOCKET_URL: BASE_URL,
  AUTH: `${API_BASE_URL}/auth`,
  LOGIN_URL: `${API_BASE_URL}/auth/login`,
  GOOGLE_LOGIN_URL: `${API_BASE_URL}/auth/google`,
  SIGNUP_URL: `${API_BASE_URL}/auth/signup`,
  FORGOT_PASSWORD: `${API_BASE_URL}/auth/reset-password`,
  VERIFY_EMAIL: `${API_BASE_URL}/auth/verify-account`,
  RESET_PASSWORD: `${API_BASE_URL}/auth/set-password`,
  USERS: `${API_BASE_URL}/users`,
  ROLES: `${API_BASE_URL}/roles`,
  NOTIFICATIONS: `${API_BASE_URL}/notifications`,
  ASSESSMENTS: `${API_BASE_URL}/assessments`,
  ASSESSMENT_TEST: `${API_BASE_URL}/tests`,
  TASKS: `${API_BASE_URL}/assignments`,
  COMMENTS: `${API_BASE_URL}/comments`,
  PROJECT: `${API_BASE_URL}/project`,
  PROJECT_INSIGHTS: `${API_BASE_URL}/project/insight`,
  PROJECT_STATUS_UPDATE: `${API_BASE_URL}/project/status-update`,
  SHARE_WITH: `${API_BASE_URL}/projects`,
  BULK_UPLOAD: `${API_BASE_URL}/uploadExcel`,
  ITEMS: `${API_BASE_URL}/items`,
  DASHBOARD: `${API_BASE_URL}/dashboard`,
  TEMPLATE: `${API_BASE_URL}/template`,
  ATTACHMENTS: `${API_BASE_URL}/attachments`,
  INVOICE: `${API_BASE_URL}/invoice/download`,
  OPENAI: `${API_BASE_URL}/openAi`,
  OPENAI_TASK: `${API_BASE_URL}/openAi/task`,
  OPENAI_BULK_UPLOAD: `${API_BASE_URL}/openAi/bulk-upload`,
};

Object.freeze(AppUrl);
export default AppUrl;
