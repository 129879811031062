import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const handleLoading = (state) => {
  state.error = null;
  state.loading = true;
};

const handleSuccess = (state, action) => {
  state.data = action.payload;
  state.loading = false;
};

const handleUpdatePasswordSuccess = (state, action) => {
  state.loading = false;
};

const handleError = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const loggedInUserSlice = createSlice({
  name: "loggedInUser",
  initialState,
  reducers: {
    loginRequest: handleLoading,
    updateLoggedInUserRequest: handleLoading,
    updatePassword: handleLoading,
    onRegisterFreelancerRequest: handleLoading,
    onGetUserDetailsRequest: handleLoading,
    onUpdateUserDetailsRequest: handleLoading,
    onHandleUpdatePasswordSuccess: handleUpdatePasswordSuccess,
    onHandleSuccess: handleSuccess,
    onHandleError: handleError,
    loginGoogle: handleLoading
  },
});

export const loggedInUserActions = loggedInUserSlice.actions;

export default loggedInUserSlice.reducer;
