import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row, Select, Button, Tag, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../components/CustomModal";
import { itemActions } from "../../item/duck/itemReducer";
import constants from "../../utils/constants";
import "./SelectFreelancerModal.scss";
import moment from "moment-timezone";
import { formatNumber } from "../../utils/commonFunctions";
const { PROJECT_TASK_STATUS } = constants;
const { INITIATE } = PROJECT_TASK_STATUS;

const TaskAcceptanceModal = (props) => {
  const { visible, projectTaskId, editable, costObject } = props;

  const prevProps = useRef();
  const dispatch = useDispatch();

  const itemObj = useSelector(({ item }) => item.map[projectTaskId]);

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (visible) {
      if (prevProps.current?.loading && !itemObj?.loading) {
        if (!itemObj?.error) {
          props.handleModal();
        }
      }
    } else {
      setState({});
      setError({});
    }

    return () => {
      prevProps.current = {
        visible: visible,
        loading: itemObj?.loading,
      };
    };
  }, [visible, itemObj?.loading, itemObj?.error, props]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    const { isAcceptedByWriter, writer_remark } = state;
    const error = {};

    if (typeof isAcceptedByWriter !== "boolean") {
      error.isAcceptedByWriter = "Please select your response";
    }
    if (isAcceptedByWriter === false && !writer_remark) {
      error.writer_remark = "Please mention your remark";
    }

    setError(error);

    return !!Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const { isAcceptedByWriter, writer_remark } = state;
      const historyObj = {
        assignedWriter: itemObj?.assigned_freelancer,
        created_at: moment().format(),
        isAcceptedByWriter,
        writer_remark,
        allottedAmountToWriter: itemObj?.meta?.allottedAmountToWriter,
      };

      const meta = {
        ...itemObj?.meta,
        isAcceptedByWriter,
        history: [...(itemObj?.meta?.history ?? []), historyObj],
      };

      const payload = {
        _id: projectTaskId,
        meta,
      };

      if (!isAcceptedByWriter) {
        payload.assigned_freelancer = null;
        payload.current_assignee = null;
        payload.status_description = INITIATE;
      }

      dispatch(itemActions.onUpdateOneRequest(payload));
    }
  }, [
    hasError,
    state,
    itemObj?.assigned_freelancer,
    itemObj?.meta,
    projectTaskId,
    dispatch,
  ]);

  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        {editable && (
          <Button
            className="new_button mr5"
            type="primary"
            onClick={onSubmit}
            size="large"
            loading={itemObj?.loading}
          >
            Submit
          </Button>
        )}
        <Button
          className="new_button"
          type="primary-outline"
          onClick={props?.handleModal}
          size="large"
        >
          Minimise
        </Button>
      </Row>
    );
  }, [props?.handleModal, editable, onSubmit, itemObj?.loading]);

  return (
    <CustomModal
      className="select-freelancer-modal-container"
      visible={visible}
      title={"Task Cost"}
      footer={renderFooter}
      onCancel={props.handleModal}
      width={650}
    >
      {/* price type start */}

      <Col className="mb20">
        <Col className="mb5">
          <label className="label">Cost type</label>
        </Col>

        <Col>
          <Tag style={{ fontSize: 14 }}>{costObject?.costType}</Tag>
        </Col>
      </Col>

      {/* price type end */}

      {/* price start */}
      <Col className="mb20">
        <Col className="mb5">
          <label className="label">Cost</label>
        </Col>

        <Col>
          <span style={{ fontWeight: 500, fontSize: 16 }}>
            {costObject?.currencySymbol}{" "}
            {formatNumber(itemObj?.meta?.allottedAmountToWriter)}
          </span>
        </Col>
      </Col>
      {/* price end */}

      {/* price start */}
      <Col className="mb20">
        <Col className="mb5">
          <label className="label">Status</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col>
          <Select
            className="fw"
            size="large"
            placeholder="Select your approval"
            value={state?.isAcceptedByWriter}
            onChange={handleChange("isAcceptedByWriter")}
            allowClear
            disabled={itemObj?.loading}
          >
            {[
              {
                _id: "Accept & Proceed",
                value: true,
              },
              {
                _id: "Ask for reconsideration",
                value: false,
              },
            ].map((item) => (
              <Select.Option key={item?._id} value={item?.value}>
                {item?._id}
              </Select.Option>
            ))}
          </Select>

          <Row className="error mt5">{error?.isAcceptedByWriter}</Row>
        </Col>
      </Col>
      {/* price end */}

      {/* Remark start */}
      {state?.isAcceptedByWriter === false && (
        <Col>
          <Col className="mb5">
            <label className="label">Reason</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col>
            <Input.TextArea
              className="fw"
              placeholder="Reason for reconsideration"
              autoSize={{ minRows: 4, maxRows: 6 }}
              value={state?.writer_remark}
              onChange={handleChange("writer_remark")}
              disabled={itemObj?.loading}
              allowClear
            />

            <Row className="error mt5">{error?.writer_remark}</Row>
          </Col>
        </Col>
      )}
      {/* Remark end */}
    </CustomModal>
  );
};

export default TaskAcceptanceModal;
