import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboard: null,
    loading: false,
    error: null,
  };
  
  const handleLoading = (state) => {
    state.error = null;
    state.loading = true;
  };
  
  const handleSuccess = (state, action) => {
    state.dashboard = action.payload;
    state.loading = false;
    state.error= null;
  };
  
  const handleError = (state, action) => {
    state.error = action.payload;
    state.loading = false;
  };

  const dashboardSlice = createSlice({
    name:"dashboard",
    initialState,
    reducers: {
        getDashboard: handleLoading,
        onHandleSuccess: handleSuccess,
        onHandleError: handleError,
      },
  })
  export const dashboardActions = dashboardSlice.actions;
  export default dashboardSlice.reducer