import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Input, Select, Button, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { userActions } from "../users/duck/userReducer";
import InviteUserModal from "./components/InviteUserModal";
import { roleActions } from "../role/duck/roleReducer";
import { paramsToString } from "../utils/commonFunctions";
import PlusIcon from "../assets/images/plusIcon.svg";

import UserItemRow from "./components/UserItemRow";
import MyTable from "../components/MyTable";

const UserList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({ search: "" });
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [boardKey, setBoardKey] = useState("");

  const rolesMap = useSelector(({ role }) => role.map);
  const userMap = useSelector(({ user }) => user?.map);

  const { list, meta, loading } = useSelector(({ user }) => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = user.boards;
    const mBoard = boards[mFilter];

    return mBoard || {};
  });
  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { search, role_id } = state;
        const params = {
          offset,
          limit,
        };
        if (search) params.search = search;
        if (role_id) params.role_id = role_id;
        setBoardKey(paramsToString(params));
        dispatch(userActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount, state]
  );

  const onResetFilter = useCallback(() => {
    setState({ search: "" });
    setBoardKey("");
  }, []);

  const handleInviteUserModal = useCallback(
    (show = false, id) => {
      show = typeof show === "boolean" && show;
      const mUser = show && userMap?.[id];
      setShowInviteUser(show);

      selectUser(mUser);
    },
    [userMap]
  );

  useEffect(() => {
    if (!boardKey) {
      loadMore();
    }
  }, [boardKey]);

  useEffect(() => {
    dispatch(roleActions.onGetAllRequest());
  }, [dispatch]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const columns = useMemo(
    () => [
      { key: 1, title: "S.No.", style: { width: 0 } },
      { key: 2, title: "Name" },
      { key: 3, title: "Email" },
      { key: 4, title: "Role" },
      { key: 5, title: "Status" },
      { key: 6, title: "Action", style: { width: 0 } },
    ],
    []
  );

  return (
    <div className="content-body app-container user-list-container">
      <Row className="fw tert-bold mb20">Users: {meta?.totalCount}</Row>
      <Row className="fw" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Search First Name, Last Name or Email"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Col>
        <Col xs={24} sm={8} md={8} lg={8} xl={2} xxl={2}>
          <Select
            className="new_select fw"
            size="large"
            placeholder={"Role"}
            showArrow
            // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            dropdownMatchSelectWidth={false}
            value={state?.role_id}
            onChange={handleChange("role_id")}
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            showSearch
          >
            {Object.keys(rolesMap).map((id) => {
              const { role_id, name } = rolesMap?.[id];

              return (
                <Select.Option key={role_id} value={role_id}>
                  {name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} sm={7} md={8} lg={6} xl={2} xxl={2}>
          <Button
            type="primary"
            size="large"
            className="fw new_button"
            onClick={() => loadMore()}
          >
            Search
          </Button>
        </Col>
        <Col xs={24} sm={7} md={8} lg={6} xl={2} xxl={2}>
          <Button
            size="large"
            className="fw new_button"
            type="primary-outline"
            onClick={onResetFilter}
          >
            Reset
          </Button>
        </Col>
        <Row
          style={{
            marginLeft: "auto",
          }}
        >
          <Button
            className="new_button"
            type="primary"
            onClick={() => handleInviteUserModal(true)}
            size="large"
            icon={<img src={PlusIcon} alt="plus icon" />}
          >
            &nbsp; Add New
          </Button>
        </Row>
      </Row>
      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          renderItem={(item, i) => (
            <UserItemRow
              key={i}
              serialNo={i + 1}
              id={item}
              handleInviteUserModal={handleInviteUserModal}
            />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>
      <InviteUserModal
        visible={showInviteUser}
        handleModal={handleInviteUserModal}
        selectUser={selectUser}
        selectedUser={selectedUser}
      />
    </div>
  );
};

export default UserList;
