import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Row, Col, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import CustomModal from "../../components/CustomModal";
import { assessmentActions } from "../../assessment/duck/assessmentReducer";
import { assessmentTestActions } from "../../assessmentTest/duck/assessmentTestReducer";
import { paramsToString, showNotification } from "../../utils/commonFunctions";

const AssignAssessmentModal = (props) => {
  const { visible, handleModal, selectUser, selectedUser } = props;

  const prevProp = useRef();
  const dispatch = useDispatch();

  const [boardKey, setBoardKey] = useState("{}");
  const [state, setState] = useState({});
  const [formError, setFormError] = useState({});

  const userObj = useSelector(({ user }) => user?.map?.[selectedUser]);

  const assessmentMap = useSelector(({ assessment }) => assessment.map);
  const { list, meta } = useSelector(({ assessment }) => {
    const boards = assessment.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const testLoading = useSelector(
    ({ assessmentTest }) => assessmentTest?.loading
  );

  useEffect(() => {
    if (prevProp?.current?.loading && !testLoading) {
      if (!userObj?.error) {
        handleModal(false);
        selectUser(null);
        showNotification("success", "Assessment Link Sent");
      }
    } else if (!prevProp?.current?.visible && visible) {
      setFormError({});
      setState({
        assessmentId: null,
      });
    }

    return () => {
      prevProp.current = { loading: testLoading, visible };
    };
  }, [handleModal, selectUser, testLoading, userObj?.error, visible]);

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          offset: offset,
          limit: limit,
        };

        setBoardKey(paramsToString(params));
        dispatch(assessmentActions.onGetAllRequest(params));
      }
    },
    [dispatch, list, meta]
  );
  useEffect(() => {
    if (!list) {
      loadMore();
    }
  }, [loadMore, list]);

  const hasError = useCallback(() => {
    let { assessmentId } = state;
    let error = {};

    if (!assessmentId) {
      error.assessmentId = "Please select one assessment";
    }

    setFormError(error);
    return !!Object.keys(error).length;
  }, [state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      setFormError({});
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const onOk = useCallback(() => {
    if (!hasError()) {
      const testData = {
        assessment_id: state?.assessmentId,
        user_id: selectedUser,
        email: userObj?.email,
      };

      dispatch(assessmentTestActions.onCreateOneRequest(testData));
    }
  }, [dispatch, hasError, selectedUser, state?.assessmentId, userObj?.email]);

  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        <Button
          className="new_button"
          type="primary"
          onClick={onOk}
          size="large"
          loading={userObj?.loading}
        >
          Submit
        </Button>
        <Button
          className="new_button"
          type="primary-outline"
          onClick={handleModal}
          size="large"
        >
          Cancel
        </Button>
      </Row>
    );
  }, [handleModal, onOk, userObj?.loading]);

  return (
    <CustomModal
      visible={visible}
      title={"Assign Assessment to Writer"}
      okButtonText={"Add"}
      width={700}
      onCancel={props?.handleModal}
      footer={renderFooter}
    >
      <div>
        {!list?.length ? (
          <Row align="middle" className="mb10">
            You dont have any assessments. Please add some to assign assessment.
          </Row>
        ) : (
          <Col>
            <Col className="mb5">
              <label className="label">Select Assessment </label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col>
              <Select
                size="large"
                placeholder="Select Test Paper"
                value={state?.assessmentId}
                className="wpsb fw"
                suffixIcon={<CaretDownOutlined className="f15" />}
                onChange={handleChange("assessmentId")}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={userObj?.loading}
              >
                {Object.keys(assessmentMap).map((id) => {
                  const { name, level, questions } = assessmentMap?.[id] ?? {};

                  return (
                    <Select.Option key={id} value={id}>
                      <Row align="middle" justify="space-between">
                        <span>{name}</span>
                        <span className="ml20">
                          <Tag>{level}</Tag>
                        </span>
                        <span className="ml20">
                          Total Questions: {questions?.length}
                        </span>
                      </Row>
                    </Select.Option>
                  );
                })}
              </Select>
              <Row className="error mt5">{formError?.assessmentId}</Row>
            </Col>
          </Col>
        )}
      </div>
    </CustomModal>
  );
};

export default AssignAssessmentModal;
