import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import "./MPagination.scss";
import Paginator from "../utils/Paginator";

const MPagination = (props) => {
  const {
    className = "",
    defaultActivePage = 1,
    totalItemsCount = 0,
    itemsCountPerPage = 10,
    pageRangeDisplayed = 5,
    onChange = (current) => current,
  } = props;

  const [state, setState] = useState({
    activePage: defaultActivePage,
  });
  const { activePage } = state;

  const { firstPage, lastPage } = useMemo(() => {
    const firstPage = 1;
    const lastPage = Math.ceil(totalItemsCount / itemsCountPerPage);

    return { firstPage, lastPage };
  }, [totalItemsCount, itemsCountPerPage]);

  const handleChange = useCallback(
    (activePage) => {
      setState((prevState) => ({ ...prevState, activePage }));
      onChange(activePage);
    },
    [onChange]
  );

  useEffect(() => {
    if (props?.activePage !== activePage) {
      setState((prevState) => ({
        ...prevState,
        activePage: props?.activePage,
      }));
    }
  }, [props?.activePage, handleChange]);

  const pages = useMemo(() => {
    const mPages = [];
    const paginationInfo = new Paginator(
      itemsCountPerPage,
      pageRangeDisplayed
    ).build(totalItemsCount, activePage);

    const isPreviousBtnDisabled = activePage === firstPage;
    mPages.push(
      <li
        key={"Previous"}
        title="Previous Page"
        className={`ant-pagination-prev ${
          isPreviousBtnDisabled && "ant-pagination-disabled"
        }`}
        onClick={() => !isPreviousBtnDisabled && handleChange(activePage - 1)}
      >
        <Button
          className="ant-pagination-item-link"
          icon={<LeftOutlined />}
          size="small"
          disabled={isPreviousBtnDisabled}
        />
      </li>
    );

    for (
      let i = paginationInfo.first_page;
      i <= paginationInfo.last_page;
      i++
    ) {
      mPages.push(
        <li
          key={i}
          title={i}
          className={`ant-pagination-item ant-pagination-item-${i} ${
            i === activePage && "ant-pagination-item-active"
          }`}
          tabIndex={i}
          onClick={() => handleChange(i)}
        >
          <a>{i}</a>
        </li>
      );
    }

    const isNextBtnDisabled = activePage === lastPage;
    mPages.push(
      <li
        key={"Next"}
        title="Next Page"
        className={`ant-pagination-next ${
          isNextBtnDisabled && "ant-pagination-disabled"
        }`}
        onClick={() => !isNextBtnDisabled && handleChange(activePage + 1)}
      >
        <Button
          className="ant-pagination-item-link"
          icon={<RightOutlined />}
          size="small"
          disabled={isNextBtnDisabled}
        />
      </li>
    );

    return mPages;
  }, [
    activePage,
    firstPage,
    lastPage,
    handleChange,
    itemsCountPerPage,
    pageRangeDisplayed,
    totalItemsCount,
  ]);

  return (
    <ul className={`ant-pagination min m-pagination ${className}`}>{pages}</ul>
  );
};

export default React.memo(MPagination);
