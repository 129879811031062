import React from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";

const AssessmentRow = (props) => {
  const { id, serialNo } = props;
  const navigate = useNavigate();
  const assessmentObj = useSelector(({ assessment }) => assessment.map[id]);

  return (
    <tr>
      <td style={{ textAlign: "center" }}>{serialNo}</td>
      <td>{assessmentObj?.name}</td>
      <td>{assessmentObj?.level}</td>
      <td>{assessmentObj?.questions?.length}</td>

      <td style={{ textAlign: "center" }}>
        <Tooltip title={"Edit"}>
          <EditOutlined
            style={{ fontSize: 12 }}
            onClick={() => navigate(`${routes.ASSESSMENT_DETAILS}/${id}`)}
          />
        </Tooltip>
      </td>
    </tr>
  );
};

export default AssessmentRow;
