import { notification } from "antd";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";

import constants from "./constants";
import colors from "./colors";

const colorMap = {};
let lastColorIndex = 0;

export const dashboardProjectColors = [
  "#00AF82",
  "#5C3B97",
  "#F3AF4B",
  "#1A1D9C",
];

export function getAvatarColor(id = "", transparency) {
  if (!id) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  if (!colorMap.hasOwnProperty(id)) {
    colorMap[id] = colors[lastColorIndex];
    lastColorIndex = lastColorIndex + 1;
    lastColorIndex = lastColorIndex % colors.length;
  }
  return transparency ? `${colorMap[id]}${transparency}` : colorMap[id];
}

const tagColorMap = {};
let tagLastColorIndex = 0;

export function getTagColor(id = "", transparency) {
  const colors = ["purple", "geekblue", "green", "cyan", "lime"];

  if (!id) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  if (!tagColorMap.hasOwnProperty(id)) {
    tagColorMap[id] = colors[tagLastColorIndex];
    tagLastColorIndex = tagLastColorIndex + 1;
    tagLastColorIndex = tagLastColorIndex % colors.length;
  }

  return tagColorMap[id];
}

export const arrayToMap = (objectArray = [], _id = "_id") => {
  const map = {};
  objectArray.forEach((item) => {
    const key = item?.[_id] || item?._id;
    map[key] = Object.assign({}, item, { _id: key });
  });

  return map;
};

export const getArrayObjectOrder = (array = []) => {
  const order = array?.map?.((item) => item?._id);
  return order ?? [];
};

export const validEmail = (email) => {
  let mailformat = /^\w+([\.-]?\w+[\+]?)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return mailformat.test(email);
};

export const validPassword = (password = "") => {
  return password.length >= 4;
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") return false;

  const token = localStorage.getItem(constants.ACCESS_TOKEN);
  if (token) {
    const user = jwtDecode(token);
    const dateNow = new Date();
    if (user?.exp > dateNow.getTime() / 1000) {
      return true;
    } else {
      localStorage.removeItem(constants.ACCESS_TOKEN);
    }
  }

  return false;
};

export const getIsSidebarCollapsedFromStore = () => {
  const isCollapsed = localStorage.getItem(constants.IS_SIDEBAR_COLLAPSED);
  return isCollapsed;
};

export const setIsSidebarCollapsedFromStore = (isCollapsed = false) => {
  if (isCollapsed) {
    localStorage.setItem(constants.IS_SIDEBAR_COLLAPSED, isCollapsed);
  } else {
    localStorage.removeItem(constants.IS_SIDEBAR_COLLAPSED);
  }
};

export const getNameInitials = (name = "") => {
  name = name?.trim?.();
  if (!name) return;

  let initials = name[0] || "";
  const index = name.indexOf(" ");
  if (index < name.length && index > 1) {
    initials += name[index + 1];
  }

  return initials.toUpperCase();
};

export const specialCharactersCheck = (name) => {
  let nameformat = /^[A-Za-z ]+$/;
  return nameformat.test(name);
};

export const isValidMobileNumber = (mobile = "") => {
  let format = /^[6789]\d{9}$/;
  let countryCode = "+91";

  if (countryCode === "+91") format = /^[6789]\d{9}$/;

  if (!countryCode || !format) return false;

  return format.test(mobile);
};

export const isValidLinkdinURL = (string) => {
  let res = string.match(/^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/gim);
  return res !== null;
};

export const isValidWebsiteURL = (string) => {
  let res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const showNotification = (type, msg) => {
  notification[type]({
    message: msg,
    placement: "bottomLeft",
  });
};

export const paramsToString = (params = {}, removeKeys = []) => {
  const mParams = { ...params };
  delete mParams?.offset;
  delete mParams?.limit;
  removeKeys.forEach((key) => delete mParams?.[key]);

  return JSON.stringify(mParams);
};

export const getStringToOptions = (string = "") => string?.split?.("|") || [];

export const getOptionsToString = (options = []) => options.join("|");

export const disabledDate = (current) => {
  // Can not select days before today
  return current && current < moment().startOf("day");
};
export const disabledFutureDate = (current) => {
  // Can not select days after today
  return current && current > moment().endOf("day");
};

export const guidGenerator = () => {
  let timestamp = parseInt(moment.utc().valueOf());
  return timestamp + Math.floor(Math.random() * 1000 + 1);
};

export const textWrapper = (text = "", maxLength = 15, returnLength = 14) => {
  if (typeof text === "string" && text?.length > maxLength) {
    return `${text.slice(0, returnLength)}...`;
  } else {
    return text;
  }
};

export const abbreviateNumber = (num = 0, fixed) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "k", "m", "b", "t"][k]; // append power
  return e;
};
export const formatNumber = (value, decimalPoints = 3) => {
  const mNumber = Number(value)
  if (Number.isInteger(mNumber)) {
    return mNumber
  } else {
    return mNumber?.toFixed(decimalPoints)
  }
}

const weeks = [
  { _id: 1, name: "Week 1" },
  { _id: 2, name: "Week 2" },
  { _id: 3, name: "Week 3" },
  { _id: 4, name: "Week 4" },
  { _id: 5, name: "Week 5" },
];
const months = [
  { _id: 1, name: "Jan" },
  { _id: 2, name: "Feb" },
  { _id: 3, name: "Mar" },
  { _id: 4, name: "Apr" },
  { _id: 5, name: "May" },
  { _id: 6, name: "June" },
  { _id: 7, name: "July" },
  { _id: 8, name: "Aug" },
  { _id: 9, name: "Sept" },
  { _id: 10, name: "Oct" },
  { _id: 11, name: "Nov" },
  { _id: 12, name: "Dec" },
];
const lastSevenDays = [];
for (var i = 0; i < 7; i++) {
  const calcDate = moment().subtract(i, "days");
  lastSevenDays.push({
    _id: Number(calcDate.format("DD")),
    name: calcDate.format("Do MMM"),
  });
}

export const graphDataBuilder = (mData = [], basis = "days") => {
  const labels = [];
  const data = [];
  let timelineToMatchWith;

  switch (basis) {
    case "week":
      timelineToMatchWith = weeks;
      break;

    case "month":
      timelineToMatchWith = months;
      break;

    default:
      timelineToMatchWith = lastSevenDays.slice().reverse();
      break;
  }

  if (timelineToMatchWith) {
    timelineToMatchWith?.forEach((obj) => {
      const dataObj = mData?.find((o) => o?._id === obj?._id);
      const value = dataObj ? dataObj?.data : 0;
      data.push(value);
      labels.push(obj?.name);
    });
  }

  return { labels, data };
};

export const taskProgressPercentage = (status) => {
  const {
    PROJECT_TASK_STATUS: {
      INITIATE,
      IN_PROGRESS,
      IN_REVIEW,
      COMPLETED,
      APPROVED,
    },
  } = constants;

  switch (status) {
    case INITIATE:
      return 0;
    case IN_PROGRESS:
      return 25;
    case IN_REVIEW:
      return 50;
    case COMPLETED:
      return 75;
    case APPROVED:
      return 100;

    default:
      return 0;
  }
};

export const downloadFile = async (url, name = "writely") => {
  if (!url) return;

  const file = await fetch(url);
  const fileBlob = await file.blob();
  const fileURL = URL.createObjectURL(fileBlob);

  const link = document.createElement("a");
  link.href = fileURL;
  link.download = name;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return true;
};

export const hasApiCallError = (meta = {}) => {
  let isError = false;
  if (meta?.success !== true || meta?.status !== 201) {
    isError = true;
    const message =
      typeof meta?.message === "string"
        ? meta?.message
        : "Something went wrong";
    showNotification("error", message);
  }

  return isError;
};
