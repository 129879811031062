import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AssessmenTestRow from "../../assessmentTest/components/AssessmenTestRow";
import { assessmentTestActions } from "../../assessmentTest/duck/assessmentTestReducer";
import MyTable from "../../components/MyTable";
import { paramsToString } from "../../utils/commonFunctions";

const AssessmentDetails = ({ user }) => {
  const dispatch = useDispatch();

  const [boardKey, setBoardKey] = useState(
    JSON.stringify({ assigned_freelancer: user?._id })
  );

  const userId = useSelector(({ loggedInUser }) => loggedInUser.data?._id);

  const [canGiveTest, setCanGiveTest] = useState(false);

  let { list, meta, loading } = useSelector(({ assessmentTest }) => {
    const boards = assessmentTest.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          assigned_freelancer: user?._id,
          offset: offset,
          limit: limit,
        };

        const mBoardKey = paramsToString(params);
        setBoardKey(mBoardKey);
        dispatch(assessmentTestActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount, user?._id]
  );

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    if (userId === user?._id) {
      setCanGiveTest(true);
    }
  }, [user?._id, userId]);

  const columns = useMemo(() => {
    let cols = [
      { key: 1, title: "S.No.", style: { width: 0 } },
      { key: 2, title: "Test Name", style: { width: 0 } },
      {
        key: 3,
        title: "Assigned Date",
        style: { width: 0 },
      },
      {
        key: 4,
        title: "Submitted Date",
        style: { width: 0 },
      },
      {
        key: 5,
        title: "Marks Obtained",
        style: { width: 0, textAlign: "center" },
      },
      { key: 6, title: "Status", style: { width: 0 } },
    ];

    if (canGiveTest) {
      cols.push({ key: 7, title: "Action", style: { width: 0 } });
    }

    return cols;
  }, [canGiveTest]);

  return (
    <Row className="fw mt30">
      <MyTable
        columns={columns}
        loading={loading}
        list={list}
        renderItem={(item, i) => (
          <AssessmenTestRow
            serialNo={i + 1}
            key={i}
            id={item}
            canGiveTest={canGiveTest}
          />
        )}
        totalCount={meta?.totalCount}
        loadMore={loadMore}
      />
    </Row>
  );
};

export default AssessmentDetails;
