import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import constants from "../utils/constants";
import { Button, DatePicker, Popover, Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { disabledFutureDate } from "../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { paramsToString } from "../utils/commonFunctions";
import { userActions } from "../users/duck/userReducer";
import moment from "moment";

const { FILTER_TYPE_DATE, ROLE_IDS } = constants;

const MyTaskFilterDate = (props) => {
  const { handleChange = () => {}, state = {}, handleClickList } = props;

  const dispatch = useDispatch();
  const prevProps = useRef();
  const [usersBoardKey, setUsersBoardKey] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  //const [selectedEndDate, setSelectedEndDate] = useState(null);
  //console.log("selectedStartDate", props);
  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const usersObj = useSelector(({ user }) => {
    const userMap = user.map;
    const boards = user.boards;
    const mBoard = boards[usersBoardKey];
    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;
    let users = list.map((userId) => userMap[userId]);
    users = users.filter((user) => user);
    return { users, meta, loading };
  });

  const roleIds = useMemo(() => {
    const ids = [
      ROLE_IDS.EDITOR,
      ROLE_IDS.MANAGER,
      ROLE_IDS.SUPER_ADMIN,
      ROLE_IDS.ADMIN,
      ROLE_IDS.CLIENT,
    ];
    if (loggedInUser?.role_id !== ROLE_IDS.FREELANCER) {
      ids.push(ROLE_IDS.FREELANCER);
    }
    return ids;
  }, [loggedInUser?.role_id]);

  const loadMoreUsers = useCallback(
    (offset = 0, limit = 30) => {
      if (
        offset === 0 ||
        usersObj?.meta?.totalCount > usersObj?.users?.length
      ) {
        const { project_id, filterType } = state;
        const params = {
          role_id: roleIds,
          offset,
          limit,
        };
        if (project_id) {
          params.project_id = project_id;
          if (filterType !== FILTER_TYPE_DATE.CUSTOM_DATE?._id) {
            params.filter_type = filterType;
          }
        }
        setUsersBoardKey(paramsToString(params));
        dispatch(userActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      roleIds,
      state,
      usersObj?.meta?.totalCount,
      usersObj?.users?.length,
    ]
  );

  useEffect(() => {
    loadMoreUsers(usersObj?.users?.length, usersObj?.meta?.totalCount);
  }, [usersObj?.meta?.totalCount, usersObj?.users?.length]);

  useEffect(() => {
    if (
      state?.project_id ||
      (prevProps.current?.project_id && !state?.project_id)
    ) {
      loadMoreUsers();
    }

    return () => {
      prevProps.current = {
        project_id: state?.project_id,
      };
    };
  }, [state?.project_id, state?.filterType]);

  return (
    <Popover
      getPopupContainer={(triggerNode) => triggerNode}
      bordered={false}
      title={null}
      trigger="click"
      placement="bottom"
      content={
        <div style={{ display: "flex", gap: 15 }}>
          <ul className="list-item-filter">
            {Object.values(FILTER_TYPE_DATE)?.map((filter) => (
              <li
                key={filter?._id}
                data-value={filter?._id}
                onClick={() => handleClickList(filter?._id)}
                className={filter?._id === state?.filterType ? "active" : ""}
              >
                <span>{filter?.name}</span>
              </li>
            ))}
          </ul>

          {/* <Select
            size="large"
            placeholder={"Status"}
            dropdownMatchSelectWidth={false}
            value={state?.filterType}
            onChange={handleChange("filterType")}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {Object.values(FILTER_TYPE_DATE)?.map((filter) => (
              <Select.Option key={filter?._id} value={filter?._id}>
                {filter?.name}
              </Select.Option>
            ))}
          </Select> */}

          {/* <Select
            size="large"
            placeholder={"Status"}
            dropdownMatchSelectWidth={false}
            value={state?.filterType}
            onChange={handleChange("filterType")}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {Object.values(FILTER_TYPE_DATE)?.map((filter) => (
              <Select.Option key={filter?._id} value={filter?._id}>
                {filter?.name}
              </Select.Option>
            ))}
          </Select> */}
          {state?.filterType === FILTER_TYPE_DATE?.CUSTOM_DATE?._id ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "15px",
                }}
              >
                <DatePicker
                  style={{ width: 170 }}
                  onChange={handleChange("start_date")}
                  placeholder="From"
                  /* value={selectedStartDate} */
                  value={state?.start_date}
                  disabledDate={disabledFutureDate}
                  inputReadOnly={true}
                  allowClear
                />
                <DatePicker
                  style={{ width: 170 }}
                  onChange={handleChange("end_date")}
                  placeholder="To"
                  /* value={selectedEndDate} */
                  value={state?.end_date}
                  disabledDate={disabledFutureDate}
                  inputReadOnly={true}
                  allowClear
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      }
    >
      <Button
        className="new_button fw"
        icon={<FilterOutlined />}
        type="primary"
        size="large"
      >
        <span className="f15">
          {state?.filterType &&
            Object.values(FILTER_TYPE_DATE)?.find(
              (filter) => filter?._id === state?.filterType
            )?.name}
        </span>
      </Button>
    </Popover>
  );
};

export default MyTaskFilterDate;
