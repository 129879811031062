import {
  Col,
  Input,
  Row,
  Button,
  Tooltip,
  Space,
  Badge,
  Select,
  Tag,
} from "antd";
import React, { useState, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { InfoOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import {
  validPassword,
  validEmail,
  isAuthenticated,
  showNotification,
  getStringToOptions,
} from "../utils/commonFunctions";
import routes from "../utils/routes";
import constants from "../utils/constants";
import logo from "../assets/images/writelyForCreator.svg";
import "./FreelancerSignup.scss";
// import OtsuGoogle from "./OtsuGoogle";
import axios from "axios";
import appUrl from "../config/appUrl";
import { useMemo } from "react";
import AppUrl from "../config/appUrl";
import { useEffect } from "react";
import GoogleSignInBtn from "./component/GoogleSignInBtn";

const { EXPERTIES_CATEGORY, INDUSTRY, ROLE_IDS } = constants;

const FreelancerSignup = () => {
  const location = useLocation();
  let navigate = useNavigate();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState({ email: null, password: null });
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);

  useEffect(() => {
    if (location?.state?.data) {
      const { user_id, email, token } = location?.state?.data;
      setState({
        email,
        user_id,
        isSignupWithGoogle: true,
        token,
      });
      setCurrentStep(2);
    }
  }, [location?.state?.data]);

  const hasError = useCallback(() => {
    const { email, password, expertise, industry } = state;
    let error = {};

    switch (currentStep) {
      case 1:
        if (!email?.trim()) {
          error.email = "Email cannot be blank";
        } else if (!validEmail(email)) {
          error.email = "Email is not valid";
        }
        if (!password?.trim()) {
          error.password = "Password cannot be blank";
        } else if (!validPassword(password)) {
          error.password = "Password length should be of minimum 4 characters";
        }
        break;

      case 2:
        if (!expertise) error.expertise = "Please select any option";
        break;

      case 3:
        if (!industry?.length) error.industry = "Please select an industry";
        break;

      default:
        break;
    }

    setError(error);

    return !!Object.keys(error)?.length;
  }, [currentStep, state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((prestate) => ({
        ...prestate,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  const handleClick = useCallback(() => {
    if (!hasError()) {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, hasError]);

  const handleSignupRequest = useCallback(async () => {
    setSubmitLoading(true);
    try {
      const userRes = await axios.post(AppUrl.SIGNUP_URL, {
        email: state?.email,
        password: state?.password,
        first_name: state?.email.split("@")?.[0],
        role_id: ROLE_IDS.FREELANCER,
      });
      let userData = userRes.data;
      if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
        showNotification("error", userData?.meta?.message);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);

        setState((prestate) => ({
          ...prestate,
          user_id: userData?.data?._id,
          token: userData?.data?.token,
        }));
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.log("error", error);
      setSubmitLoading(false);
    }
  }, [currentStep, state?.email, state?.password]);

  const handleEmailSubmit = useCallback(() => {
    if (!hasError()) {
      handleSignupRequest();
    }
  }, [handleSignupRequest, hasError]);

  const updateUser = useCallback(async () => {
    const { user_id, expertise, industry } = state;
    const payload = {
      _id: user_id,
      expertise,
      industry: industry?.join("|"),
    };
    const updateUserRequest = (data) => {
      return axios({
        method: "PUT",
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
        url: `${appUrl.USERS}/${data?._id}`,
        mode: "cors",
        data,
      });
    };

    const response = await updateUserRequest(payload);

    const data = response.data;
    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
    } else if (state?.isSignupWithGoogle) {
      localStorage.setItem(constants.ACCESS_TOKEN, state?.token);
      navigate(routes.DASHBOARD, {
        state: { autoNavigate: true },
        replace: true,
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, navigate, state]);

  const handleFinish = useCallback(() => {
    if (!hasError()) {
      updateUser();
    }
  }, [hasError, updateUser]);

  const handleIndustry = useCallback(
    (ind) => {
      let mIndustry;

      if (!state?.industry?.length) {
        mIndustry = [ind];
      } else {
        mIndustry = state?.industry?.includes(ind)
          ? state?.industry?.filter((item) => item !== ind)
          : [...state?.industry, ind];
      }

      setState((prestate) => ({
        ...prestate,
        industry: mIndustry,
      }));
      setError({});
    },
    [state]
  );

  const submitGoogleCredentials = async (accessToken) => {
    let credentials = {
      accessToken: accessToken,
    };

    setLoadingGoogle(true);
    const loginRequest = (data) => {
      return axios({
        method: "POST",
        url: appUrl.GOOGLE_LOGIN_URL,
        mode: "cors",
        data: {
          access_token: accessToken,
        },
      });
    };

    const response = await loginRequest(credentials);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      setLoadingGoogle(false);
    } else {
      const { token, user } = data.data;
      if (user?.role_id !== ROLE_IDS.FREELANCER || user?.expertise) {
        showNotification("error", "User already exist");
      } else {
        setState((prestate) => ({
          ...prestate,
          email: user?.email,
          user_id: user?._id,
          isSignupWithGoogle: true,
          token,
        }));
        setCurrentStep(currentStep + 1);
      }
      setLoadingGoogle(false);
    }
    setLoadingGoogle(false);
  };

  const strongPasswordTips = useMemo(
    () => (
      <Space direction="vertical">
        <Badge
          color="#1DC96C"
          text={
            <span style={{ color: "#fff", fontSize: "13px" }}>
              Must be minium of 8 characters
            </span>
          }
        />
        <Badge
          color="#1DC96C"
          text={
            <span style={{ color: "#fff", fontSize: "13px" }}>
              Must contain 1 uppercase character
            </span>
          }
        />
        <Badge
          color="#1DC96C"
          text={
            <span style={{ color: "#fff", fontSize: "13px" }}>
              Must contain 1 numeric value
            </span>
          }
        />
        <Badge
          color="#1DC96C"
          text={
            <span style={{ color: "#fff", fontSize: "13px" }}>
              Must contain 1 special character
            </span>
          }
        />
      </Space>
    ),
    []
  );

  const tagRender = useCallback((props) => {
    const { label, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"#F6D1FF"}
        onMouseDown={onPreventMouseDown}
        closeIcon={<CloseOutlined style={{ color: "#000" }} />}
        closable
        onClose={onClose}
        style={{
          color: "#000",
          fontSize: "16px",
          fontWeight: "700",
          margin: "3px 3px 3px 0",
          lineHeight: "200%",
        }}
      >
        {label}
      </Tag>
    );
  }, []);

  const popularIndustries = useMemo(
    () =>
      getStringToOptions(INDUSTRY)?.filter(
        (item) => item !== "Others" && item?.length < 18
      ),
    []
  );

  if (isAuthenticated()) {
    const pathname = location.state?.from;

    if (pathname?.pathname) {
      return <Navigate to={pathname?.pathname} replace />;
    } else {
      return (
        <Navigate
          to={routes.DASHBOARD}
          state={{ autoNavigate: true }}
          replace
        />
      );
    }
  }

  return (
    <div className="signup-container">
      <div className="logo-container pointer">
        <img src={logo} alt="Writely" className="logo" />
      </div>

      {/* Step 1 start */}
      {currentStep === 1 && (
        <div className="container-step-1">
          <Row align="middle" justify="end" className="mt20">
            <span className="step">Step 1/3</span>
          </Row>
          <Row className="info mt20 mb20">
            Welcome to Writely! Sign up with writely in 3 easy to follow steps
          </Row>

          <Row className="input-container mb20">
            <label>Email</label>
            <Input
              className="fw"
              placeholder="Enter your email address"
              size="large"
              onChange={handleChange("email")}
              onPressEnter={handleEmailSubmit}
              value={state?.email}
              disabled={submitLoading}
            />
            <Row className="error">{error?.email}</Row>
          </Row>

          <Row className="input-container mb20">
            <Col span={24}>
              <Row justify="space-between">
                <label>Password</label>
                <Row align="middle" className="pointer">
                  <Tooltip title={strongPasswordTips} placement="right">
                    <InfoOutlined
                      className="mr5"
                      style={{
                        backgroundColor: "#F6D1FF",
                        borderRadius: "50%",
                        border: "none",
                        width: "16px",
                        height: "16px",
                        fontSize: "12px",
                        paddingTop: "2px",
                      }}
                    />
                    Tips for creating a strong password
                  </Tooltip>
                </Row>
              </Row>
            </Col>
            <Input
              type="password"
              placeholder="Create a strong password"
              size="large"
              onChange={handleChange("password")}
              value={state?.password}
              onPressEnter={handleEmailSubmit}
              disabled={submitLoading}
            />

            <Row className="error">{error?.password}</Row>
          </Row>

          {/* Buttons Container Start */}
          <Button
            type="primary"
            block
            onClick={handleEmailSubmit}
            loading={submitLoading}
            size="large"
            className="signup-btn mb20"
          >
            Join Writely
          </Button>
          <Row justify="center" className="mb20 bold">
            Or sign up with
          </Row>

          <GoogleSignInBtn
            text="Join with Google"
            loading={isLoadingGoogle}
            onSignInSuccess={submitGoogleCredentials}
          />

          {/* <OtsuGoogle
            isLoadingGoogle={isLoadingGoogle}
            valueText={"Join with Google"}
            submitGoogleCredentials={submitGoogleCredentials}
          /> */}
          {/* Buttons Container End */}
        </div>
      )}
      {/* Step 1 end */}

      {/* Step 2 start */}
      {currentStep === 2 && (
        <div className="container-step-2">
          <Row align="middle" justify="end" className="mt20">
            <span className="step">Step 2/3</span>
          </Row>
          <Row className="info mt20">
            <p>
              🤟 Hi <span>{state?.email.split("@")?.[0] ?? "Creator"}!</span>{" "}
              Nice to meet you. Start with telling us about your expertise
            </p>
          </Row>
          <Row className="items-container">
            {Object.keys(EXPERTIES_CATEGORY).map((key) => (
              <div
                key={key}
                className={`item-card ${
                  state?.expertise === key ? "active" : ""
                }`}
                onClick={() => {
                  setState((prestate) => ({
                    ...prestate,
                    expertise: key,
                  }));
                  setError({});
                }}
              >
                <span>{key}</span>
                <p>{EXPERTIES_CATEGORY[key]}</p>
              </div>
            ))}
            <Row className="error">{error?.expertise}</Row>
          </Row>

          <Button
            type="primary"
            onClick={handleClick}
            size="large"
            className="signup-btn mb20"
          >
            Join Writely
          </Button>
        </div>
      )}
      {/* Step 2 end */}

      {/* Step 3 start */}
      {currentStep === 3 && (
        <div className="container-step-3">
          <Row align="middle" justify="end" className="mt20">
            <span className="step">Step 3/3</span>
          </Row>
          <Row className="info-3 mt10 mb20">You are almost there!</Row>
          <div className="industry-container">
            <p>Last Step. Select your Industry experience</p>
            <Select
              className="fw"
              size="large"
              mode="multiple"
              tagRender={tagRender}
              value={state?.industry}
              placeholder={
                <React.Fragment>
                  <SearchOutlined />
                  &nbsp; Search for your Industry
                </React.Fragment>
              }
              onChange={handleChange("industry")}
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
            >
              {getStringToOptions(INDUSTRY).map((option, i) => (
                <Select.Option key={i} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
            <Row className="error">{error?.industry}</Row>
            <div className="popular-industries mt20">
              {popularIndustries?.map((ind) => (
                <div
                  className={`industry ${
                    state?.industry?.includes(ind) ? "active" : ""
                  }`}
                  key={ind}
                  onClick={() => handleIndustry(ind)}
                >
                  <p>{ind}</p>
                </div>
              ))}
            </div>
            <Button
              type="primary"
              onClick={handleFinish}
              size="large"
              className="signup-btn mt20"
            >
              Finish
            </Button>
          </div>
        </div>
      )}
      {/* Step 3 end */}

      {/* Step 4 start */}
      {currentStep === 4 && (
        <div className="container-step-4">
          <p className="info-bold">Click the link in your email!</p>
          {state?.isSignupWithGoogle ? (
            <p className="info">
              Your Writely account has been successfully created. To log in,
              please use <i>Continue with Google on</i>{" "}
              <a href="https://app.writely.io/creator">
                app.writely.io/creator
              </a>
            </p>
          ) : (
            <p className="info">
              Follow the instructions in the email we just sent to
              <span> {state?.email}</span> to verify your account on Writerly.
              Do not forget to check your spam folder.
            </p>
          )}
        </div>
      )}
      {/* Step 4 end */}
    </div>
  );
};

export default FreelancerSignup;
