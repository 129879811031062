import React, { useEffect, useMemo } from "react";
import { Col, Row } from "antd";
import StarImage from "../assets/images/star.svg";
import TickImage from "../assets/images/tick_new.svg";
import FileImage from "../assets/images/file.svg";
import CoinImage from "../assets/images/coin.svg";

import { dashboardActions } from "./duck/dashboardReducer";
import "./Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";

import ProjectListDashboardContainer from "./components/ProjectListDashboardContainer";

import { abbreviateNumber } from "../utils/commonFunctions";
import ToDoListContainer from "./components/ToDoListContainer";
import OtherTaskListContainer from "./components/OtherTaskListContainer";
import ProjectGraph from "./components/ProjectGraph";
import RevenueGraph from "./components/RevenueGraph";
import ItemGraph from "./components/ItemGraph";
import constants from "../utils/constants";

const { CURRENCIES } = constants;

const Dashboard = () => {
  const dispatch = useDispatch();

  const { dashboard } = useSelector(({ dashboard }) => dashboard);

  const cardData = useMemo(() => {
    const {
      revenue_task_euro,
      revenue_task_gbp,
      revenue_task_inr,
      revenue_task_usd,
      task_in_progress_count,
      total_projects_count,
      total_word_count,
    } = dashboard ?? {};
    return [
      {
        name: "Total Project Handled",
        value: total_projects_count || 0,
        icon: StarImage,
      },
      {
        name: "Total Tasks Opened",
        value: task_in_progress_count || 0,
        icon: TickImage,
      },
      {
        name: "Total Word Count",
        value: abbreviateNumber(Number(total_word_count || 0), 1),
        icon: FileImage,
      },
      {
        name: "Rupee Market Revenue",
        value: `${CURRENCIES.INR.SYMBOL} ${abbreviateNumber(
          Number(revenue_task_inr || 0),
          1
        )}`,
        icon: CoinImage,
      },
      {
        name: "US Dollar Market Revenue",
        value: `${CURRENCIES.USD.SYMBOL} ${abbreviateNumber(
          Number(revenue_task_usd || 0),
          1
        )}`,
        icon: CoinImage,
      },
      {
        name: "Euro Market Revenue",
        value: `${CURRENCIES.EURO.SYMBOL} ${abbreviateNumber(
          Number(revenue_task_euro || 0),
          1
        )}`,
        icon: CoinImage,
      },
      {
        name: "Pound Market Revenue",
        value: `${CURRENCIES.GBP.SYMBOL} ${abbreviateNumber(
          Number(revenue_task_gbp || 0),
          1
        )}`,
        icon: CoinImage,
      },
    ];
  }, [dashboard]);

  useEffect(() => {
    dispatch(dashboardActions.getDashboard());
  }, [dispatch]);

  return (
    <div className="content-body app-container dashboard-container">
      <div className="left-panel">
        <div className="card-container">
          {cardData.map((card) => (
            <div key={card.name} className="card pri-box-shadow">
              <Row justify="space-between" wrap={false}>
                <Col>
                  <p>{card.value}</p>
                  <span className="whitespace-nowrap">{card.name}</span>
                </Col>

                <img src={card.icon} alt="" />
              </Row>
            </div>
          ))}
        </div>
        <ProjectGraph />
        <ItemGraph />
        <RevenueGraph />
      </div>
      <div className="right-panel">
        <ToDoListContainer />
        <ProjectListDashboardContainer />
        <OtherTaskListContainer />
      </div>
    </div>
  );
};

export default React.memo(Dashboard);
