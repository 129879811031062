import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Input, Row, Divider } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import { ReloadOutlined, LeftOutlined } from "@ant-design/icons";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Watermark from "../assets/images/open_ai_kg.svg";

import GenerateContentContainer from "./components/GenerateContentContainer";
import MRadioGroup from "../components/MRadioGroup";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import Loading from "../components/Loading";

import { openAiActions } from "./duck/openAiReducer";
import { useQuery } from "../utils/customHooks";

import appUrl from "../config/appUrl";
import {
  arrayToMap,
  hasApiCallError,
  showNotification,
} from "../utils/commonFunctions";
import routes from "../utils/routes";
import constants from "../utils/constants";

import "./OpenAi.scss";

const { AUTO_GENERATE_CONTENT_TYPES, OPEN_AI_TASK_FIELDS } = constants;
const DEFAULT_STEP = 1;
// const MAX_STEPS = 1;

const KeywordGenerator = () => {
  const prevProps = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const openAi = useSelector(({ openAi }) => openAi);
  const mTask = useMemo(() => {
    const taskId = query.get("task_id");
    return openAi.map[taskId] || {};
  }, [query, openAi.map]);

  const [state, setState] = useState({ currentStep: DEFAULT_STEP });
  const [error, setError] = useState({});
  const [responses, setResponses] = useState({});
  const [responseAnswers, setResponseAnswers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      prevProps.current?.loading &&
      !openAi?.loading &&
      state.isTaskCreating
    ) {
      const searchParams = {
        task_id: openAi?.newCreatedDataId,
      };

      const navigateTo = {
        search: `?${createSearchParams(searchParams)}`,
      };
      navigate(navigateTo, { replace: true });
      setState((prevState) => ({ ...prevState, isTaskCreating: false }));
    } else if (
      prevProps.current?.loading &&
      !mTask?.loading &&
      state.isTaskSaving
    ) {
      if (!mTask?.error) {
        const navigateTo = routes.OPEN_AI_DASHBOARD;
        navigate(navigateTo, { replace: true });
      }
    }

    return () => {
      prevProps.current = {
        loading: openAi?.loading || mTask?.loading,
        isTaskCreating: state.isTaskCreating,
        isTaskSaving: state.isTaskSaving,
      };
    };
  }, [
    openAi?.newCreatedDataId,
    openAi?.loading,
    state?.isTaskCreating,
    state?.isTaskSaving,
    mTask?.loading,
    mTask?.error,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    const taskId = query.get("task_id");
    if (!taskId) {
      setState((prevState) => ({ ...prevState, isTaskCreating: true }));
      const payload = {
        fields: [...OPEN_AI_TASK_FIELDS],
      };
      payload.fields.forEach((field) => {
        if (field._id === "content_type") {
          field.value = AUTO_GENERATE_CONTENT_TYPES.KEYWORD_GENERATOR.name;
        }
      });
      dispatch(openAiActions.onCreateOneRequest(payload));
    } else {
      dispatch(openAiActions.onGetOneRequest({ _id: taskId }));
    }
  }, [query, dispatch]);

  const hasError = useCallback(() => {
    let { currentStep, description } = state;
    description = description?.trim?.();

    const error = {};

    if (currentStep === 1) {
      if (!description) {
        error.description =
          "Please enter description of your product or service";
      }
    }

    setError(error);
    return Object.keys(error).length > 0;
  }, [state]);

  const generateKeywordsApi = useCallback(
    (temperature) => {
      if (temperature || !hasError()) {
        setLoading(true);

        let { currentStep, description } = state;
        description = description?.trim?.();

        let text = `Give 5 set of keywords for ${description}. Each set should have atleast 10 keywords and in one single string.`;

        const payload = { text };
        if (temperature) payload.temperature = 0.5;
        if (mTask?._id) payload.task_id = mTask?._id;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              const choiceText = resData?.data?.content;

              let answers =
                choiceText?.split?.(
                  choiceText?.includes("\n\n") ? "\n\n" : "\n"
                ) || [];
              if (Array.isArray(answers) && answers.length > 0) {
                answers = answers.filter((answer) => answer?.trim?.());
                answers = answers.map((answer, i) => {
                  answer =
                    answer
                      ?.match?.(/(?:"[^"]*"|^[^"]*$)/)?.[0]
                      ?.replace?.(/"/g, "") || answer;

                  if (answer.startsWith(`${i + 1}.`)) {
                    answer = answer?.replace(`${i + 1}.`, "");
                  }

                  return answer?.trim?.();
                });

                answers = answers.filter((answer) => answer?.trim?.());
                setResponseAnswers(answers);
              }

              setResponses((prevResponses) =>
                Object.assign({}, prevResponses, { [currentStep]: resData })
              );

              setState((prevState) => ({
                ...prevState,
                // finalDraft: choiceText,
                currentStep: temperature
                  ? prevState.currentStep
                  : prevState.currentStep + 1,
              }));
            }

            setLoading(false);
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            setLoading(false);
          });
      }
    },
    [state, mTask, hasError]
  );

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const onGoBack = useCallback(() => {
    if (state.currentStep <= 1) {
      navigate(-1);
      return;
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  }, [state.currentStep, navigate]);

  const onSubmit = useCallback(() => {
    setState((prevState) => ({ ...prevState, finalDraft: "" }));
    generateKeywordsApi();
  }, [generateKeywordsApi]);

  const onSelect = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;
      let finalDraft = state.finalDraft || "";

      if (!finalDraft.replace(/<(.|\n)*?>/g, "").trim()) finalDraft = "";
      if (finalDraft) finalDraft = `${finalDraft}<br>`;
      finalDraft = `${finalDraft}<p>${value}</p>`;

      setState((prevState) => ({ ...prevState, finalDraft }));
      setError({});
    },
    [state.finalDraft]
  );

  const handleShowOpenAiTaskModal = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
    }));
  }, []);

  const onSave = useCallback(
    (fields = []) => {
      const { _id } = mTask;

      const payload = {
        _id: _id,
        name: "",
        fields: [],
        draft: false,
      };

      const fieldMap = Object.assign(
        {},
        arrayToMap(mTask?.fields),
        arrayToMap(fields)
      );
      payload.fields = mTask?.fields?.map((field) => {
        const newField = Object.assign({}, field, fieldMap[field?._id]);
        if (newField?._id === "content_type") {
          newField.value = AUTO_GENERATE_CONTENT_TYPES.KEYWORD_GENERATOR.name;
        } else if (newField?._id === "task_name") {
          payload.name = newField.value;
        } else if (newField?._id === "content") {
          newField.value = state.finalDraft;
        }

        return newField;
      });

      setState((prevState) => ({
        ...prevState,
        showOpenAiTaskModal: false,
        isTaskSaving: true,
      }));
      dispatch(openAiActions.onUpdateOneRequest(payload));
    },
    [mTask, state, dispatch]
  );

  if (
    (!openAi.newCreatedDataId && openAi.loading) ||
    state.isTaskCreating ||
    (!mTask?._id && mTask?.loading)
  ) {
    return <Loading />;
  }

  return (
    <>
      <GenerateContentContainer containerClassName="set-container">
        <Row className="content-container">
          {state.currentStep === 1 && (
            <Row className="fw" justify="space-between">
              <Col
                className="--left-container"
                xs={24}
                sm={24}
                md={24}
                lg={11}
                xl={11}
                xxl={11}
              >
                <Col className="title-container mb15">
                  <Row className="d-flex-open-ai" align="middle">
                    <div className="back-icon mr-3">
                      <LeftOutlined className="text-2xl" onClick={onGoBack} />
                    </div>
                    <span>Keyword Generator</span>
                  </Row>
                </Col>

                <Col className="mb15">
                  <Col className="mb5">
                    <label className="label">
                      What's the focus of your post?
                    </label>
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>

                  <Input.TextArea
                    className="input input-area"
                    placeholder="eg. Interior Designing"
                    value={state.description}
                    autoSize={{ minRows: 5, maxRows: 8 }}
                    onChange={handleChange("description")}
                    size="large"
                    disabled={loading}
                  />

                  <Row className="error mt5">{error.description}</Row>
                </Col>

                <Col className="mt20">
                  <Button
                    className="fw"
                    type="primary"
                    loading={loading}
                    onClick={onSubmit}
                    size="large"
                  >
                    Create Content
                  </Button>
                </Col>
              </Col>

              <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} align="center">
                <Divider type="vertical" />
              </Col>

              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} align="center">
                <Divider />
              </Col>

              <Col
                className="--right-container"
                xs={24}
                sm={24}
                md={24}
                lg={11}
                xl={11}
                xxl={11}
              >
                <img className="h-64 w-full" alt="watermark" src={Watermark} />
              </Col>
            </Row>
          )}

          {state.currentStep === 2 && (
            <Row className="fw" justify="space-between">
              <Col
                className="--left-container"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={11}
                xxl={11}
              >
                {/* keywords description start */}
                <Col className="mb-24">
                  <Col className="title-container mb15">
                    <Row className="d-flex-open-ai" align="middle">
                      <div className="back-icon mr-3">
                        <LeftOutlined className="text-2xl" onClick={onGoBack} />
                      </div>
                      <span>Choose your keyword description</span>
                    </Row>
                  </Col>

                  <Col className="mb15">
                    <MRadioGroup
                      values={responseAnswers}
                      onChange={onSelect()}
                    />
                  </Col>

                  <Col className="mt15">
                    <Button
                      className="fw"
                      type="primary"
                      disabled={loading}
                      onClick={() => generateKeywordsApi(0.5)}
                      size="large"
                    >
                      <Row align="middle" justify="center">
                        <ReloadOutlined className="mr-2.5" spin={loading} />
                        Regenerate
                      </Row>
                    </Button>
                  </Col>
                </Col>
                {/* keywords description end */}
              </Col>

              <Col
                className="--right-container"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={11}
                xxl={11}
              >
                <Col className="sticky top-0">
                  <Col className="h-96 mb-16">
                    <ReactQuill
                      className="input-text-editor h-full"
                      value={state.finalDraft}
                      onChange={handleChange("finalDraft")}
                      theme="snow"
                    />
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row
                      className="w-full"
                      align="middle"
                      justify="space-between"
                    >
                      {mTask?._id && (
                        <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                          <Button
                            className="w-full"
                            type="success"
                            size="large"
                            loading={mTask?.loading}
                            onClick={() => handleShowOpenAiTaskModal(true)}
                          >
                            {mTask?.loading ? "Saving" : "Save"}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Col>
              </Col>
            </Row>
          )}
        </Row>
      </GenerateContentContainer>

      <OpenAiTaskModal
        visible={state.showOpenAiTaskModal}
        taskId={mTask?._id}
        fields={mTask?.fields?.filter((field) => field?._id === "task_name")}
        onSubmit={onSave}
        handleModal={handleShowOpenAiTaskModal}
      />
    </>
  );
};

export default KeywordGenerator;
