import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Input,
  Layout,
  Progress,
  Rate,
  Row,
  Radio,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";

import Task from "../models/Task";
import TaskChat from "./components/TaskChat";
import NotFound from "../components/NotFound";
import Loading from "../components/Loading";

import { taskActions } from "./duck/taskReducer";
import constants from "../utils/constants";

import "./TaskDetails.scss";

const TaskDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const isIAdmin = useMemo(() => {
    const {
      ROLE_IDS: { ADMIN, SUPER_ADMIN },
    } = constants;
    const mRoleId = loggedInUser?.role_id;

    return mRoleId === SUPER_ADMIN || mRoleId === ADMIN;
  }, [loggedInUser?.role_id]);

  const taskObj = useSelector(({ task }) => task.map[id]);
  const mTask = useMemo(() => new Task().fromMap(taskObj), [taskObj]);
  const {
    assignedUser,
    assignmentDetails,
    submissionDetails,
    feedbackDetails,
    currentStep,
  } = mTask;

  const isMyTask = useMemo(
    () => loggedInUser?._id === assignedUser._id,
    [loggedInUser?._id, assignedUser._id]
  );

  const {
    isAssignmentAssignDone,
    isAssignmentSubmitDone,
    isAssignmentFeedbackDone,
  } = useMemo(() => {
    const isAssignmentAssignDone = assignmentDetails.isDone;
    const isAssignmentSubmitDone = submissionDetails.isDone;
    const isAssignmentFeedbackDone = feedbackDetails.isDone;

    return {
      isAssignmentAssignDone,
      isAssignmentSubmitDone,
      isAssignmentFeedbackDone,
    };
  }, [
    assignmentDetails.isDone,
    submissionDetails.isDone,
    feedbackDetails.isDone,
  ]);

  const {
    isAssignmentDetailsPanelDisabled,
    isAssignmentSubmitPanelDisabled,
    isAssignmentFeedbackPanelDisabled,
  } = useMemo(() => {
    const isAssignmentDetailsPanelDisabled =
      taskObj?.loading || isAssignmentAssignDone;
    const isAssignmentSubmitPanelDisabled =
      taskObj?.loading || isAssignmentSubmitDone;
    const isAssignmentFeedbackPanelDisabled =
      taskObj?.loading || isAssignmentFeedbackDone;

    return {
      isAssignmentDetailsPanelDisabled,
      isAssignmentSubmitPanelDisabled,
      isAssignmentFeedbackPanelDisabled,
    };
  }, [
    isAssignmentAssignDone,
    isAssignmentSubmitDone,
    isAssignmentFeedbackDone,
    taskObj?.loading,
  ]);

  const [state, setState] = useState({
    assignment: {},
    submission: {},
    feedback: {},
  });
  const [error, setError] = useState({
    assignment: {},
    submission: {},
    feedback: {},
  });

  const currentPanelKey = useMemo(() => {
    let parentName = "";
    if (!isAssignmentAssignDone) {
      parentName = "assignment";
    } else if (!isAssignmentSubmitDone) {
      parentName = "submission";
    } else if (!isAssignmentFeedbackDone) {
      parentName = "feedback";
    }

    return parentName;
  }, [
    isAssignmentAssignDone,
    isAssignmentSubmitDone,
    isAssignmentFeedbackDone,
  ]);

  useEffect(() => {
    if (!taskObj) {
      dispatch(taskActions.onGetOneRequest({ _id: id }));
    } else if (mTask._id) {
      let assignment = {};
      if (mTask.assignmentDetails.isDone) {
        assignment = { ...mTask.assignmentDetails };
      }

      let submission = {};
      if (mTask.submissionDetails.isDone) {
        submission = { ...mTask.submissionDetails };
      }

      let feedback = {};
      if (mTask.feedbackDetails.isDone) {
        feedback = { ...mTask.feedbackDetails };
        mTask.feedbackDetails.criteria.forEach((c, i) => {
          feedback[`criteria${i}`] = c.score;
        });
      }

      setState({
        assignment,
        submission,
        feedback,
      });
    }
  }, [dispatch, id, taskObj, mTask, currentPanelKey]);

  const handleChange = useCallback(
    (name) => (event) => {
      const value = event?.target?.value ?? event;

      setState((prevState) => {
        const newState = prevState[currentPanelKey] || {};
        newState[name] = value;

        return Object.assign(prevState, { [currentPanelKey]: newState });
      });

      setError({});
    },
    [currentPanelKey]
  );

  const hasError = useCallback(() => {
    const currentPanelState = state[currentPanelKey] || {};

    const error = {};

    if (currentPanelKey === "assignment" || currentPanelKey === "submission") {
      let { description } = currentPanelState;
      description = description?.trim?.();

      if (!description) {
        error.description = "Please enter description";
      }
    } else if (currentPanelKey === "feedback") {
      let { status, rating } = currentPanelState;
      status = status?.trim?.();
      if (!status) {
        error.status = "Please select status";
      }
      if (!rating) {
        error.rating = "Please provide performance rating";
      }
    }

    setError({ [currentPanelKey]: error });

    return !!Object.keys(error).length;
  }, [state, currentPanelKey]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const currentPanelState = state[currentPanelKey] || {};

      let data = {};

      let action;
      if (
        currentPanelKey === "assignment" ||
        currentPanelKey === "submission"
      ) {
        const { description } = currentPanelState;

        data = new FormData();
        data.append("_id", id);
        data.append("description", description);

        if (currentPanelKey === "assignment") {
          action = taskActions.onAssignRequest;
        } else {
          action = taskActions.onSubmitRequest;
        }
      } else if (currentPanelKey === "feedback") {
        const { comment, status } = currentPanelState;

        data.rating = currentPanelState?.rating;
        data.comment = comment;
        data.status = status;

        action = taskActions.onFeedbackRequest;
      }
      data._id = id;

      if (action) {
        dispatch(action(data));
      }
    }
  }, [hasError, state, currentPanelKey, id, dispatch]);

  const genExtra = useCallback(
    () => <DownOutlined onClick={(event) => event.stopPropagation()} />,
    []
  );

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  // check if task is fetching
  if (!taskObj?._id && taskObj?.loading) {
    return <Loading />;
  }
  // check if task exist
  else if (!taskObj?._id) {
    return <NotFound subTitle={"Sorry, this task does not exist."} />;
  }

  const canSubmit =
    isIAdmin || (isMyTask && isAssignmentAssignDone && !isAssignmentSubmitDone);

  return (
    <Layout className="task-details-page">
      <Layout>
        <Layout.Content className="content-body">
          <Row
            className="page-header mb15"
            justify="space-between"
            align="middle"
          >
            <Row className="page-heading">
              {assignedUser.firstName} {assignedUser.lastName}
            </Row>

            <Row>
              <Button onClick={onGoBack}>
                <ArrowLeftOutlined />
                Back
              </Button>
            </Row>
          </Row>

          <Col className="page-body">
            {/* details section start */}
            <Col className="section">
              <Row className="mb15">
                <Col className="sub-heading">Details</Col>
              </Row>

              <Row className="mb15">
                <Col span={12}>
                  <Col className="label">First Name</Col>
                  <Col className="value">{assignedUser.firstName}</Col>
                </Col>

                <Col span={12}>
                  <Col className="label">Last Name</Col>
                  <Col className="value">{assignedUser.lastName}</Col>
                </Col>
              </Row>

              <Row className="mb15">
                <Col span={12}>
                  <Col className="label">Email Address</Col>
                  <Col className="email">
                    {assignedUser.email ? (
                      <a href={`mailto:${assignedUser.email}`}>
                        {assignedUser.email}
                      </a>
                    ) : (
                      "-"
                    )}
                  </Col>
                </Col>

                <Col span={12}>
                  <Col className="label">Address</Col>
                  <Col className="value">{assignedUser.address || "-"}</Col>
                </Col>
              </Row>

              <Row className="mb15">
                <Col span={12}>
                  <Col className="label">Industry</Col>

                  <Col className="value">
                    <Tag>
                      {assignedUser?.industry ? assignedUser?.industry : "-"}
                    </Tag>
                  </Col>
                </Col>

                <Col span={12}>
                  <Col className="label">Linkedin</Col>
                  <Col className="link">
                    {assignedUser.linkedinUrl ? (
                      <a href={assignedUser.linkedinUrl} target="blank">
                        {assignedUser.linkedinUrl}
                      </a>
                    ) : (
                      "-"
                    )}
                  </Col>
                </Col>
              </Row>

              <Row className="mb15">
                <Col span={24}>
                  <Col className="label">Sub Industry</Col>

                  <Col className="value">
                    <Tag>
                      {assignedUser?.sub_industry
                        ? assignedUser?.sub_industry
                        : "-"}
                    </Tag>
                  </Col>
                </Col>
              </Row>

              <Row className="mb15">
                <Col span={12}>
                  <Col className="label">Resume</Col>
                  <Col className="value">
                    {assignedUser.resume?._id ? (
                      <Row
                        className="pointer"
                        align="middle"
                        onClick={() => window.open(assignedUser.resume?.url)}
                      >
                        {assignedUser.resume.getFileTypeIcon()}
                        {assignedUser.resume.name}
                      </Row>
                    ) : (
                      "-"
                    )}
                  </Col>
                </Col>
                <Col span={12}>
                  <Col className="label">Sample Work</Col>
                  <Row className="value" align="middle">
                    {assignedUser.sampleWork.length
                      ? assignedUser.sampleWork.map((sWork, i) => (
                          <Row
                            key={i}
                            className="pointer mr15"
                            align="middle"
                            onClick={() => window.open(sWork?.url)}
                          >
                            {sWork.getFileTypeIcon()} {sWork.name}
                          </Row>
                        ))
                      : "-"}
                  </Row>
                </Col>
              </Row>

              {/* <Row className="mb15">
                <Col span={12}>
                  <Col className="label mb10">Status</Col>

                  <Col className="value">
                    {currentStep && (
                      <Tag color={currentStep?.color}>
                        {currentStep?.message || "-"}
                      </Tag>
                    )}
                  </Col>
                </Col>

                <Col span={12}></Col>
              </Row> */}
            </Col>
            {/* details section  end */}

            {/* assignment section start */}
            {(isIAdmin || isAssignmentAssignDone) && (
              <Col className="section">
                <Collapse
                  className="collapseable-container mb15"
                  defaultActiveKey={[
                    "AssignmentPanel",
                    "SubmissionPanel",
                    "FeedbackPanel",
                  ]}
                >
                  {/* assignment panel start */}
                  <Collapse.Panel
                    key={"AssignmentPanel"}
                    className="panel"
                    header={"Assignment"}
                    showArrow={false}
                    extra={genExtra()}
                  >
                    <Row className="mb15">
                      <Col span={24}>
                        <Col className="label mb5">Description</Col>

                        {isAssignmentAssignDone ? (
                          <Col>{state?.assignment?.description}</Col>
                        ) : (
                          <Input.TextArea
                            className="custom-input"
                            placeholder="Share details here"
                            value={state?.assignment?.description}
                            onChange={handleChange("description")}
                            autoSize={{ minRows: 4, maxRows: 6 }}
                            disabled={isAssignmentDetailsPanelDisabled}
                          />
                        )}

                        <Row className="error">
                          {error?.assignment?.description}
                        </Row>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                  {/* assignment panel end */}

                  {/* assignment submission panel start */}
                  {isAssignmentAssignDone && (
                    <Collapse.Panel
                      key={"SubmissionPanel"}
                      className="panel"
                      header={"Submission"}
                      showArrow={false}
                      extra={genExtra()}
                    >
                      <Row className="mb15">
                        <Col className="label mb5">Description</Col>

                        <Col span={24}>
                          {isAssignmentSubmitDone ? (
                            <Col>{state?.submission?.description}</Col>
                          ) : (
                            <Input.TextArea
                              className="custom-input"
                              placeholder="Share details here"
                              value={state?.submission?.description}
                              onChange={handleChange("description")}
                              autoSize={{ minRows: 4, maxRows: 6 }}
                              disabled={isAssignmentSubmitPanelDisabled}
                            />
                          )}

                          <Row className="error">
                            {error?.submission?.description}
                          </Row>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  )}
                  {/* assignment submission panel end */}

                  {/* feedback panel start */}
                  {(isAssignmentFeedbackDone ||
                    (isAssignmentSubmitDone && isIAdmin)) && (
                    <Collapse.Panel
                      key="FeedbackPanel"
                      className="panel feedback-panel"
                      header="Feedback"
                      extra={genExtra()}
                      showArrow={false}
                    >
                      <Row className="mb15">
                        <Col span={24} className="mb15">
                          <Col className="label mb10">Any Other comments</Col>

                          {isAssignmentFeedbackDone ? (
                            <Col>{state?.feedback?.comment || "-"}</Col>
                          ) : (
                            <Input.TextArea
                              className="custom-input"
                              placeholder="Any your comment here"
                              value={state?.feedback?.comment}
                              onChange={handleChange("comment")}
                              autoSize={{ minRows: 3, maxRows: 5 }}
                              disabled={isAssignmentFeedbackPanelDisabled}
                            />
                          )}

                          <Row className="error">
                            {error?.feedback?.comment}
                          </Row>
                        </Col>

                        <Col span={24} className="mb15">
                          <Row align="middle">
                            <Col span={4}>Rating</Col>
                            <Col span={14}>
                              <Rate
                                value={state.feedback?.rating}
                                onChange={handleChange("rating")}
                                disabled={isAssignmentFeedbackPanelDisabled}
                              />
                            </Col>
                          </Row>
                          <Row className="error">{error?.feedback?.rating}</Row>
                        </Col>

                        <Col span={24} className="mb15">
                          <Col className="label mb10">Status</Col>

                          {isAssignmentFeedbackDone ? (
                            <Col className="capitalize">
                              <Tag
                                className="status capitalize"
                                color={currentStep?.color}
                              >
                                {state?.feedback?.status}
                              </Tag>
                            </Col>
                          ) : (
                            <Radio.Group
                              onChange={handleChange("status")}
                              value={state?.feedback?.status}
                            >
                              {Object.values(Task.STATUS).map((s, i) => (
                                <Radio key={i} value={s} className="capitalize">
                                  {s}
                                </Radio>
                              ))}
                            </Radio.Group>
                          )}

                          <Row className="error">{error?.feedback?.status}</Row>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  )}
                  {/* feedback panel end */}
                </Collapse>
              </Col>
            )}
            {/* assignment section end */}
          </Col>
        </Layout.Content>

        <Layout.Footer className="footer">
          <Row align="middle" justify="space-between">
            <Row />

            <Row>
              {!isAssignmentFeedbackDone && canSubmit ? (
                <Button
                  type="primary"
                  loading={taskObj?.loading}
                  onClick={onSubmit}
                  size="large"
                >
                  Submit
                </Button>
              ) : (
                <Tag className="status capitalize" color={currentStep?.color}>
                  {currentStep?.message || "-"}
                </Tag>
              )}
            </Row>
          </Row>
        </Layout.Footer>
      </Layout>

      <TaskChat id={id} onModel={constants.ON_MODEL.TASK} />
    </Layout>
  );
};

export default TaskDetails;
