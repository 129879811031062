import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Input, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CustomModal from "../../components/CustomModal";

import { userActions } from "../../users/duck/userReducer";
import { validEmail } from "../../utils/commonFunctions";

const InviteFreelancerModal = (props) => {
  const { visible, handleModal, filter } = props;

  const prevProp = useRef();
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const { loading, error } = user;
  const [state, setState] = useState({});
  const [formError, setFormError] = useState({});

  useEffect(() => {
    if (prevProp?.current?.loading && !loading) {
      if (!error) {
        handleModal(false);
      }
    } else if (!prevProp?.current?.visible && visible) {
      setFormError({});
      setState({
        first_name: null,
        last_name: null,
        email: null,
      });
    }

    return () => {
      prevProp.current = { loading, visible };
    };
  }, [loading, visible, error, handleModal]);

  const hasError = useCallback(() => {
    let { first_name, email } = state;
    let error = {};

    email = email?.trim();
    if (!email) {
      error.email = "Please enter member email";
    } else if (!validEmail(email)) {
      error.email = "Please enter valid email";
    }
    if (!first_name) {
      error.first_name = "Please enter First Name";
    }

    setFormError(error);
    return !!Object.keys(error).length;
  }, [state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked;

      setFormError({});
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
  let { first_name, last_name, email } = state;

  const onOk = useCallback(() => {
    if (!hasError()) {
      let newUser = {
        first_name,
        last_name,
        email,
        role_id: "freelancer",
        filter,
      };
      dispatch(userActions.onCreateOneRequest(newUser));
    }
  }, [dispatch, email, filter, first_name, hasError, last_name]);

  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        <Button
          className="new_button"
          type="primary"
          onClick={onOk}
          size="large"
          loading={loading}
        >
          Add
        </Button>
        <Button
          className="new_button"
          type="primary-outline"
          onClick={handleModal}
          size="large"
        >
          Cancel
        </Button>
      </Row>
    );
  }, [handleModal, onOk, loading]);

  return (
    <CustomModal
      visible={visible}
      title={"Add Writer"}
      okButtonText={"Add"}
      onCancel={props?.handleModal}
      footer={renderFooter}
    >
      <div>
        <Col className="mb10">
          <Col className="mb5">
            <label className="label">First Name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
              size="large"
              type="text"
              value={first_name}
              placeholder="Jhon"
              onChange={handleChange("first_name")}
            />
            <Row className="error mt5">{formError?.first_name}</Row>
          </Col>
        </Col>

        <Col className="mb10">
          <Col className="mb5">
            <label className="label">Last Name</label>
          </Col>
          <Col>
            <Input
              size="large"
              type="text"
              value={last_name}
              placeholder="Doe"
              onChange={handleChange("last_name")}
            />
          </Col>
        </Col>

        <Col>
          <Col className="mb5">
            <label className="label">Email </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
              placeholder="abc@cockpit.com"
              value={email}
              onChange={handleChange("email")}
              size="large"
              allowClear
            />
            <Row className="error mt5">{formError?.email}</Row>
          </Col>
        </Col>
      </div>
    </CustomModal>
  );
};

export default InviteFreelancerModal;
