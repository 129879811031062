import React, { useEffect, useMemo, useState } from "react";
import { templateActions } from "./duck/templateReducer";
import { useDispatch, useSelector } from "react-redux";
import constants from "../utils/constants";

import { useNavigate } from "react-router-dom";
import { Button, Col, Input, Row, Table, Tag } from "antd";
import { EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { templateColumns } from "./components/TemplateColumn";
import routes from "../utils/routes";
import moment from "moment-timezone";
const { TEMPLATE_TYPES } = constants;

const Template = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const templateMap = useSelector(({ template }) => template.map);

  const templateList = useSelector(({ template }) => {
    const mFilter = JSON.stringify({});
    const boards = template.boards;
    const mBoard = boards[mFilter];

    return mBoard?.list;
  });

  const [state, setState] = useState({
    search: "",
    templateType: TEMPLATE_TYPES[0],
    selectedTemplateId: null,
  });
  useEffect(() => {
    if (!templateList) {
      dispatch(templateActions.onGetAllRequest());
    }
  }, [templateList, templateMap]);

  // return <div className="template-items-container" justify="space-between">
  //   {templateList?.map?.((item, i) => (
  //       <TemplateCard
  //           key={`${item}_${i}`}
  //           id={item}
  //           // onSelect={onTemplateDetails}
  //           isSelected={state.selectedTemplateId === item}
  //           // disabled={project?.loading}
  //       />))
  //   }
  //
  //     </div>;

  const data = useMemo(() => {
    let mData = [];
    if (templateList) {
      templateList?.forEach((id, index) => {
        let template = templateMap?.[id];
        const name = `${template?.name || ""}`;
        mData.push({
          key: id,
          sn: ++index,
          name,
          type: "Writing",
          date: moment(template?.created_at).format("DD-MMM-YYYY"),

          // role: mFreelancer?.role_id,
          status: template?.status ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          ),
          action: (
            <EditOutlined
              onClick={() =>
                navigate(routes.TEMPLATE_DETAIL.replace(":id", id))
              }
              style={{
                cursor: "pointer",
              }}
            />
          ),
        });
      });
    }

    return mData;
  }, [templateList, templateMap]);

  return (
    <Col className="content-body app-container project-draft-container">
      <Row className="page-header mb10" justify="space-between">
        <Row className="page-heading">
          Templates({data.length})
          {/*<span style={{ opacity: "0.6" }}> &nbsp;({meta?.totalCount})</span>*/}
        </Row>
      </Row>

      <Row justify="space-between" align="middle">
        <Row align="middle">
          <Col>
            <Input
              className="custom-input mr10"
              style={{ width: "300px", fontWeight: "500", marginRight: "15px" }}
              placeholder="Search Template Name ot Type"
              prefix={<SearchOutlined />}
              value={state?.search}
              // onChange={handleChange("search")}
            />
          </Col>
          <Button type="primary" className="mr10">
            Search
          </Button>
          <Button type="primary-outline">Reset</Button>
        </Row>

        <Button
          type="primary"
          // onClick={() => handleInviteUserModal(true)}
          size="large"
          shape="circle"
          style={{ borderRadius: "50% !important" }}
          icon={<PlusOutlined style={{ fontSize: "1.4rem" }} />}
        />
      </Row>

      {/*<Row className="page-body">*/}
      {/*  <Row className="table-body">*/}
      <Table
        style={{ width: "100%" }}
        columns={templateColumns()}
        dataSource={data}
        loading={false}
        pagination={false}
      />
      {/*</Row>*/}
      {/*</Row>*/}
    </Col>
  );
};

export default React.memo(Template);
