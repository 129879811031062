import { io } from "socket.io-client";
import AppUrl from "./appUrl";
let socket = undefined;

const initializeSocket = () => {
  socket = io(AppUrl.SOCKET_URL, {
    auth: {
      token: localStorage.getItem("accessToken"),
    },
    reconnectionAttempts: 5,
  });

  socket.on("connect", () => {
    console.log("Authenticated,", socket.id);
  });
  socket.on("connect_error", (err) => {
    console.log("Socket error", err);
    closeSocket();
  });
};

const closeSocket = () => {
  console.log("socket diconnected");
  socket.disconnect();
};

export { socket, initializeSocket, closeSocket };
