import constants from "../utils/constants";

const { PROJECT_TASK_STATUS } = constants;
const projectTaskListDetails = [
  {
    listName: "To-Do List",
    subHeading: "With Manager",
    taskStatus: PROJECT_TASK_STATUS.INITIATE,
  },
  {
    listName: "In Progress",
    subHeading: "With Writer / Freelancer",

    taskStatus: PROJECT_TASK_STATUS.IN_PROGRESS,
  },
  {
    listName: "In Review",
    subHeading: "With Editor",

    taskStatus: PROJECT_TASK_STATUS.IN_REVIEW,
  },
  {
    listName: "Completed",
    subHeading: "Now you Can Accept Your Task",
    taskStatus: PROJECT_TASK_STATUS.COMPLETED,
  },
];

export default projectTaskListDetails;
