import React, { useCallback, useMemo } from "react";
import { Col, Row, Input, Button, Upload } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import "./UploadListPreview.scss";
import Attachment from "../models/Attachment";

const UploadListPreview = (props) => {
  const { fileList, setFileList } = props;

  const { name, fileIcon, exactSize } = useMemo(() => {
    const file = fileList?.[0];
    const attachment = new Attachment();

    attachment.fromMap(
      Object.assign(file, { mimetype: file.type, filename: file.name })
    );

    const fileIcon = attachment.getFileTypeIcon();

    let [fileName, extension] = attachment.name.split(".");

    if (fileName.length > 10) {
      fileName = `${fileName.substring(0, 10)}..`;
    }
    const name = `${fileName}.${extension}`;

    let size = attachment.size;
    const fSExt = ["Bytes", "KB", "MB"];
    let i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    const exactSize = `${size ? Math.round(size * 100) / 100 : 0} ${fSExt[i]}`;

    return { fileIcon, name, exactSize };
  }, [fileList]);

  return (
    <Row style={{ backgroundColor: "#fff" }}>
      <Col className="upload-preview-container">
        {fileIcon}
        <Row className="info">
          <span>{name}</span>
          <span>{exactSize}</span>
        </Row>
        <DeleteOutlined className="delete" onClick={() => setFileList([])} />
      </Col>
    </Row>
  );
};

export default UploadListPreview;
