import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Skeleton } from "antd";
import "./ProjectListView.scss";
import { useDispatch, useSelector } from "react-redux";
import { paramsToString } from "../../utils/commonFunctions";
import { itemActions } from "../../item/duck/itemReducer";
import constants from "../../utils/constants";
import moment from "moment-timezone";
import EmptyContainer from "../../components/EmptyContainer";
import TaskListRow from "./TaskListRow";
import { useLocation } from "react-router-dom";

const { DEFAULT_PAGING, FILTER_TYPE } = constants;

const ProjectListView = (props) => {
  const { projectId, filter = {}, listBoardKey, setListBoardKey } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [boardKey, setBoardKey] = useState("");

  const item = useSelector(({ item }) => item);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = listBoardKey ? listBoardKey : boardKey;

    const boards = item.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [boardKey, item.boards, listBoardKey]);

  const loadMore = useCallback(
    (offset = DEFAULT_PAGING.offset, limit = DEFAULT_PAGING.limit) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const {
          filterType,
          user,
          start_date,
          end_date,
          task_name,
          status_description,
          content_type,
        } = filter;
        const params = {
          pathname,
          project_id: projectId,
          offset,
          limit,
        };
        if (task_name) params.task_name = task_name;

        if (status_description) {
          params.status_description = status_description;
        }
        if (content_type) {
          params.content_type = content_type;
        }

        if (filterType === FILTER_TYPE?.WRITER?._id && user)
          params.assigned_freelancer = user;
        if (filterType === FILTER_TYPE.CREATED_BY?._id && user)
          params.created_by = user;
        if (filterType === FILTER_TYPE.ASSIGNED_TO?._id && user)
          params.current_assignee = user;

        if (filterType === FILTER_TYPE.CREATED_DATE?._id) {
          if (start_date)
            params.start_date = moment(start_date)
              .startOf("day")
              .utc()
              .format();

          if (end_date)
            params.end_date = moment(end_date).endOf("day").utc().format();
        }
        setListBoardKey(null);
        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      filter,
      list?.length,
      meta?.totalCount,
      pathname,
      projectId,
      setListBoardKey,
    ]
  );

  useEffect(() => {
    loadMore();
  }, [filter]);

  const handleScroll = useCallback(
    ({ target }) => {
      if (
        Math.ceil(target.scrollTop) + window.innerHeight >=
        target.scrollHeight
      ) {
        if (!loading && list?.length < meta?.totalCount) {
          loadMore(list?.length);
        }
      }
    },
    [list?.length, loadMore, loading, meta?.totalCount]
  );

  return (
    <div className="list-view-container">
      <Row className="list-view-header fw">
        <Row className="header-content fw " align="middle" justify={"center"}>
          <Col className="name">Task Id</Col>
          <Col className="name">Title</Col>
          <Col className="name">Content Type</Col>
          <Col className="name">Created By</Col>
          <Col className="name">Writer</Col>
          <Col className="name">Current Assignee</Col>
          <Col className="name">Status</Col>
          <Col className="name">Request Date</Col>
          <Col className="name">Deadline</Col>
          <Col className="name">Word Count</Col>
          <Col className="name">Task Type</Col>
        </Row>
      </Row>

      {meta?.totalCount === 0 && !loading && <EmptyContainer />}
      <Row className="list-view-body fw" onScroll={handleScroll}>
        {list?.map((item, i) => (
          <TaskListRow key={i} id={item} />
        ))}
        {loading &&
          [...new Array(10)].map((_, i) => (
            <Row className="loaders" key={i}>
              {[...new Array(11)]?.map((_, i) => (
                <Col key={i}>
                  <Skeleton
                    active={loading}
                    title={false}
                    paragraph={{ rows: 1 }}
                    size="large"
                  />
                </Col>
              ))}
            </Row>
          ))}
      </Row>
    </div>
  );
};

export default ProjectListView;
