import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Input, Row, Select } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import { ReloadOutlined, LeftOutlined } from "@ant-design/icons";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import GenerateContentContainer from "./components/GenerateContentContainer";
import MRadioGroup from "../components/MRadioGroup";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import Loading from "../components/Loading";

import { useQuery } from "../utils/customHooks";
import { openAiActions } from "./duck/openAiReducer";

import appUrl from "../config/appUrl";
import {
  arrayToMap,
  hasApiCallError,
  showNotification,
} from "../utils/commonFunctions";
import constants from "../utils/constants";
import routes from "../utils/routes";

import "./OpenAi.scss";

const {
  CONTENT_VOICE_TONES,
  ENGLISH_VARIANT_SELECTION,
  BRAND,
  AUTO_GENERATE_CONTENT_TYPES,
  OPEN_AI_TASK_FIELDS,
} = constants;

const DEFAULT_STEP = 1;
const MAX_STEPS = 1;

const GenerateSocialBookmarking = () => {
  const prevProps = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const openAi = useSelector(({ openAi }) => openAi);
  const mTask = useMemo(() => {
    const taskId = query.get("task_id");
    return openAi.map[taskId] || {};
  }, [query, openAi.map]);

  const [state, setState] = useState({
    currentStep: DEFAULT_STEP,
    tone: CONTENT_VOICE_TONES[3],
    language: ENGLISH_VARIANT_SELECTION[1],

    words: 30,
  });
  const [error, setError] = useState({});
  const [responses, setResponses] = useState({});
  const [responseDescriptions, setResponseDescriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const hasError = useCallback(() => {
    let { currentStep, metaTagDescription, tone, language, brand, keywords } =
      state;
    metaTagDescription = metaTagDescription?.trim?.();
    tone = tone?.trim?.();
    language = language?.trim?.();
    brand = brand?.trim?.();
    keywords = keywords?.trim?.();

    const error = {};

    if (currentStep === 1) {
      if (!metaTagDescription) {
        error.metaTagDescription =
          "Please enter description your product or service";
      }

      if (!tone) {
        error.tone = "Please select Tone of the content";
      }
      if (!language) {
        error.tone = "Please select Language of the content";
      }
      if (!brand) {
        error.tone = "Please select Brand of the content";
      }
      if (!keywords) {
        error.keywords = "Please enter some primary keywords";
      }
    }

    setError(error);
    return Object.keys(error).length > 0;
  }, [state]);

  const generateDescriptionApi = useCallback(
    (temperature) => {
      if (temperature || !hasError()) {
        setLoading(true);

        let {
          currentStep,
          metaTagDescription,
          keywords,
          tone,
          language,
          brand,
          words,
        } = state;
        metaTagDescription = metaTagDescription?.trim?.();
        keywords = keywords?.trim?.();
        keywords = keywords?.trim?.();
        tone = tone?.trim?.();
        language = language?.trim?.();
        brand = brand?.trim?.();
        words = words || 30;

        // let text = `write 5 social Bookmarking description using ${metaTagDescription}, keywords ${keywords}, tone ${tone}, brand${brand} language ${language} in ${words} words.`;
        let text = `Write two exact 30 words Social Bookmarking description for the ${metaTagDescription}. Include keywords  ${keywords}  within the description and title of the social bookmarking. Make the description  ${brand} brand-oriented. Keep the language  language ${language} ,tone ${tone}. Add in the description.`;

        const payload = { text };
        if (temperature) payload.temperature = 0.5;
        if (mTask?._id) payload.task_id = mTask?._id;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              const choiceText = resData?.data?.content;
              let descriptions = choiceText?.split?.("\n") || [];
              console.log("descriptions", descriptions);
              if (Array.isArray(descriptions)) {
                descriptions = descriptions.filter((description) =>
                  description?.trim?.()
                );

                descriptions = descriptions.filter(
                  (description) => !description.includes("CTA:")
                );

                descriptions = descriptions.map((description, i) => {
                  const [title = "", rest = ""] = description.split(/:(.+)/);

                  const trimmedRest =
                    rest
                      ?.replace(/"/g, "")
                      ?.replace(new RegExp(`^${i + 1}\\.`), "")
                      ?.trim() || rest;

                  return title.trim() + ":" + " " + trimmedRest;
                });

                descriptions = descriptions.filter((description) =>
                  description?.trim?.()
                );

                setResponseDescriptions(descriptions);
              }

              setResponses((prevResponses) =>
                Object.assign({}, prevResponses, { [currentStep]: resData })
              );
              setState((prevState) => ({
                ...prevState,
                // finalDraft: choiceText,
                finalDraft: temperature ? prevState.finalDraft : "",
                currentStep: temperature
                  ? prevState.currentStep
                  : prevState.currentStep + 1,
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            setLoading(false);
          });
      }
    },
    [state, mTask, hasError]
  );

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      if (name === "words" && value) {
        value = value.replace(/[^0-9]/g, "");
        value = Number(value);
      }

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const onGoBack = useCallback(() => {
    if (state.currentStep <= 1) {
      navigate(-1);
      return;
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  }, [state.currentStep, navigate]);

  const onSubmit = useCallback(() => {
    generateDescriptionApi();
  }, [generateDescriptionApi]);

  const onSelect = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;
      let finalDraft = state.finalDraft || "";

      if (!finalDraft.replace(/<(.|\n)*?>/g, "").trim()) finalDraft = "";
      if (finalDraft) finalDraft = `${finalDraft}<br>`;
      finalDraft = `${finalDraft}<p>${value}</p>`;

      setState((prevState) => ({ ...prevState, finalDraft }));
      setError({});
    },
    [state.finalDraft]
  );

  const onRegenerate = useCallback(() => {
    const temperature = Number(Math.random().toFixed(2));

    if (state.currentStep === 2) {
      generateDescriptionApi(temperature);
    }
  }, [state.currentStep, generateDescriptionApi]);

  useEffect(() => {
    if (
      prevProps.current?.loading &&
      !openAi?.loading &&
      state.isTaskCreating
    ) {
      const searchParams = {
        task_id: openAi?.newCreatedDataId,
      };

      const navigateTo = {
        search: `?${createSearchParams(searchParams)}`,
      };
      navigate(navigateTo, { replace: true });
      setState((prevState) => ({ ...prevState, isTaskCreating: false }));
    } else if (
      prevProps.current?.loading &&
      !mTask?.loading &&
      state.isTaskSaving
    ) {
      if (!mTask?.error) {
        const navigateTo = routes.OPEN_AI_DASHBOARD;
        navigate(navigateTo, { replace: true });
      }
    }

    return () => {
      prevProps.current = {
        loading: openAi?.loading || mTask?.loading,
        isTaskCreating: state.isTaskCreating,
        isTaskSaving: state.isTaskSaving,
      };
    };
  }, [
    openAi?.newCreatedDataId,
    openAi?.loading,
    state?.isTaskCreating,
    state?.isTaskSaving,
    mTask?.loading,
    mTask?.error,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    const taskId = query.get("task_id");
    if (!taskId) {
      console.log("create task ");
      setState((prevState) => ({ ...prevState, isTaskCreating: true }));
      const payload = {
        fields: [...OPEN_AI_TASK_FIELDS],
      };
      payload.fields.forEach((field) => {
        if (field._id === "content_type") {
          field.value = AUTO_GENERATE_CONTENT_TYPES.SOCIAL_BOOKMARKING.name;
        }
      });
      dispatch(openAiActions.onCreateOneRequest(payload));
    } else {
      dispatch(openAiActions.onGetOneRequest({ _id: taskId }));
    }
  }, [query, dispatch]);

  const handleShowOpenAiTaskModal = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
    }));
  }, []);

  const onSave = useCallback(
    (fields = []) => {
      const { _id } = mTask;

      const payload = {
        _id: _id,
        name: "",
        fields: [],
        draft: false,
      };

      const fieldMap = Object.assign(
        {},
        arrayToMap(mTask?.fields),
        arrayToMap(fields)
      );
      payload.fields = mTask?.fields?.map((field) => {
        const newField = Object.assign({}, field, fieldMap[field?._id]);
        if (newField?._id === "content_type") {
          newField.value = AUTO_GENERATE_CONTENT_TYPES.SOCIAL_BOOKMARKING.name;
        } else if (newField?._id === "task_name") {
          payload.name = newField.value;
        } else if (newField?._id === "content") {
          newField.value = state.finalDraft;
        }

        return newField;
      });

      setState((prevState) => ({
        ...prevState,
        showOpenAiTaskModal: false,
        isTaskSaving: true,
      }));
      dispatch(openAiActions.onUpdateOneRequest(payload));
    },
    [mTask, state, dispatch]
  );

  if (
    (!openAi.newCreatedDataId && openAi.loading) ||
    state.isTaskCreating ||
    (!mTask?._id && mTask?.loading)
  ) {
    return <Loading />;
  }

  return (
    <GenerateContentContainer containerClassName="set-container">
      <Row className="content-container">
        {state.currentStep === 1 && (
          <Row className="fw" justify="space-between">
            <Col className="--left-container" span={24}>
              <Col className="title-container mb-4">
                <Row className="d-flex-open-ai" align="middle">
                  <div className="back-icon mr-3">
                    <LeftOutlined className="text-2xl" onClick={onGoBack} />
                  </div>

                  <span>Generate your social bookmarking</span>

                  {/* <Button
                    type="primary-outline"
                    size="large"
                    onClick={onGoBack}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </Button> */}
                </Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">
                    Add your primary or secondary keywords
                  </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Input
                  className="input input-area"
                  placeholder="Add your primary or secoundry keywords here"
                  value={state.keywords}
                  onChange={handleChange("keywords")}
                  size="large"
                  disabled={loading}
                />

                <Row className="error mt5">{error.keywords}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">
                    Describe your product or service
                  </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Input.TextArea
                  className="input input-area"
                  placeholder="Let us know your topic, brand here"
                  value={state.metaTagDescription}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={handleChange("metaTagDescription")}
                  size="large"
                  disabled={loading}
                />

                <Row className="error mt5">{error.metaTagDescription}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">Select tone of the content</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Select
                  className="custom-select fw"
                  placeholder="Select tone of the content"
                  value={state.tone}
                  onChange={handleChange("tone")}
                  size="large"
                  disabled={loading}
                >
                  {CONTENT_VOICE_TONES.map((tone, i) => (
                    <Select.Option key={i} title={tone} value={tone} />
                  ))}
                </Select>

                <Row className="error mt5">{error.tone}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">English Variant Selection</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Select
                  className="custom-select fw"
                  placeholder="English variant selection"
                  value={state.language}
                  onChange={handleChange("language")}
                  size="large"
                  disabled={loading}
                >
                  {ENGLISH_VARIANT_SELECTION.map((language, i) => (
                    <Select.Option key={i} title={language} value={language} />
                  ))}
                </Select>

                <Row className="error mt5">{error.language}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">Brand</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Select
                  className="custom-select fw"
                  placeholder="Choose brand"
                  value={state.brand}
                  onChange={handleChange("brand")}
                  size="large"
                  disabled={loading}
                >
                  {BRAND.map((brand, i) => (
                    <Select.Option key={i} title={brand} value={brand} />
                  ))}
                </Select>

                <Row className="error mt5">{error.brand}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">Number of words</label>
                </Col>

                <Input
                  className="input input-area"
                  placeholder="30"
                  value={state.words}
                  onChange={handleChange("words")}
                  size="large"
                  disabled={loading}
                />
              </Col>

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw mt15"
                    type="primary"
                    loading={loading}
                    onClick={onSubmit}
                    size="large"
                  >
                    Create Content
                  </Button>
                </Col>
              </Row>
            </Col>

            {/* <Col
            className="--right-container mb-24"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={11}
            xxl={11}
          >
            <ReactQuill
              className="input-text-editor h-96 sticky top-0"
              value={state.finalDraft}
              onChange={handleChange("finalDraft")}
              theme="snow"
            />
          </Col> */}
          </Row>
        )}

        {state.currentStep === 2 && (
          <Row className="fw" justify="space-between">
            <Col
              className="--left-container mb-24"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={11}
              xxl={11}
            >
              {/* meta descriptions start */}
              <Col className="mb-24">
                <Col className="title-container mb-4">
                  <Row className="d-flex-open-ai" align="middle">
                    <div className="back-icon mr-3">
                      <LeftOutlined className="text-2xl" onClick={onGoBack} />
                    </div>

                    <span>Choose your social bookmarking</span>

                    {/* <Button
                      type="primary-outline"
                      size="large"
                      onClick={onGoBack}
                    >
                      <ArrowLeftOutlined /> Go Back
                    </Button> */}
                  </Row>
                </Col>

                <Col className="mb15">
                  <MRadioGroup
                    values={responseDescriptions}
                    value={state.description}
                    onChange={onSelect("description")}
                  />

                  <Row className="error mt5">{error.title}</Row>
                </Col>

                <Col className="mt15 ">
                  <Button
                    className="fw"
                    type="primary"
                    disabled={loading}
                    onClick={() => generateDescriptionApi(0.5)}
                    size="large"
                  >
                    <Row align="middle" justify="center">
                      <ReloadOutlined className="mr-2.5" spin={loading} />
                      Regenerate
                    </Row>
                  </Button>
                </Col>
              </Col>
              {/* meta descriptions end */}
            </Col>

            <Col
              className="--right-container"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={11}
              xxl={11}
            >
              <Col className="sticky top-0">
                <Col className="h-96 mb-16">
                  <ReactQuill
                    className="input-text-editor h-full"
                    value={state.finalDraft}
                    onChange={handleChange("finalDraft")}
                    theme="snow"
                  />
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Row
                    className="w-full"
                    align="middle"
                    justify="space-between"
                  >
                    {mTask?._id && (
                      <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                        <Button
                          className="w-full"
                          type="success"
                          size="large"
                          loading={mTask?.loading}
                          onClick={() => handleShowOpenAiTaskModal(true)}
                        >
                          {mTask?.loading ? "Saving" : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Col>
            </Col>
          </Row>
        )}
      </Row>

      <OpenAiTaskModal
        visible={state.showOpenAiTaskModal}
        taskId={mTask?._id}
        fields={mTask?.fields?.filter((field) => field?._id === "task_name")}
        onSubmit={onSave}
        handleModal={handleShowOpenAiTaskModal}
      />
    </GenerateContentContainer>
  );
};

export default GenerateSocialBookmarking;
