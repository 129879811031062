import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Divider,
  Upload,
  Progress,
} from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import {
  DownloadOutlined,
  UploadOutlined,
  ReloadOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import GenerateContentContainer from "./components/GenerateContentContainer";
import MRadioGroup from "../components/MRadioGroup";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import Loading from "../components/Loading";

import { openAiActions } from "./duck/openAiReducer";
import { useQuery } from "../utils/customHooks";

import appUrl from "../config/appUrl";
import {
  arrayToMap,
  downloadFile,
  hasApiCallError,
  showNotification,
} from "../utils/commonFunctions";
import routes from "../utils/routes";
import constants from "../utils/constants";

import "./OpenAi.scss";

const {
  CONTENT_VOICE_TONES,
  AUTO_GENERATE_CONTENT_TYPES,
  OPEN_AI_TASK_FIELDS,
} = constants;

const DEFAULT_STEP = 1;
const MAX_STEPS = 3;

const GenerateMetaTag = () => {
  const prevProps = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const openAi = useSelector(({ openAi }) => openAi);
  const mTask = useMemo(() => {
    const taskId = query.get("task_id");
    return openAi.map[taskId] || {};
  }, [query, openAi.map]);

  const [state, setState] = useState({
    currentStep: DEFAULT_STEP,
    tone: CONTENT_VOICE_TONES[3],
  });
  const [error, setError] = useState({});
  const [responseTitles, setResponseTitles] = useState([]);
  const [responseDescriptions, setResponseDescriptions] = useState([]);
  const [responses, setResponses] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      prevProps.current?.loading &&
      !openAi?.loading &&
      state.isTaskCreating
    ) {
      const searchParams = {
        task_id: openAi?.newCreatedDataId,
      };

      const navigateTo = {
        search: `?${createSearchParams(searchParams)}`,
      };
      navigate(navigateTo, { replace: true });
      setState((prevState) => ({ ...prevState, isTaskCreating: false }));
    } else if (
      prevProps.current?.loading &&
      !mTask?.loading &&
      state.isTaskSaving
    ) {
      if (!mTask?.error) {
        const navigateTo = routes.OPEN_AI_DASHBOARD;
        navigate(navigateTo, { replace: true });
      }
    }

    return () => {
      prevProps.current = {
        loading: openAi?.loading || mTask?.loading,
        isTaskCreating: state.isTaskCreating,
        isTaskSaving: state.isTaskSaving,
      };
    };
  }, [
    openAi?.newCreatedDataId,
    openAi?.loading,
    state?.isTaskCreating,
    state?.isTaskSaving,
    mTask?.loading,
    mTask?.error,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    const taskId = query.get("task_id");
    if (!taskId) {
      setState((prevState) => ({ ...prevState, isTaskCreating: true }));
      const payload = {
        fields: [...OPEN_AI_TASK_FIELDS],
      };
      payload.fields.forEach((field) => {
        if (field._id === "content_type") {
          field.value = AUTO_GENERATE_CONTENT_TYPES.SEO_META_TAG.name;
        }
      });
      dispatch(openAiActions.onCreateOneRequest(payload));
    } else {
      dispatch(openAiActions.onGetOneRequest({ _id: taskId }));
    }
  }, [query, dispatch]);

  useEffect(() => {
    let timmer;
    if (mTask?._id && mTask?.ai_status_description === "running") {
      timmer = setTimeout(() => {
        dispatch(openAiActions.onGetOneRequest({ _id: mTask?._id }));
      }, 1000);
      console.log("uplodate task in 500ms", timmer, mTask?.progress);
    }

    return () => {
      clearTimeout(timmer);
    };
  }, [dispatch, mTask]);

  useEffect(() => {
    if (loading) {
      const { isTitleLoading, isDescriptionLoading } = state;

      if (!isTitleLoading && !isDescriptionLoading) {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep + 1,
        }));
        setLoading(false);
      }
    }
  }, [state, loading]);

  const hasError = useCallback(() => {
    let { currentStep, metaTagDescription, tone, keywords } = state;
    metaTagDescription = metaTagDescription?.trim?.();
    tone = tone?.trim?.();
    keywords = keywords?.trim?.();

    const error = {};

    if (currentStep === 1) {
      if (!metaTagDescription) {
        error.metaTagDescription =
          "Please enter description your product or service";
      }

      if (!tone) {
        error.tone = "Please select Tone of the content";
      }

      if (!keywords) {
        error.keywords = "Please enter some primary keywords";
      }
    }

    setError(error);
    return Object.keys(error).length > 0;
  }, [state]);

  const generateTitlesApi = useCallback(
    (temperature) => {
      if (temperature || !hasError()) {
        // setLoading(true);
        setState((prevState) => ({
          ...prevState,
          isTitleLoading: true,
        }));

        let { metaTagDescription, tone, keywords } = state;
        metaTagDescription = metaTagDescription?.trim?.();
        tone = tone?.trim?.();
        keywords = keywords?.trim?.();

        let text = `Generate 5 SEO meta title on ${metaTagDescription} using tone ${tone} and keywords ${keywords} in 60 characters. Don't use the word professional again.`;
        const payload = { text };
        if (mTask?._id) payload.task_id = mTask?._id;
        if (temperature) payload.temperature = 0.5;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              setResponses((prevResponses) => ({
                ...prevResponses,
                [JSON.stringify(payload)]: resData,
              }));

              const choiceText = resData?.data?.content;
              let titles = choiceText?.split?.("\n") || [];
              if (Array.isArray(titles)) {
                titles = titles.filter((title) => title?.trim?.());
                titles = titles.map((title) => {
                  title =
                    title
                      ?.match?.(/(?:"[^"]*"|^[^"]*$)/)?.[0]
                      ?.replace?.(/"/g, "") || title;

                  return title?.trim?.();
                });
                titles = titles.filter((title) => title?.trim?.());

                setResponseTitles(titles);
              }
            }
            setState((prevState) => ({
              ...prevState,
              title: "",
              isTitleLoading: false,
            }));
            // setLoading(false);
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            // setLoading(false);
            setState((prevState) => ({
              ...prevState,
              isTitleLoading: false,
            }));
          });
      }
    },
    [state, hasError, mTask?._id]
  );

  const generateDescriptionsApi = useCallback(
    (temperature) => {
      if (temperature || !hasError()) {
        // setLoading(true);
        setState((prevState) => ({
          ...prevState,
          isDescriptionLoading: true,
        }));

        let { metaTagDescription, tone, keywords } = state;
        metaTagDescription = metaTagDescription?.trim?.();
        tone = tone?.trim?.();
        keywords = keywords?.trim?.();

        let text = `Generate 5 SEO meta description on ${metaTagDescription} using tone ${tone} and keywords ${keywords} in 200 characters.`;
        const payload = { text };
        if (mTask?._id) payload.task_id = mTask?._id;
        if (temperature) payload.temperature = 0.5;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              setResponses((prevResponses) => ({
                ...prevResponses,
                [JSON.stringify(payload)]: resData,
              }));

              const choiceText = resData?.data?.content;
              let descriptions = choiceText?.split?.("\n") || [];
              if (Array.isArray(descriptions)) {
                descriptions = descriptions.filter((description) =>
                  description?.trim?.()
                );
                descriptions = descriptions.map((description, i) => {
                  description =
                    description
                      ?.match?.(/(?:"[^"]*"|^[^"]*$)/)?.[0]
                      ?.replace?.(/"/g, "") || description;

                  if (description.startsWith(`${i + 1}.`)) {
                    description = description?.replace(`${i + 1}.`, "");
                  }

                  return description?.trim?.();
                });

                descriptions = descriptions.filter((description) =>
                  description?.trim?.()
                );
                setResponseDescriptions(descriptions);
              }
            }
            setState((prevState) => ({
              ...prevState,
              description: "",
              isDescriptionLoading: false,
            }));
            // setLoading(false);
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            // setLoading(false);
            setState((prevState) => ({
              ...prevState,
              isDescriptionLoading: false,
            }));
          });
      }
    },
    [state, hasError, mTask?._id]
  );

  const onGoBack = useCallback(() => {
    if (state.currentStep <= 1) {
      navigate(-1);
      return;
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  }, [state.currentStep, navigate]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;

        if (name === "title" || name === "description") {
          let finalDraft = "";

          if (prevState.title) {
            if (finalDraft) finalDraft = `${finalDraft}<br>`;
            finalDraft = `${finalDraft}<h3><strong>Title</strong></h3>`;
            finalDraft = `${finalDraft}<p>${prevState.title}</p>`;
          }

          if (prevState.description) {
            if (finalDraft) finalDraft = `${finalDraft}<br>`;
            finalDraft = `${finalDraft}<h3><strong>Description</strong></h3>`;
            finalDraft = `${finalDraft}<p>${prevState.description}</p>`;
          }

          prevState.finalDraft = finalDraft;
        }

        return { ...prevState };
      });
      setError({});
    },
    []
  );

  const onSelect = useCallback(
    (name) => (e) => {
      if (name === "title" || name === "description") {
        let value = e?.target?.value ?? e;
        let finalDraft = state.finalDraft || "";

        if (!finalDraft.replace(/<(.|\n)*?>/g, "").trim()) finalDraft = "";
        if (finalDraft) finalDraft = `${finalDraft}<br>`;

        if (name === "title") {
          finalDraft = `${finalDraft}<h3><strong>Title</strong></h3>`;
          finalDraft = `${finalDraft}<p>${value}</p>`;
        }

        if (name === "description") {
          finalDraft = `${finalDraft}<h3><strong>Description</strong></h3>`;
          finalDraft = `${finalDraft}<p>${value}</p>`;
        }

        setState((prevState) => ({ ...prevState, finalDraft }));
        setError({});
      }
    },
    [state.finalDraft]
  );

  const onSubmit = useCallback(() => {
    if (state.currentStep === 1 && !hasError()) {
      setState((prevState) => ({ ...prevState, finalDraft: "" }));
      setLoading(true);
      generateTitlesApi();
      generateDescriptionsApi();
    }
  }, [state.currentStep, generateTitlesApi, generateDescriptionsApi, hasError]);

  const handleShowOpenAiTaskModal = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
    }));
  }, []);

  const onSave = useCallback(
    (fields = []) => {
      const { _id } = mTask;

      const payload = {
        _id: _id,
        fields: [],
        draft: false,
      };

      const fieldMap = Object.assign(
        {},
        arrayToMap(mTask?.fields),
        arrayToMap(fields)
      );
      payload.fields = mTask?.fields?.map((field) => {
        const newField = Object.assign({}, field, fieldMap[field?._id]);
        if (newField?._id === "content_type") {
          newField.value = AUTO_GENERATE_CONTENT_TYPES.SEO_META_TAG.name;
        } else if (newField?._id === "content") {
          newField.value = state.finalDraft;
        }

        return newField;
      });

      setState((prevState) => ({
        ...prevState,
        showOpenAiTaskModal: false,
        isTaskSaving: true,
      }));
      dispatch(openAiActions.onUpdateOneRequest(payload));
    },
    [mTask, state, dispatch]
  );

  const onBeforeUpload = useCallback((file) => {
    const supportedFileType = [
      "application/vnd.ms-excel",
      "application/msexcel",
      "application/x-msexcel",
      "application/x-ms-excel",
      "application/x-excel",
      "application/x-dos_ms_excel",
      "application/xls",
      "application/x-xls",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload Excel files only");
    }

    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const onUpdateFile = useCallback(
    ({ file }) => {
      setState((prevState) => ({ ...prevState, isBulkUploading: true }));

      const formData = new FormData();
      formData.append("file", file);
      if (mTask?._id) formData.append("task_id", mTask._id);

      axios({
        method: "post",
        url: appUrl.OPENAI_BULK_UPLOAD,
        data: formData,
      })
        .then((res) => {
          const resData = res.data;
          console.log("resData?.data", resData?.data);

          if (
            (resData?.meta?.status !== 200 || resData?.meta?.status !== 201) &&
            resData?.meta?.success !== true
          ) {
            showNotification(
              "error",
              resData?.meta?.message || "Something went wrong while Uploading "
            );
          } else {
            dispatch(openAiActions.onGetOneSuccess(resData?.data));
          }

          setState((prevState) => ({ ...prevState, isBulkUploading: false }));
        })
        .catch((error) => {
          console.log("bulk upload error ", error);
          showNotification("error", "Something went wrong");
          setState((prevState) => ({ ...prevState, isBulkUploading: false }));
        });
    },
    [dispatch, mTask]
  );

  const onDownloadFile = useCallback(() => {
    const fileName = mTask?.name || "Bulk meta tags";
    downloadFile(mTask?.attachment?.url, fileName);
  }, [mTask]);

  if (
    (!openAi.newCreatedDataId && openAi.loading) ||
    state.isTaskCreating ||
    (!mTask?._id && mTask?.loading)
  ) {
    return <Loading />;
  }

  return (
    <>
      <GenerateContentContainer containerClassName="set-container">
        <Row className="content-container">
          {state.currentStep === 1 && (
            <Row className="fw" justify="space-between">
              <Col
                className="--left-container"
                xs={24}
                sm={24}
                md={24}
                lg={11}
                xl={11}
                xxl={11}
              >
                <Col className="title-container mb15">
                  <Row className="d-flex-open-ai" align="middle">
                    <div className="back-icon mr-3">
                      <LeftOutlined className="text-2xl" onClick={onGoBack} />
                    </div>
                    <span>Generate your meta tag</span>

                    {/* <Button
                      type="primary-outline"
                      size="large"
                      onClick={onGoBack}
                    >
                      <ArrowLeftOutlined /> Go Back
                    </Button> */}
                  </Row>
                </Col>

                <Col className="mb15">
                  <Col className="mb5">
                    <label className="label">
                      Add your primary or secondary keywords
                    </label>
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>

                  <Input
                    className="input input-area"
                    placeholder="Primary / Secondary Keywords"
                    value={state.keywords}
                    onChange={handleChange("keywords")}
                    size="large"
                    disabled={loading}
                  />

                  <Row className="error mt5">{error.keywords}</Row>
                </Col>

                <Col className="mb15">
                  <Col className="mb5">
                    <label className="label">
                      Describe your product or service
                    </label>
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>

                  <Input.TextArea
                    className="input input-area"
                    placeholder="Let us know your topic, brand here"
                    value={state.metaTagDescription}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={handleChange("metaTagDescription")}
                    size="large"
                    disabled={loading}
                  />

                  <Row className="error mt5">{error.metaTagDescription}</Row>
                </Col>

                <Col className="mb15">
                  <Col className="mb5">
                    <label className="label">Select tone of the content</label>
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>

                  <Select
                    className="custom-select fw"
                    placeholder="Select tone of the content"
                    value={state.tone}
                    onChange={handleChange("tone")}
                    size="large"
                    disabled={loading}
                  >
                    {CONTENT_VOICE_TONES.map((tone, i) => (
                      <Select.Option key={i} title={tone} value={tone} />
                    ))}
                  </Select>

                  <Row className="error mt5">{error.tone}</Row>
                </Col>

                <Col className="mt20">
                  <Button
                    className="fw"
                    type="primary"
                    loading={loading}
                    onClick={onSubmit}
                    size="large"
                  >
                    Create Content
                  </Button>
                </Col>
              </Col>

              <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} align="center">
                <Divider type="vertical" />
              </Col>

              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} align="center">
                <Divider />
              </Col>

              <Col
                className="--right-container"
                xs={24}
                sm={24}
                md={24}
                lg={11}
                xl={11}
                xxl={11}
              >
                <Row className="h-full" align="middle" justify="center">
                  <Col>
                    <Col className="mb-5">
                      <Row className="fw mb-2" justify="center">
                        <Upload
                          className="ml15"
                          accept={".xlsx, .xls,.xlsx"}
                          listType=""
                          fileList={fileList}
                          beforeUpload={onBeforeUpload}
                          customRequest={onUpdateFile}
                          disabled={state.isBulkUploading}
                        >
                          <Button
                            type="primary"
                            size="large"
                            icon={<UploadOutlined className="f20" />}
                            disabled={
                              state.isBulkUploading ||
                              mTask?.ai_status_description === "running"
                            }
                            loading={
                              state.isBulkUploading ||
                              mTask?.ai_status_description === "running"
                            }
                          >
                            Upload File
                          </Button>
                        </Upload>
                      </Row>

                      {mTask?.ai_status_description === "running" && (
                        <Row className="fw">
                          <Progress percent={mTask?.progress} />
                        </Row>
                      )}
                    </Col>

                    <Row className="fw text-sm text-center" justify="center">
                      Use Our Bulk Generator to Automate Meta Tag Creation:
                      Quick and simple
                    </Row>

                    <Row className="fw" justify="center">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={11}
                        xl={11}
                        xxl={11}
                        align="middle"
                      >
                        <Divider dashed />
                      </Col>
                    </Row>

                    <Row className="fw mb-5" justify="center">
                      <Button
                        type="primary"
                        icon={<DownloadOutlined className="f20" />}
                        size="large"
                        onClick={onDownloadFile}
                        disabled={
                          mTask?.ai_status_description === "running" ||
                          !mTask?.attachment
                        }
                      >
                        Download File
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {state.currentStep === 2 && (
            <Col span={24}>
              <Row className="fw" justify="space-between">
                <Col
                  className="--left-container"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={11}
                  xxl={11}
                >
                  {/* meta titles start */}
                  <Col className="mb-24">
                    <Col className="title-container mb-4">
                      <Row className="d-flex-open-ai" align="middle">
                        <div className="back-icon mr-3">
                          <LeftOutlined
                            className="text-2xl mr-3"
                            onClick={onGoBack}
                          />
                        </div>
                        <span>Choose your meta title</span>

                        {/* <Button
                          type="primary-outline"
                          size="large"
                          onClick={onGoBack}
                        >
                          <ArrowLeftOutlined /> Go Back
                        </Button> */}
                      </Row>
                    </Col>

                    <Col className="mb15">
                      <MRadioGroup
                        values={responseTitles}
                        value={state.title}
                        onChange={onSelect("title")}
                      />

                      <Row className="error mt5">{error.title}</Row>
                    </Col>

                    <Col className="mt15">
                      <Button
                        className="fw"
                        type="primary"
                        disabled={state.isTitleLoading}
                        onClick={() => generateTitlesApi(0.5)}
                        size="large"
                      >
                        <Row align="middle" justify="center">
                          <ReloadOutlined
                            className="mr-2.5"
                            spin={state.isTitleLoading}
                          />
                          Regenerate
                        </Row>
                      </Button>
                    </Col>
                  </Col>
                  {/* meta titles end */}

                  {/* meta descriptions satrt */}
                  <Col className="mb-24">
                    <Col className="title-container mb-4">
                      <Col className="title">Choose your meta description</Col>
                    </Col>

                    <Col className="mb15">
                      <MRadioGroup
                        values={responseDescriptions}
                        value={state.description}
                        onChange={onSelect("description")}
                      />

                      <Row className="error mt5">{error.title}</Row>
                    </Col>

                    <Col className="mt15">
                      <Button
                        className="fw"
                        type="primary"
                        disabled={state.isDescriptionLoading}
                        onClick={() => generateDescriptionsApi(0.5)}
                        size="large"
                      >
                        <Row align="middle" justify="center">
                          <ReloadOutlined
                            className="mr-2.5"
                            spin={state.isDescriptionLoading}
                          />
                          Regenerate
                        </Row>
                      </Button>
                    </Col>
                  </Col>
                  {/* meta descriptions end */}
                </Col>

                <Col
                  className="--right-container mb-24"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={11}
                  xxl={11}
                >
                  <Col className="sticky top-0">
                    <Col className="h-96 mb-16">
                      <ReactQuill
                        className="input-text-editor h-full"
                        value={state.finalDraft}
                        onChange={handleChange("finalDraft")}
                        theme="snow"
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Row
                        className="w-full"
                        align="middle"
                        justify="space-between"
                      >
                        {mTask?._id && (
                          <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                            <Button
                              className="w-full"
                              type="success"
                              size="large"
                              loading={mTask?.loading}
                              onClick={() => handleShowOpenAiTaskModal(true)}
                            >
                              {mTask?.loading ? "Saving" : "Save"}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </GenerateContentContainer>

      <OpenAiTaskModal
        visible={state.showOpenAiTaskModal}
        taskId={mTask?._id}
        fields={mTask?.fields?.filter((field) => field?._id === "task_name")}
        onSubmit={onSave}
        handleModal={handleShowOpenAiTaskModal}
      />
    </>
  );
};

export default GenerateMetaTag;
