import { call, put, fork, takeLatest } from "redux-saga/effects";
import MSaga from "../../helper/MSaga";
import { taskActions } from "./taskReducer";
import appUrl from "../../config/appUrl";
import axios from "axios";
import { showNotification } from "../../utils/commonFunctions";

// assign task start
const assignTaskApi = (data = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.TASKS}/assign/${data?._id}`,
    data: data,
  });
};

function* handleAssignTask(action) {
  try {
    const response = yield call(assignTaskApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    let message = meta?.message;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", message);
      yield put(taskActions.onUpdateOneFailure(data));
    } else {
      showNotification("success", message);
      data.data._id = action.payload?._id;
      yield put(taskActions.onUpdateOneSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("MSaga Update One", error);

    error._id = action.payload?._id;
    yield put(taskActions.onUpdateOneFailure(error));
  }
}
// assign task end

// submit task start
const submitTaskApi = (data = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.TASKS}/submit/${data?._id}`,
    data: data,
  });
};

function* handleSubmitTask(action) {
  try {
    const response = yield call(submitTaskApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    let message = meta?.message;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", message);
      yield put(taskActions.onUpdateOneFailure(data));
    } else {
      showNotification("success", message);
      data.data._id = action.payload?._id;
      yield put(taskActions.onUpdateOneSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("MSaga Update One", error);

    error._id = action.payload?._id;
    yield put(taskActions.onUpdateOneFailure(error));
  }
}
// submit task end

// feedback task start
const feedbackTaskApi = (data = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.TASKS}/feedback/${data?._id}`,
    data: data,
  });
};

function* handleFeedbackTask(action) {
  try {
    const response = yield call(feedbackTaskApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    let message = meta?.message;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", message);
      yield put(taskActions.onUpdateOneFailure(data));
    } else {
      showNotification("success", message);
      data.data._id = action.payload?._id;
      yield put(taskActions.onUpdateOneSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("MSaga Update One", error);

    error._id = action.payload?._id;
    yield put(taskActions.onUpdateOneFailure(error));
  }
}
// feedback task end

const taskSaga = new MSaga({
  name: "task",
  apiEndPoint: appUrl.TASKS,
  actions: taskActions,
});

function* watchTaskSaga() {
  yield fork(taskSaga.getWatchSaga());
  yield takeLatest(taskActions.onAssignRequest.type, handleAssignTask);
  yield takeLatest(taskActions.onSubmitRequest.type, handleSubmitTask);
  yield takeLatest(taskActions.onFeedbackRequest.type, handleFeedbackTask);
}

export default watchTaskSaga;
