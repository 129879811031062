import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CustomModal from "../../components/CustomModal";
import InputField from "../../components/InputField";
import FileItem from "../../task/components/FileItem";

import { openAiActions } from "../duck/openAiReducer";

const OpenAiTaskModal = (props) => {
  const { visible, taskId } = props;

  const prevProps = useRef(null);
  const dispatch = useDispatch();

  const taskObj = useSelector(({ openAi }) => openAi.map[taskId] || {});
  const fields = useMemo(
    () => props.fields || taskObj?.fields || [],
    [props.fields, taskObj?.fields]
  );
  const attachment = useMemo(() => taskObj?.attachment, [taskObj?.attachment]);

  const [state, setState] = useState({});
  const [error, setError] = useState({});
  useEffect(() => {
    if (visible) {
      if (prevProps.current?.loading && !taskObj?.loading) {
        if (!taskObj?.error) {
          props.handleModal();
        }
      }

      if (!prevProps.current?.visible) {
        const newState = {};

        fields.forEach((field, i) => (newState[i] = field.value));
        setState(newState);
        setError({});
      }
    }

    return () => {
      prevProps.current = { visible, loading: taskObj?.loading };
    };
  }, [visible, taskObj, props, fields]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    const error = {};

    fields?.forEach((field, i) => {
      const vlaue = state[i]?.trim?.();
      if (field.mandatory && !vlaue) {
        error[i] = field.validation;
      }
    });

    setError(error);

    return !!Object.keys(error).length;
  }, [state, fields]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const payload = {
        _id: taskId,
        fields,
      };
      payload.fields = fields.map((field, i) => {
        const newFieldData = {
          ...field,
          value: state[i] ?? "",
        };

        if (newFieldData?._id === "task_name") {
          payload.name = newFieldData?.value;
        }

        return newFieldData;
      });

      if (props.onSubmit) {
        props.onSubmit?.(payload.fields);
      } else {
        dispatch(openAiActions.onUpdateOneRequest(payload));
      }
    }
  }, [hasError, state, taskId, dispatch, fields]);

  return (
    <CustomModal
      className="openai-task-modal-container"
      visible={visible}
      title="Update Details"
      onCancel={props.handleModal}
      footer={
        <Row className="p10">
          <Button
            className="new_button"
            type="primary"
            size="large"
            onClick={onSubmit}
            loading={taskObj?.loading}
          >
            Update
          </Button>

          <Button
            className="new_button"
            type="primary-outline"
            size="large"
            disabled={taskObj?.loading}
            onClick={props.handleModal}
          >
            Cancel
          </Button>
        </Row>
      }
    >
      <Col className="content-container">
        {fields?.map?.((field, i) => (
          <Fragment key={i}>
            <InputField
              key={i}
              {...field}
              value={state[i]}
              error={error[i]}
              onChange={handleChange(`${i}`)}
              disabled={taskObj?.loading || field?.disabled}
            />
          </Fragment>
        ))}

        {attachment && (
          <Row>
            <FileItem file={attachment} />
          </Row>
        )}
      </Col>
    </CustomModal>
  );
};

export default React.memo(OpenAiTaskModal);
