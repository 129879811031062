import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Input, Radio, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SearchOutlined } from "@ant-design/icons";

import CustomModal from "../../components/CustomModal";
import ProjectTemplateItem from "./ProjectTemplateItem";

import { projectActions } from "../duck/projectReducer";
import { templateActions } from "../../template/duck/templateReducer";

import constants from "../../utils/constants";
import routes from "../../utils/routes";

import "./CreateProjectModal.scss";

const { TEMPLATE_TYPES } = constants;

const CreateProjectModal = (props) => {
  const { visible } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const prevProps = useRef();

  const project = useSelector(({ project }) => project);

  const templateList = useSelector(({ template }) => {
    const mFilter = JSON.stringify({});
    const boards = template.boards;
    const mBoard = boards[mFilter];

    return mBoard?.list;
  });

  const [state, setState] = useState({
    search: "",
    templateType: TEMPLATE_TYPES[0],
    selectedTemplateId: null,
  });

  useEffect(() => {
    if (visible && prevProps.current.loading && !project?.loading) {
      if (!project?.error && project?.newCreatedDataId) {
        navigate(
          `${routes.CREATE_PROJECT_FLOW}?draft_id=${project?.newCreatedDataId}`
        );
      }
    } else if (!visible) {
      setState({
        search: "",
        templateType: TEMPLATE_TYPES[0],
        selectedTemplateId: null,
      });
    } else if (!templateList) {
      dispatch(templateActions.onGetAllRequest());
    }

    return () => {
      prevProps.current = { loading: project?.loading };
    };
  }, [visible, dispatch, navigate, project, templateList]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((preState) => ({
        ...preState,
        [name]: value,
      }));
    },
    []
  );

  const onOk = useCallback(() => {
    const payload = {
      template_id: state?.selectedTemplateId,
    };

    dispatch(projectActions.onCreateOneRequest(payload));
  }, [dispatch, state?.selectedTemplateId]);

  const renderFooter = useMemo(() => {
    return (
      <>
        <Button type="link" onClick={props?.handleModal} size="large">
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={onOk}
          size="large"
          loading={project?.loading}
          disabled={!state.selectedTemplateId}
        >
          Create Project
        </Button>
      </>
    );
  }, [onOk, props?.handleModal, state.selectedTemplateId, project?.loading]);

  return (
    <CustomModal
      className="create-project-modal-container"
      visible={visible}
      title={"Create New Project"}
      okButtonText={"Create Project"}
      onCancel={props?.handleModal}
      footer={renderFooter}
    >
      {/* search start */}
      <Row className="search-input-container">
        <Input
          className="search-input mb5"
          prefix={<SearchOutlined />}
          placeholder="Search"
          onChange={handleChange("search")}
          value={state.search}
        />
      </Row>
      {/* search end */}

      <Col className="content-container">
        {/* filter template types btn start */}
        {!state.search && (
          <Row className="template-types-btn-container fw">
            <Radio.Group
              onChange={handleChange("templateType")}
              defaultValue={TEMPLATE_TYPES[0]}
              value={state.templateType}
            >
              {TEMPLATE_TYPES.map((type) => (
                <Radio.Button key={type} value={type}>
                  {type}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Row>
        )}
        {/* filter template types btn end */}

        {/* template start */}
        <Row className="template-items-container" justify="space-between">
          {templateList?.map?.((item, i) => (
            <ProjectTemplateItem
              key={`${item}_${i}`}
              id={item}
              onSelect={handleChange("selectedTemplateId")}
              isSelected={state.selectedTemplateId === item}
              disabled={project?.loading}
            />
          ))}
        </Row>
        {/* template end */}
      </Col>
    </CustomModal>
  );
};

export default React.memo(CreateProjectModal);
