import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Input, Row, Col, Select, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import CustomModal from "../../components/CustomModal";

import { userActions } from "../../users/duck/userReducer";
import { validEmail } from "../../utils/commonFunctions";

const InviteUserModal = (props) => {
  const { visible, handleModal, selectUser, selectedUser } = props;

  let modalTitle = selectedUser ? "Edit User Member" : "Add User Member";

  const prevProp = useRef();
  const dispatch = useDispatch();
  const rolesMap = useSelector(({ role }) => role.map);

  const { loading, error, newCreatedDataId } = useSelector(({ user }) => user);
  const [state, setState] = useState({});
  const [formError, setFormError] = useState({});

  useEffect(() => {
    if (prevProp?.current?.loading && !loading) {
      if (!error && newCreatedDataId) {
        handleModal(false);
        selectUser(null);
      }
    } else if (!prevProp?.current?.visible && visible) {
      setState({
        first_name: selectedUser?.first_name ?? null,
        last_name: selectedUser?.last_name ?? null,
        email: selectedUser?.email ?? null,
        role_id: selectedUser?.role_id ?? null,
        active: selectedUser?.active,
      });
    }
    setFormError({});

    return () => {
      prevProp.current = { loading, visible };
    };
  }, [
    loading,
    visible,
    error,
    handleModal,
    rolesMap,
    dispatch,
    selectUser,
    selectedUser?.first_name,
    selectedUser?.last_name,
    selectedUser?.email,
    selectedUser?.role_id,
    selectedUser?.active,
    newCreatedDataId,
  ]);

  const hasError = useCallback(() => {
    let { first_name, email, role_id } = state;
    let error = {};

    email = email?.trim();
    if (!email) {
      error.email = "Please enter member email";
    } else if (!validEmail(email)) {
      error.email = "Please enter valid email";
    }
    if (!first_name) {
      error.first_name = "Please enter First Name";
    }

    if (!role_id) {
      error.role_id = "Please select a role for this user";
    }

    setFormError(error);
    return !!Object.keys(error).length;
  }, [state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      setFormError({});
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
  let { first_name, last_name, email, role_id, active } = state;

  const onOk = useCallback(() => {
    if (!hasError()) {
      if (selectedUser) {
        let updatedData = {
          _id: selectedUser?._id,
          role_id,
          active,
          first_name,
          last_name,
        };
        dispatch(userActions.onUpdateOneRequest(updatedData));
        if (!error) {
          handleModal(false);
          selectUser(null);
        }
      } else {
        let newUser = {
          first_name,
          last_name,
          email,
          role_id,
        };
        dispatch(userActions.onCreateOneRequest(newUser));
      }
    }
  }, [
    active,
    dispatch,
    email,
    error,
    first_name,
    handleModal,
    hasError,
    last_name,
    role_id,
    selectUser,
    selectedUser,
  ]);

  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        <Button type="primary" onClick={onOk} size="large" loading={loading}>
          {selectedUser ? "Update" : "Add"}
        </Button>
        <Button type="primary-outline" onClick={handleModal} size="large">
          Cancel
        </Button>
      </Row>
    );
  }, [handleModal, onOk, loading, selectedUser]);

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={props?.handleModal}
      footer={renderFooter}
    >
      <div>
        <Col className="mb10">
          <Col>
            <label className="label">First Name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
              style={{
                padding: "13px 10px 13px 20px",
                height: 46,
                borderRadius: 6,
              }}
              size="large"
              type="text"
              value={first_name}
              placeholder="Jhon"
              onChange={handleChange("first_name")}
            />

            <Row className="error mt5">{formError?.first_name}</Row>
          </Col>
        </Col>

        <Col className="mb10">
          <Col>
            <label className="label">Last Name</label>
          </Col>
          <Col>
            <Input
              style={{
                padding: "13px 10px 13px 20px",
                height: 46,
                borderRadius: 6,
              }}
              size="large"
              type="text"
              value={last_name}
              placeholder="Doe"
              onChange={handleChange("last_name")}
            />
          </Col>
        </Col>

        <Col className="mb10">
          <Col>
            <label className="label">Email </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            {selectedUser ? (
              <span>{email}</span>
            ) : (
              <Input
                className="new_search fw"
                placeholder="abc@cockpit.com"
                value={email}
                onChange={handleChange("email")}
                size="large"
              />
            )}

            <Row className="error mt5">{formError?.email}</Row>
          </Col>
        </Col>
        <Col className="mt10">
          <Col>
            <label className="label">Role</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col>
            <Select
              placeholder="Select Role"
              size="large"
              value={role_id}
              className="custom-select wpsb fw"
              onChange={handleChange("role_id")}
              suffixIcon={<CaretDownOutlined />}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={loading}
            >
              {Object.keys(rolesMap).map((id) => {
                const { role_id, name } = rolesMap?.[id];

                return (
                  <Select.Option key={role_id} value={role_id}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
            <Row className="error mt5">{formError?.role_id}</Row>
          </Col>
        </Col>
        {selectedUser && (
          <Col className="mt10">
            <Col />
            <Col>
              <Checkbox checked={active} onChange={handleChange("active")}>
                Active
              </Checkbox>
            </Col>
          </Col>
        )}
      </div>
    </CustomModal>
  );
};

export default InviteUserModal;
