import React from "react";
import { Button } from "antd";
import { useGoogleLogin } from "@react-oauth/google";

import google from "../../assets/images/google.png";

const GoogleSignInBtn = ({
  text = "Join with Google",
  loading = false,
  onSignInSuccess = (accessToken) => accessToken,
  ...props
}) => {
  const onSignIn = useGoogleLogin({
    onSuccess: (tokenRes) => {
      // console.log("tokenRes", tokenRes);
      onSignInSuccess(tokenRes.access_token);
    },
    onError: (errorRes) => {
      console.log("Google Failure response", errorRes);
    },
    scope: "email",
  });

  return (
    <Button
      className="fw"
      onClick={onSignIn}
      loading={loading}
      block
      size="large"
      icon={<img className="h-6 mr-1.5" src={google} alt="google" />}
    >
      {text}
    </Button>
  );
};

export default GoogleSignInBtn;
