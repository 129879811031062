import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Input, Row, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Loading from "../components/Loading";
import NotFound from "../components/NotFound";
import SectionItem from "./components/SectionItem";

import { roleActions } from "./duck/roleReducer";
import rolePermissions from "../utils/rolePermissions";

import "./components/Role.scss";

const RoleDetails = () => {
  const preProps = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const roleObj = useSelector(({ role }) => role.map[id]);
  const { loading } = roleObj || {};

  const [state, setState] = useState({});

  const [modulePremissions, setModulePremissions] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (!roleObj) {
      dispatch(roleActions.onGetOneRequest({ _id: id }));
    } else if (preProps?.current?.loading && !roleObj.loading) {
      if (!roleObj.error) {
        navigate(-1);
      }
    } else if (roleObj?._id && !roleObj?.loading) {
      let allModulePermissions = true;
      const { project, task, module } = roleObj?.permissions || {};

      const mPermissions = Object.assign(
        {},
        rolePermissions.module,
        module && JSON.parse(JSON.stringify(module))
      );

      Object.keys(mPermissions).forEach((sKey) => {
        const section = mPermissions[sKey];

        if (typeof section === "object") {
          Object.keys(section).forEach((ssKey) => {
            if (typeof section[ssKey] === "boolean") {
              allModulePermissions = allModulePermissions && section[ssKey];
            } else if (typeof section[ssKey] === "object") {
              Object.keys(section[ssKey]).forEach((k) => {
                if (typeof section[ssKey][k] === "boolean") {
                  allModulePermissions =
                    allModulePermissions && section[ssKey][k];
                }
              });
            }
          });
        }
      });

      const mProjectPermissions = Object.assign(
        {},
        rolePermissions.project,
        project
      );
      const mTaskPermissions = Object.assign({}, rolePermissions.task, task);
      let allowAllOtherPermissions = false;
      if (
        mProjectPermissions.showAll &&
        mProjectPermissions.create &&
        mTaskPermissions.showAll &&
        mTaskPermissions.create
      ) {
        allowAllOtherPermissions = true;
      }

      setState((preState) => ({
        ...preState,
        name: roleObj?.name,
        active: roleObj?.active,
        allowAllModulePermissions: allModulePermissions,
        project: mProjectPermissions,
        task: mTaskPermissions,
        allowAllOtherPermissions,
      }));
      setModulePremissions(mPermissions);
    }

    return () => {
      preProps.current = { loading: roleObj?.loading };
    };
  }, [id, roleObj, dispatch, navigate]);

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  const onSave = useCallback(() => {
    let { name, active, project, task } = state;
    name = name?.trim?.();

    if (!name) {
      setError({ name: "Role Name is Required" });
    } else {
      const permissions = {
        project,
        task,
        module: modulePremissions,
      };

      const mPermissionsPayload = {
        name,
        active,
        permissions,
      };

      if (id) {
        mPermissionsPayload._id = id;
        dispatch(roleActions.onUpdateOneRequest(mPermissionsPayload));
      } else {
        dispatch(roleActions.onCreateOneRequest(mPermissionsPayload));
      }
    }
  }, [id, state, modulePremissions, dispatch]);

  // handle other inputs start
  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );
  // handle other inputs end

  // handle project premissions start
  const handleAllOtherPermissionsChange = useCallback((value) => {
    setState((preState) => ({
      ...preState,
      allowAllOtherPermissions: value,
      project: {
        showAll: value,
        create: value,
      },
      task: {
        showAll: value,
        create: value,
      },
    }));
  }, []);

  const handleProjectChange = useCallback(
    (name) => (premission) => (value) => {
      setState((preState) => {
        preState[name][premission] = value;

        if (
          preState.project.showAll &&
          preState.project.create &&
          preState.task.showAll &&
          preState.task.create
        ) {
          preState.allowAllOtherPermissions = true;
        } else {
          preState.allowAllOtherPermissions = false;
        }

        return { ...preState };
      });
    },
    []
  );
  // handle project premissions end

  // handle module premissions start
  const handleAllModulePermissionsChange = useCallback((value) => {
    setModulePremissions((prePermissions) => {
      Object.keys(prePermissions).forEach((sKey) => {
        const section = prePermissions[sKey];

        if (typeof section === "object") {
          Object.keys(section).forEach((ssKey) => {
            if (typeof section[ssKey] === "boolean") {
              section[ssKey] = value;
            } else if (typeof section[ssKey] === "object") {
              Object.keys(section[ssKey]).forEach((k) => {
                if (typeof section[ssKey][k] === "boolean") {
                  section[ssKey][k] = value;
                }
              });
            }
          });
        }
      });

      return { ...prePermissions };
    });
    setState((preState) => ({
      ...preState,
      allowAllModulePermissions: value,
    }));
    setError({});
  }, []);

  const handleModuleChange = useCallback(
    (name) => (value) => {
      setModulePremissions((prePermissions) => {
        let allowAll = true;
        prePermissions = Object.assign({}, prePermissions, { [name]: value });

        Object.keys(prePermissions).forEach((sKey) => {
          const section = prePermissions[sKey];

          if (typeof section === "object") {
            Object.keys(section).forEach((ssKey) => {
              if (typeof section[ssKey] === "boolean") {
                allowAll = allowAll && section[ssKey];
              } else if (typeof section[ssKey] === "object") {
                Object.keys(section[ssKey]).forEach((k) => {
                  if (typeof section[ssKey][k] === "boolean") {
                    allowAll = allowAll && section[ssKey][k];
                  }
                });
              }
            });
          }
        });

        setState((preState) => ({
          ...preState,
          allowAllModulePermissions: allowAll,
        }));

        return { ...prePermissions };
      });
    },
    []
  );
  // handle module premissions end

  // check if task is fetching
  if (!roleObj?._id && roleObj?.loading) {
    return <Loading />;
  }
  // check if task exist
  else if (!roleObj?._id) {
    return <NotFound subTitle={"Sorry, this role does not exist."} />;
  }

  return (
    <Col className="content-body app-container">
      {/* page header start */}
      <Row className="page-header" justify="space-between" align="middle">
        <Row className="page-heading">Edit Role</Row>

        <Row>
          <Button
            className="new_button"
            type="primary-outline"
            size="large"
            loading={roleObj.loading}
            onClick={onSave}
          >
            Save Role
          </Button>

          <Button
            className="new_button ml10"
            size="large"
            onClick={onGoBack}
            disabled={roleObj.loading}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </Row>
      </Row>
      {/* page header end */}

      {/* page body start */}
      <Col>
        <Col className="mb20">
          {/* name input start */}
          <Row className="mb10">
            <Col span={4}>
              <label className="label mt10">Role Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col span={6}>
              <Input
                className="new_search"
                size="large"
                // className="custom-input"
                type="text"
                value={state.name}
                onChange={handleChange("name")}
                disabled={loading}
              />

              <Row className="error mt5">{error.name}</Row>
            </Col>
          </Row>
          {/* name input end */}

          {/* role active start */}
          <Row className="mb10">
            <Col span={4}>
              <label className="label">Active:</label>
            </Col>

            <Col span={18}>
              <Switch
                checked={state.active}
                onChange={handleChange("active")}
              />
            </Col>
          </Row>
          {/* role active end */}
        </Col>

        {/* project permissions start */}
        <Card
          className="permissions-table-container"
          title={
            <Row align="middle" className="p10">
              <Col span={4}>
                <label className="label">Project Permissions</label>
              </Col>

              <Col span={20}>
                <Switch
                  checked={state.allowAllOtherPermissions}
                  onChange={handleAllOtherPermissionsChange}
                  disabled={loading}
                />

                <span className="ml15">Allow All</span>
              </Col>
            </Row>
          }
          bordered={false}
        >
          <table className="permissions-table">
            <thead>
              <tr>
                <th>
                  <Row className="pl15">Projects</Row>
                </th>

                <th>
                  <Row>
                    <Col className="text-right" span={9}>
                      Individual's Project
                    </Col>

                    <Col span={6}>
                      <Switch
                        checked={state.project?.showAll}
                        onChange={handleProjectChange("project")("showAll")}
                        disabled={loading}
                      />
                    </Col>

                    <Col className="text-left" span={9}>
                      All Projects
                    </Col>
                  </Row>
                </th>

                <th>
                  <Row>
                    <Col span={9} />

                    <Col span={6}>
                      <Switch
                        checked={state.project?.create}
                        onChange={handleProjectChange("project")("create")}
                        disabled={loading}
                      />
                    </Col>

                    <Col className="text-left" span={9}>
                      Create
                    </Col>
                  </Row>
                </th>
              </tr>

              <tr>
                <th>
                  <Row className="pl15">Tasks</Row>
                </th>

                <th>
                  <Row align="middle">
                    <Col className="text-right" span={9}>
                      Individual's Task
                    </Col>

                    <Col span={6}>
                      <Switch
                        checked={state.task?.showAll}
                        onChange={handleProjectChange("task")("showAll")}
                        disabled={loading}
                      />
                    </Col>

                    <Col className="text-left" span={9}>
                      All Task
                    </Col>
                  </Row>
                </th>

                <th>
                  <Row>
                    <Col span={9} />

                    <Col span={6}>
                      <Switch
                        checked={state.task?.create}
                        onChange={handleProjectChange("task")("create")}
                        disabled={loading}
                      />
                    </Col>

                    <Col className="text-left" span={9}>
                      Create
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
          </table>
        </Card>
        {/* project permissions end */}

        {/* module permissions start */}
        <Card
          className="permissions-table-container"
          title={
            <Row className="p10">
              <Col span={4}>
                <label className="label">Module Visibility & Action</label>
              </Col>

              <Col span={20}>
                <Switch
                  checked={state.allowAllModulePermissions}
                  onChange={handleAllModulePermissionsChange}
                  disabled={loading}
                />

                <span className="ml15">Show All</span>
              </Col>
            </Row>
          }
          bordered={false}
        >
          <table className="permissions-table">
            {Object.keys(modulePremissions).map(
              (sName, i) =>
                typeof modulePremissions[sName] === "object" && (
                  <SectionItem
                    key={i}
                    section={modulePremissions[sName]}
                    handleChange={handleModuleChange(sName)}
                    disabled={loading}
                  />
                )
            )}
          </table>
        </Card>
        {/* module permissions end */}
      </Col>
      {/* page body end */}
    </Col>
  );
};

export default React.memo(RoleDetails);
