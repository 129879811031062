import React, { useCallback, useMemo } from "react";
import { Avatar, Col, Row, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { CopyOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";

import {
  getNameInitials,
  getAvatarColor,
  showNotification,
} from "../../utils/commonFunctions";
import constants from "../../utils/constants";

const { URL_REGEX } = constants;

const ChatItem = (props) => {
  const { message = {} } = props;

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);

  const { createdBy, body, canCopy, loading } = useMemo(() => {
    const createdBy = {};
    let canCopy = false;

    if (message?.role === "user") {
      createdBy._id = loggedInUser?._id;
      createdBy.name = `${loggedInUser?.first_name} ${
        loggedInUser?.last_name || ""
      }`.trim();
    } else if (message?.role === "assistant" || message?.role === "system") {
      canCopy = true;
      createdBy._id = `writely-bot`;
      createdBy.name = `Writely Bot`;
    }

    return {
      createdBy,
      body: message?.content,
      canCopy,
      loading: message?.loading,
    };
  }, [message, loggedInUser]);

  const avatarColor = useMemo(
    () => getAvatarColor(createdBy?._id),
    [createdBy?._id]
  );

  const nameInitials = useMemo(
    () => getNameInitials(createdBy?.name),
    [createdBy]
  );

  const renderText = useCallback(
    (txt) =>
      txt.split(" ").map((part, i) => {
        let text = part;
        text = URL_REGEX.test(part) ? (
          <a href={part} target="_blank" key={part} rel="noreferrer">
            {`${part} `}
          </a>
        ) : (
          `${part} `
        );

        return text;
      }),
    []
  );

  const onCopy = useCallback(() => {}, []);

  return (
    <Col className="open-ai-chat-item mb-2">
      <Row className="chat-item-container">
        <Col>
          <Avatar
            size={30}
            shape={"circle"}
            style={{ backgroundColor: avatarColor }}
          >
            {nameInitials}
          </Avatar>
        </Col>

        <Col className="chat-details-container">
          <Row className="user-details" align="middle" justify="space-between">
            <span className="name">{createdBy.name}</span>
            <span>
              {!loading && canCopy && (
                <Tooltip title="Copy Text">
                  <CopyToClipboard
                    text={body}
                    onCopy={() =>
                      showNotification("success", "Copy to clipboard")
                    }
                  >
                    <CopyOutlined className="mr-1" onClick={onCopy} />
                  </CopyToClipboard>
                </Tooltip>
              )}
            </span>
          </Row>
          <Col className="chat-details">
            <Col className="message mt-2">{renderText(body)}</Col>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ChatItem);
