import React from "react";
import { Row, Spin } from "antd";

import "./Loading.scss";

const Loading = (props) => {
  return (
    <Row className="fh loading-container" justify="center" align="middle">
      <Spin />
    </Row>
  );
};

export default React.memo(Loading);
