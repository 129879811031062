import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import NotFound from "../components/NotFound";
import routes from "../utils/routes";
import { assessmentActions } from "./duck/assessmentReducer";
import QNAModal from "./components/QNAModal";
import constants from "../utils/constants";

const { ASSESSMENTS_LEVEL } = constants;
const { Paragraph } = Typography;

const AssessmentDetails = () => {
  const preProps = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const assessments = useSelector(({ assessment }) => assessment);

  const mAssessment = useMemo(
    () =>
      id
        ? assessments?.map?.[id]
        : { loading: assessments?.loading, error: assessments?.error },
    [assessments?.error, assessments?.loading, assessments?.map, id]
  );

  const [state, setState] = useState({
    active: true,
  });
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [error, setError] = useState({});
  const [qna, setQNA] = useState([]);

  const handleModal = useCallback((show = false, selectedObj) => {
    show = typeof show === "boolean" && show;

    setState((preState) => ({ ...preState, showModal: show, selectedObj }));
  }, []);

  const handleQNA = useCallback(
    (qnaObj) => {
      let { index, ...rest } = qnaObj;
      if (index !== undefined) {
        setQNA(qna.map((obj, i) => (i === index ? rest : obj)));
      } else {
        setQNA((preState) => [...preState, qnaObj]);
      }
      setIsStateChanged(true);
    },
    [qna]
  );

  const handleRemove = useCallback(
    (index) => {
      setQNA(qna.filter((_, i) => i !== index));
      setIsStateChanged(true);
    },
    [qna]
  );

  useEffect(() => {
    if (id && !mAssessment) {
      dispatch(assessmentActions.onGetOneRequest({ _id: id }));
    } else if (preProps?.current?.loading && !mAssessment.loading) {
      if (!mAssessment.error) {
        navigate(routes.ASSESSMENTS);
        setError({});
      }
    } else if (mAssessment?._id && !mAssessment?.loading) {
      setState({
        name: mAssessment?.name,
        level: mAssessment?.level,
        total_marks: mAssessment?.total_marks,
        duration_type: mAssessment?.duration_type,
        duration: mAssessment?.duration,
        active: mAssessment?.active,
      });
      setQNA(mAssessment?.questions ?? []);
    }

    return () => {
      preProps.current = { loading: mAssessment?.loading };
    };
  }, [dispatch, id, mAssessment, navigate]);

  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault();
      if (isStateChanged) {
        if (
          window.confirm(
            "You have unsaved changes.Leaving this page will undo all the changes"
          )
        ) {
          navigate(routes.ASSESSMENTS);
        } else {
          window.history.pushState(null, null, window.location.pathname);
        }
      } else {
        navigate(routes.ASSESSMENTS);
      }
    },
    [isStateChanged, navigate]
  );

  const handleBeforeUnload = useCallback(
    (e) => {
      if (isStateChanged) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [isStateChanged]
  );

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [handleBeforeUnload, onBackButtonEvent]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
      setIsStateChanged(true);
    },
    []
  );

  const hasError = useCallback(() => {
    const { name, level } = state;
    const error = {};
    if (!name) {
      error.name = "Please enter title of test";
    }
    if (!level) {
      error.level = "Please select level of test";
    }

    setError(error);

    return !!Object.keys(error).length;
  }, [state]);

  const addOrEdit = useCallback(() => {
    if (!hasError()) {
      const { name, level, active } = state;
      const payload = {
        name,
        level,
        active,
        questions: qna,
      };

      if (id) {
        payload._id = id;
        dispatch(assessmentActions.onUpdateOneRequest(payload));
      } else {
        dispatch(assessmentActions.onCreateOneRequest(payload));
      }
    }
  }, [dispatch, hasError, id, qna, state]);

  const loading = mAssessment?.loading;
  // check if assessment is fetching
  if (id && !mAssessment?._id && loading) {
    return <Loading />;
  }
  // check if assessment exist
  else if (id && !mAssessment?._id) {
    return <NotFound subTitle={"Sorry, this assessment does not exist."} />;
  }

  return (
    <Col className="content-body app-container">
      {/* page header start */}
      <Row className="page-header" justify="space-between" align="middle">
        <Row className="page-heading">{id ? "Edit Set" : "Add Set"}</Row>
        <Row>
          <Button
            className="new_button mr10"
            type="primary-outline"
            size="large"
            onClick={(e) => onBackButtonEvent(e)}
            disabled={loading}
          >
            <ArrowLeftOutlined />
            Back
          </Button>
          <Button
            className="new_button"
            type="primary"
            size="large"
            loading={loading}
            onClick={addOrEdit}
          >
            Save Set
          </Button>
        </Row>
      </Row>
      {/* page header end */}

      {/* page body start */}
      <Col className="bg-white fh p20 overflow-auto">
        <Col className="mb20" xl={12} md={24} sm={24} xs={24}>
          {/* name input start */}
          <Row className="mb10">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label mt10">Title</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="new_search fw"
                size="large"
                placeholder="Eg. Advance English Test"
                type="text"
                value={state?.name}
                onChange={handleChange("name")}
                disabled={loading}
              />

              <Row className="error mt5">{error?.name}</Row>
            </Col>
          </Row>
          {/* name input end */}

          {/*  level start */}
          <Row className="mb10">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label mt10">Level</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>

            <Col lg={18} md={24} sm={24} xs={24}>
              <Select
                className="wpsb fw"
                size="large"
                value={state?.level}
                placeholder="Select Level of Test"
                onChange={handleChange("level")}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={loading}
              >
                {ASSESSMENTS_LEVEL.map((level, i) => (
                  <Select.Option key={i} title={level} value={level} />
                ))}
              </Select>
              <Row className="error mt5">{error?.level}</Row>
            </Col>
          </Row>
          {/*  level end */}

          {/*  active start */}
          <Row className="mb15">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label className="label">Active:</label>
            </Col>

            <Col lg={18} md={24} sm={24} xs={24}>
              <Switch
                checked={state.active}
                onChange={handleChange("active")}
              />
            </Col>
          </Row>
          {/*  active end */}

          {/*  qna button start */}
          <Row className="mb20">
            <Col lg={6} md={24} sm={24} xs={24} />

            <Col span={18}>
              <Button
                className="new_button"
                size="large"
                type="primary-outline"
                onClick={() => handleModal(true)}
                disabled={loading}
              >
                Add Question
              </Button>
            </Col>
          </Row>
          {/*  qna button end */}

          {qna?.map((item, index) => (
            <Row className="mb10 " key={index}>
              <Col lg={6} md={24} sm={24} xs={24}>
                <label className="label">Question {index + 1} </label>
              </Col>
              <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                <Row align="middle ">
                  <Col span={12}>
                    <Paragraph ellipsis={{ rows: 2 }}>
                      {item?.question}
                    </Paragraph>
                    <hr />
                  </Col>

                  <Col span={4}>
                    <Row align="middle" wrap={false}>
                      <Tooltip title="Delete">
                        <Popconfirm
                          title="Are you sure to delete this?"
                          onConfirm={() => handleRemove(index)}
                          okText="Yes"
                          cancelText="No"
                          placement="bottom"
                          disabled={loading}
                        >
                          <DeleteOutlined
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                              margin: " 0 20px",
                            }}
                          />
                        </Popconfirm>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleModal(true, { ...item, index })}
                        />
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
      </Col>
      {/* page body end */}

      <QNAModal
        visible={state?.showModal}
        selectedObj={state?.selectedObj}
        showModal={handleModal}
        handleQNA={handleQNA}
      />
    </Col>
  );
};

export default AssessmentDetails;
