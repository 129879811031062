import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";

import { dashboardActions } from "./dashboardReducer";
import { showNotification } from "../../utils/commonFunctions";
import appUrl from "../../config/appUrl";

const {
  getDashboard,
  onHandleSuccess,
  onHandleError
} = dashboardActions;


// get dashboard start
const getDashboardApi = () => {
  return axios({
    method: "GET",
    url: appUrl.DASHBOARD,
  });
};

function* getDashboardDetails(action) {
  try {
    const response = yield call(getDashboardApi);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      yield put(onHandleSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("dashboard details ", error);
    yield put(onHandleError(error));
  }
}
// get dashboard end

function* dashboardSaga() {
  yield takeLatest(getDashboard.type, getDashboardDetails);
}

export default dashboardSaga;
