import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Layout,
  Col,
  Row,
  Menu,
  Dropdown,
  Avatar,
  Badge,
  Button,
  Popover,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  FormOutlined,
  SettingOutlined,
  DownOutlined,
  // RobotFilled,
  BellOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getAvatarColor,
  getNameInitials,
  paramsToString,
} from "../utils/commonFunctions";
import routes from "../utils/routes";
import constants from "../utils/constants";
// import bellIcon from "../assets/images/bell.svg";
import "./AppHeader.scss";
import roleTransformer from "./RoleTransformer";
import ChangeProfilePassword from "../profile/components/ChangeProfilePassword";
import NotificationTransformer from "../notifications/components/NotificationTransformer";
import { notificationActions } from "../notifications/duck/notificationReducer";
import EmptyContainer from "./EmptyContainer";
import { useUserPermissions } from "../utils/customHooks";
import { closeSocket } from "../config/Socket";
const { ACTION_TYPES, ROLE_IDS } = constants;

const AppHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const mPermissions = useUserPermissions();
  const { module } = mPermissions;
  const { openAi } = module;

  const [boardKey, setBoardKey] = useState("{}");
  const { list, meta, loading } = useSelector(({ notification }) => {
    const boards = notification.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          offset: offset,
          limit: limit,
          // is_read: false,
        };
        setBoardKey(paramsToString(params));
        dispatch(notificationActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount]
  );

  useEffect(() => {
    loadMore();
  }, []);


  const [show, setShow] = useState(false);
  const handleModal = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setShow(show);
  }, []);

  const user = useSelector(({ loggedInUser }) => loggedInUser.data);
  const userFullName = useMemo(
    () => `${user?.first_name || ""} ${user?.last_name || ""}`.trim(),
    [user]
  );
  const avatarColor = useMemo(() => getAvatarColor(user?._id), [user?._id]);

  const onProfile = useCallback(() => navigate(routes.PROFILE), [navigate]);

  const onLogout = useCallback(() => {
    dispatch({ type: ACTION_TYPES.LOGOUT });
    let navigateTo;
    closeSocket();
    switch (user?.role_id) {
      case "client":
        navigateTo = routes.CLIENT_LOGIN;
        break;
      case "freelancer":
        navigateTo = routes.FREELANCER_LOGIN;
        break;

      default:
        navigateTo = routes.LOGIN;
        break;
    }

    navigate(navigateTo);
  }, [dispatch, navigate, user?.role_id]);

  const routesName = useMemo(
    () => ({
      [routes.PROJECT]: "Project List",
      [routes.PROJECT_ARCHIVED]: "Archived Projects",
      [routes.PROJECT_DRAFT]: "Project Drafts",
      [routes.PROJECT_TASK]: "Task List",
      [routes.PROJECT_TASK_DELAYED]: "Task Delayed",
      [routes.INVOICE_REQUESTOR]: "Requestor Invoice",
      [routes.INVOICE_WRITER]: "Creator Invoice",
      [routes.FREELANCER]: "Writers",
      [routes.USERS]: "Users",
      [routes.OPEN_AI_TEMPLATES]: "Templates",
      [routes.OPEN_AI_TASKS]: "Projects",
    }),
    []
  );

  const headerText = useCallback(() => {
    if (routesName?.[pathname]) {
      return <span className="pri-bold">{routesName?.[pathname]}</span>;
    } else {
      return (
        <>
          Welcome, <span className="pri-bold">{user?.first_name}</span>
        </>
      );
    }
  }, [pathname, routesName, user?.first_name]);


  const menuItems = useMemo(
    () => [
      {
        key: routes.PROFILE,
        icon:
          user?.role_id === ROLE_IDS.FREELANCER ? (
            <SettingOutlined />
          ) : (
            <FormOutlined />
          ),
        label:
          user?.role_id === ROLE_IDS.FREELANCER ? "Change Password" : "Profile",
        onClick:
          user?.role_id === ROLE_IDS.FREELANCER
            ? () => handleModal(true)
            : onProfile,
      },
      {
        key: routes.LOGOUT,
        icon: <PoweroffOutlined />,
        label: "Logout",
        onClick: onLogout,
      },
    ],
    [user?.role_id, onProfile, onLogout, handleModal]
  );

  const onClickMenu = useCallback(
    (e) => {
      const mItem = menuItems.find((item) => item.key === e.key);
      mItem?.onClick?.();
    },
    [menuItems]
  );

  return (
    <Layout.Header className="appHeader">
      <Row align="middle" justify="space-between" wrap={false}>
        <Row align="middle" className="nowrap">
          {/* toggle menu icon start */}
          {React.createElement(
            props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: props.toggleSidebar,
            }
          )}
          {/* toggle menu icon end */}

          <div className="info-contianer">
            <p className="overflow-ellipse">{headerText()}</p>
          </div>
        </Row>

        <Row
          align="middle"
          justify="space-between"
          style={{ paddingLeft: "auto" }}
          className="pointer mr20 "
          wrap={false}
        >
          {/* open ai chat icon start */}
          {openAi?.openAiChat?.show && (
            <img
              className="icon h-10 w-10 mr20"
              src="/img/icons/robot-icon.svg"
              alt="Writely Bot"
              onClick={() => navigate(routes.OPEN_AI_CHAT)}
            />
          )}
          {/* open ai chat icon end */}

          {/* notifications icon start */}
          <Popover
            id="notification-popup"
            getPopupContainer={(triggerNode) => triggerNode}
            trigger={["click"]}
            showArrow={false}
            overlayStyle={{
              padding: 0,
            }}
            content={
              <div className="notifications" >
                {list?.slice(0, 4)?.map((id) => (
                  <NotificationTransformer key={id} item={id} />
                ))}
                {!meta?.totalCount && !loading && <EmptyContainer />}
                <Button
                  className="notification-btn"
                  type="text"
                  size="large"
                  block
                  onClick={() => navigate(routes.NOTIFICATION)}
                >
                  See all notifications
                </Button>
              </div>
            }
            placement="bottom"
          >
            <Badge count={meta?.totalUnreadCount} offset={[-2, 0]}>
              <BellOutlined className="text-3xl" />
              {/* <img src={bellIcon} alt="notification bell icon" /> */}
            </Badge>
          </Popover>
          {/* notifications icon end */}

          <div className="vertical-divider  mr20"></div>

          <Dropdown
            className="pointer user-info-container"
            overlay={<Menu items={menuItems} onClick={onClickMenu} />}
            trigger={["click"]}
          >
            <Row align="middle">
              <Col className="mr10">
                <Avatar
                  size={40}
                  shape="circle"
                  style={{ backgroundColor: avatarColor }}
                >
                  {getNameInitials(userFullName)}
                </Avatar>
              </Col>

              <Row className="name-container" align="stretch">
                <Col>
                  <Col className="overflow-ellipse capitalize pri-bold">
                    {userFullName}
                  </Col>
                  <Col>{roleTransformer(user?.role_id)}</Col>
                </Col>
              </Row>

              <DownOutlined style={{ fontSize: "14px", marginLeft: "10px" }} />
            </Row>
          </Dropdown>
        </Row>
      </Row>
      <ChangeProfilePassword visible={show} showModal={handleModal} />
    </Layout.Header>
  );
};

export default memo(AppHeader);
