import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Progress, Radio, Row, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loggedInUserActions } from "../loggedInUser/duck/loggedInUserReducer";
import { showNotification } from "../utils/commonFunctions";
import "./AssessmentTest.scss";
import Loading from "../components/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { assessmentActions } from "../assessment/duck/assessmentReducer";
import NotFound from "../components/NotFound";
import Countdown from "antd/lib/statistic/Countdown";
import { useMemo } from "react";
import routes from "../utils/routes";
import axios from "axios";
import AppUrl from "../config/appUrl";
import moment from "moment-timezone";
import { assessmentTestActions } from "./duck/assessmentTestReducer";

const AssessmentTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assessment_id = useParams()?.id;

  const [testStatus, setTestStatus] = useState("STARTED");
  const [showError, setShowError] = useState(false);
  const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
  const [currentSelectedOption, setCurrentSelectedOption] = useState(null);
  const [userAnswer, setUserAnswer] = useState([]);
  const [testId, setTestId] = useState(null);

  const user = useSelector(({ loggedInUser }) => loggedInUser.data);
  const assessmentObj = useSelector(
    ({ assessment }) => assessment.map?.[assessment_id]
  );

  const mQuestions = useMemo(
    () => assessmentObj?.questions ?? [],
    [assessmentObj?.questions]
  );

  const testObj = useSelector(
    ({ assessmentTest }) => assessmentTest.map?.[testId]
  );

  useEffect(() => {
    if (!user) {
      dispatch(loggedInUserActions.onGetUserDetailsRequest());
    }
    if (!assessmentObj) {
      dispatch(assessmentActions.onGetOneRequest({ _id: assessment_id }));
    }
  }, [user, dispatch, assessmentObj, assessment_id]);

  const startTest = useCallback(() => {
    const date = localStorage.getItem(assessmentObj?._id);
    let diff = moment().diff(date, "days");

    if (date && diff <= 30) {
      alert(
        `You already gave this assessment on ${moment(date).format(
          "DD-MM-YYYY"
        )}`
      );
    } else {
      localStorage.setItem(assessmentObj?._id, moment().format());
      const payload = {
        assessment_id: assessmentObj?._id,
        user_id: user?._id,
        email: user?.email,
        status_type: "started",
      };

      axios({
        method: "POST",
        url: `${AppUrl.ASSESSMENT_TEST}`,
        data: payload,
      })
        .then((response) => {
          const { data } = response;

          const meta = data?.meta;
          if (meta?.success !== true || meta?.status !== 200) {
            showNotification("error", meta?.message);
            localStorage.removeItem(assessmentObj?._id);
          } else {
            setTestId(data?.data?._id);
            dispatch(
              assessmentTestActions.onGetOneRequest({ _id: data?.data?._id })
            );
            setTestStatus("ONGOING");
          }
        })
        .catch((err) => {
          console.log(err);
          showNotification("error", "Something went wrong");
        });
    }
  }, [assessmentObj?._id, dispatch, user?._id, user?.email]);

  const onNext = useCallback(
    (index) => {
      if (index && !currentSelectedOption) {
        setShowError(true);
      } else {
        setUserAnswer((prestate) => [
          ...prestate,
          {
            ...mQuestions?.[currentQuestionIndex],
            selected_option: index ? currentSelectedOption : null,
          },
        ]);
        setCurrentSelectedOption(null);
        setcurrentQuestionIndex((c) => c + 1);
        setShowError(false);
      }
    },
    [currentQuestionIndex, currentSelectedOption, mQuestions]
  );

  const date = useMemo(() => Date.now(), [testStatus, currentQuestionIndex]);

  const renderQuestion = useCallback(() => {
    const questionObj = mQuestions?.[currentQuestionIndex];

    return (
      questionObj && (
        <Row>
          <Row justify="space-between" className="fw">
            <p className="question">{questionObj?.question}</p>
          </Row>

          <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Radio.Group
              className="fw"
              onChange={(e) => {
                setShowError(false);
                setCurrentSelectedOption(e?.target?.value);
              }}
              value={currentSelectedOption}
            >
              <Space className="fw" direction="vertical">
                {Object.keys(questionObj?.options)?.map(
                  (option) =>
                    !!questionObj?.options?.[option] && (
                      <Radio
                        className="test-option"
                        value={option}
                        key={option}
                      >
                        {questionObj?.options?.[option]}
                      </Radio>
                    )
                )}
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      )
    );
  }, [currentQuestionIndex, currentSelectedOption, mQuestions]);

  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault();
      if (testStatus === "ONGOING") {
        if (
          window.confirm(
            "Leaving too early! It will put your assessment status on pending"
          )
        ) {
          navigate(-1);
        } else {
          window.history.pushState(null, null, window.location.pathname);
        }
      } else {
        navigate(-1);
      }
    },
    [navigate, testStatus]
  );

  const handleBeforeUnload = useCallback(
    (e) => {
      if (testStatus === "ONGOING") {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [testStatus]
  );

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [handleBeforeUnload, onBackButtonEvent]);

  useEffect(() => {
    if (
      !testObj?.loading &&
      mQuestions?.length &&
      currentQuestionIndex === mQuestions?.length &&
      testStatus !== "COMPLETED"
    ) {
      const payload = {
        _id: testId,
        status_type: "submitted",
        answer: userAnswer,
      };
      dispatch(assessmentTestActions.onUpdateOneRequest(payload));
      showNotification("success", "Test submitted successfully");
      setTestStatus("COMPLETED");
    }
  }, [
    currentQuestionIndex,
    dispatch,
    mQuestions?.length,
    testObj?.loading,
    testStatus,
    testId,
    userAnswer,
  ]);

  if (!assessmentObj?._id && assessmentObj?.loading) {
    return <Loading />;
  } else if (!assessmentObj?.loading && !assessmentObj?._id) {
    return (
      <NotFound
        subTitle={"Oops there is no assessment. Please try after sometime"}
      />
    );
  }

  return (
    <div className="assessment-container">
      <div className="test-container bg-white pri-box-shadow">
        {testStatus === "STARTED" && (
          <>
            <div className="modal-header pri-box-shadow">
              <Row className="heading">Assessment Test for Writers</Row>
            </div>
            <div className="modal-body">
              <h1>Instructions</h1>
              <ul>
                <li className="f15">
                  There is no negative marking for incorrect answers on this
                  test.
                </li>
                <li className="f15">
                  Each question has a time limit, and questions that are skipped
                  or submitted cannot be re-attempted.
                </li>
                <li className="f15">
                  The test will be automatically submitted when the time is up.
                </li>
                <li className="f15">
                  If you leave the test before it is completed, your assessment
                  status will be pending.
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <Button
                size="large"
                className="new_button"
                type="primary"
                onClick={startTest}
              >
                Start Test
              </Button>
            </div>
          </>
        )}
        {testStatus === "ONGOING" && assessmentObj && (
          <>
            <div className="modal-header pri-box-shadow">
              <Row justify="space-between" align="middle">
                <Row className="heading" align="middle">
                  Question No. {currentQuestionIndex + 1} Of{" "}
                  {mQuestions?.length}
                </Row>
                <Row className="sub-heading" align="middle">
                  <Countdown
                    prefix={
                      <span className="sub-heading">Time Remaining:</span>
                    }
                    value={
                      date +
                      (Number(mQuestions?.[currentQuestionIndex]?.time) + 1) *
                        1000
                    }
                    format="mm:ss"
                    onFinish={() => onNext()}
                  />
                </Row>
              </Row>
            </div>
            <div className="modal-body">
              {renderQuestion()}
              {showError && (
                <Row className="error mt5">
                  Please select an option to proceed
                </Row>
              )}
            </div>
            <div className="modal-footer">
              <Row align="middle">
                <Button
                  size="large"
                  className="new_button mr15"
                  type="primary"
                  onClick={() => onNext(true)}
                >
                  {currentQuestionIndex < mQuestions?.length - 1
                    ? "Next"
                    : "Submit"}
                </Button>
                <Button size="large" type="text" onClick={() => onNext()}>
                  Skip
                </Button>
              </Row>
            </div>
          </>
        )}

        {testStatus === "COMPLETED" && (
          <>
            <div className="modal-header pri-box-shadow">
              <Row className="heading ">Your Result</Row>
            </div>
            <div className="modal-body">
              {testObj?.loading ? (
                <>
                  <Row justify="center" className="level">
                    Please wait we are calculating your result...
                  </Row>
                  <Row justify="center" className="mt20">
                    <Spin />
                  </Row>
                </>
              ) : (
                <>
                  <Row justify="center" className="level">
                    Hello, {user?.first_name || user?.email}. Thank you for your
                    time. You received the following grades.
                  </Row>
                  <Row justify="center" className="level mt20">
                    <Progress
                      type="circle"
                      percent={100}
                      strokeColor="#2795FF"
                      style={{ color: "red" }}
                      format={() =>
                        ` ${testObj?.marks_obtained || 0}/${
                          testObj?.total_marks || 0
                        }`
                      }
                    />
                  </Row>
                  <Row justify="center" className="level mt20">
                    <span>{`To return to the dashboard, `}</span>
                    <Link className="level ml5" to={routes.DASHBOARD} replace>
                      click here!
                    </Link>
                  </Row>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AssessmentTest;
