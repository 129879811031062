import { Button, Modal } from "antd";
import React, { useState } from "react";
const ContentModal = (props) => {
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    // setIsModalOpen(showModal);
  };
  const handleOk = () => {
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    // setIsModalOpen(false);
  };

  const handleClick = () => {
    // Call the callback function to update the parent state
    props.showModal(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={props.showModal}>
        Open Modal
      </Button> */}
      <Modal
        title="Basic Modal"
        open={props.isModalOpen}
        onOk={handleClick}
        onCancel={handleClick}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};
export default ContentModal;
