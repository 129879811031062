import React, { Fragment, useCallback, useMemo } from "react";
import {
  Card,
  Col,
  Row,
  Dropdown,
  Menu,
  Popconfirm,
  Tooltip,
  Badge,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import {
  MoreOutlined,
  CalendarOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import User from "../../models/User";
import routes from "../../utils/routes";
import constants from "../../utils/constants";

import "./ProjectTaskItem.scss";
import { itemActions } from "../../item/duck/itemReducer";
import NameWithAvatar from "../../components/NameWithAvatar";

const { PROJECT_TASK_STATUS } = constants;
const { INITIATE, IN_PROGRESS, COMPLETED, APPROVED } = PROJECT_TASK_STATUS;

const ProjectTaskItem = (props) => {
  const { id, isProjectEditable } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const itemObj = useSelector(({ item }) => item.map[id]);
  const fields = useMemo(() => itemObj.fields, [itemObj.fields]);

  const createdBy = useMemo(
    () => new User().fromMap(itemObj?.created_by),
    [itemObj?.created_by]
  );
  const assignedFreelancer = useMemo(() => {
    if (itemObj?.current_assignee) {
      return new User().fromMap(itemObj?.current_assignee);
    } else {
      return new User().fromMap(itemObj?.project_id?.assigned_manager);
    }
  }, [itemObj]);

  const onProjectTaskDetails = useCallback(() => {
    const navigateTo = routes.PROJECT_TASK_DETAIL.replace(
      ":projectId",
      itemObj?.project_id?._id
    ).replace(":id", id);

    navigate(navigateTo);
  }, [itemObj?.project_id?._id, id, navigate]);

  const removeTask = useCallback(() => {
    dispatch(itemActions.onDeleteOneRequest({ _id: id }));
  }, [dispatch, id]);

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        icon: (
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            arrowPointAtCenter={false}
            placement="right"
            onConfirm={removeTask}
          >
            <span style={{ fontSize: 14 }}>
              <DeleteOutlined /> Delete
            </span>
          </Popconfirm>
        ),
      },
    ],
    [removeTask]
  );
  const onClickMenu = useCallback(
    (e) => {
      e.domEvent.stopPropagation();
      const mItem = menuItems.find((item) => item.key === e.key);
      mItem?.onClick?.();
    },
    [menuItems]
  );
  if (!itemObj?._id) {
    return <></>;
  }

  return (
    <>
      <Col className="project-task-kanban-item-container m15">
        {itemObj?.status_description === INITIATE &&
          itemObj?.meta?.history?.length > 0 && (
            <Tooltip title="Sent for Reconsideration" placement="top">
              <Fragment>
                <Badge.Ribbon
                  text="R"
                  placement="start"
                  style={{ zIndex: 3, cursor: "pointer" }}
                  color="volcano"
                />
              </Fragment>
            </Tooltip>
          )}
        {itemObj?.status_description === IN_PROGRESS &&
          itemObj?.meta?.cost &&
          !itemObj?.meta?.isAcceptedByWriter && (
            <Tooltip title="Awaiting Acceptance" placement="top">
              <Fragment>
                <Badge.Ribbon
                  text="A"
                  placement="start"
                  style={{ zIndex: 3, cursor: "pointer" }}
                  color="purple"
                />
              </Fragment>
            </Tooltip>
          )}
        {itemObj?.status_description === APPROVED && (
          <Tooltip title="Closed" placement="top">
            <Fragment>
              <Badge.Ribbon
                text="C"
                placement="start"
                style={{ zIndex: 3, cursor: "pointer" }}
                color="#244F1E"
              />
            </Fragment>
          </Tooltip>
        )}
        <Card
          bordered={false}
          hoverable
          style={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
          }}
        >
          <Col onClick={onProjectTaskDetails}>
            <Col className="id mt5">Task Id - #{itemObj?.task_id}</Col>
            <Tooltip
              title={fields?.[0]?.value?.length > 23 ? fields?.[0]?.value : ""}
            >
              <Col
                style={{ maxWidth: "12em" }}
                className="overflow-ellipse tert-bold"
              >
                {fields?.[0]?.value || "-"}
              </Col>
            </Tooltip>
            {moment(itemObj?.created_at).isValid() && (
              <Col className="deadline mt5">
                <CalendarOutlined className="mr5" />
                Created on -
                {moment(itemObj?.created_at).isValid()
                  ? moment(itemObj?.created_at).format("DD MMM YYYY")
                  : "-"}
                {moment(itemObj?.due_date).isValid() && (
                  <Col className="deadline mt5">
                    <CalendarOutlined className="mr5" />
                    Deadline -
                    {moment(itemObj?.due_date).isValid()
                      ? moment(itemObj?.due_date).format("DD MMM YYYY")
                      : "-"}
                  </Col>
                )}
              </Col>
            )}
            <Col className="mt10" style={{ padding: "auto" }}>
              <Row justify="space-between" align="middle" wrap={false}>
                <Col
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: 100,
                  }}
                >
                  <Col className="deadline mb5">Created By</Col>
                  <NameWithAvatar user={createdBy} showFirstName />
                </Col>
                <Col
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: 150,
                    paddingRight: 10,
                  }}
                >
                  <Col className="deadline mb5">Assigned To</Col>

                  <NameWithAvatar user={assignedFreelancer} showFirstName />
                </Col>
              </Row>
            </Col>
          </Col>
        </Card>
        {itemObj?.status_description !== COMPLETED &&
          itemObj?.status_description !== APPROVED &&
          isProjectEditable && (
            <Dropdown
              overlay={<Menu items={menuItems} onClick={onClickMenu} />}
              placement="bottom"
              trigger={["click"]}
            >
              <MoreOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{
                  fontSize: 20,
                  position: "absolute",
                  top: 20,
                  right: 2,
                  cursor: "pointer",
                }}
              />
            </Dropdown>
          )}
      </Col>
    </>
  );
};

export default React.memo(ProjectTaskItem);
