import { Radio, Row, Skeleton } from "antd";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { graphDataBuilder } from "../../utils/commonFunctions";

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 5,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        gridLines: {
          color: "#0000",
        },
      },
    ],
  },
  pan: {
    enabled: true,
  },
  zoom: {
    enabled: true,
  },
  responsive: true,
};

const ItemGraph = () => {
  const [view, setView] = useState("days");
  const { dashboard, loading } = useSelector(({ dashboard }) => dashboard);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setView(value);
    },
    []
  );

  const mData = useMemo(
    () => dashboard?.item_chart?.[view],
    [dashboard?.item_chart, view]
  );
  const chartData = graphDataBuilder(mData, view);

  return (
    <div className="container pri-box-shadow">
      <div className="title-container pri-box-shadow">
        <Row align="middle" justify="space-between">
          <p className="header-text">Task Created</p>
          <Radio.Group
            className="darg"
            onChange={handleChange("view")}
            value={view}
          >
            <Radio.Button value="days">Daily</Radio.Button>
            <Radio.Button value="week">Weekly</Radio.Button>
            <Radio.Button value="month">Monthly</Radio.Button>
          </Radio.Group>
        </Row>
      </div>
      <div className="project-graph-container">
        {loading ? (
          <Skeleton active={loading} paragraph={{ rows: 5 }} />
        ) : (
          <div className="chart-container">
            <Bar
              data={{
                labels: chartData?.labels,
                datasets: [
                  {
                    label: "Task Created",
                    data: chartData?.data,
                    backgroundColor: "#2A2A2A",
                    barThickness: 15,
                  },
                ],
              }}
              options={options}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemGraph;
