import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Input, Row, Select } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import { ReloadOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import GenerateContentContainer from "./components/GenerateContentContainer";
import MRadioGroup from "../components/MRadioGroup";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import Loading from "../components/Loading";

import { useQuery } from "../utils/customHooks";
import { openAiActions } from "./duck/openAiReducer";

import { arrayToMap, showNotification } from "../utils/commonFunctions";
import appUrl from "../config/appUrl";
import routes from "../utils/routes";
import constants from "../utils/constants";

import "./OpenAi.scss";
import SelectSocialMediaPlatform from "./components/SelectSocialMediaPlatform";

const {
  CONTENT_VOICE_TONES,
  CONTENT_PERSPECTIVES,
  AUTO_GENERATE_CONTENT_TYPES,
  OPEN_AI_TASK_FIELDS,
  SOCIAL_MEDIA_PLATFORMS,
} = constants;

const DEFAULT_STEP = 1;
const MAX_STEPS = 1;

const OpenAiSocialMediaCopy = () => {
  const prevProps = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const openAi = useSelector(({ openAi }) => openAi);
  const mTask = useMemo(() => {
    const taskId = query.get("task_id");
    return openAi.map[taskId] || {};
  }, [query, openAi.map]);

  const [state, setState] = useState({
    currentStep: DEFAULT_STEP,
    platforms: [],
    tone: CONTENT_VOICE_TONES[3],
    contentPerspective: CONTENT_PERSPECTIVES[0],
  });
  const [error, setError] = useState({});
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.isLoadingAll) {
      const { platforms } = state;
      const allPlatformLoading = platforms.map((p) => state[p]?.loading);
      if (allPlatformLoading.every((loading) => !loading)) {
        setState((prevState) => ({
          ...prevState,
          isLoadingAll: false,
          currentStep: prevState.currentStep + 1,
        }));
      }
    }
  }, [state]);

  const hasError = useCallback(() => {
    let { currentStep, topic, tone } = state;
    topic = topic?.trim?.();
    tone = tone?.trim?.();

    const error = {};

    if (currentStep === 1) {
      if (!topic) {
        error.topic = "Please Describe your product or service";
      }

      if (!tone) {
        error.tone = "Please select Tone of the content";
      }
    }

    setError(error);
    return Object.keys(error).length > 0;
  }, [state]);

  const generateContentApi = useCallback(
    (platform = "", temperature) => {
      if (temperature || !hasError()) {
        setState((prevState) => ({
          ...prevState,
          [platform]: Object.assign({}, prevState[platform], {
            loading: true,
          }),
        }));

        let { keywords, topic, usp, tone, currentStep } = state;
        keywords = keywords?.trim?.();
        topic = topic?.trim?.();
        usp = usp?.trim?.();
        tone = tone?.trim?.();

        const mPlatform = SOCIAL_MEDIA_PLATFORMS[platform];
        const mQuery = mPlatform.query;

        let text = `Generate 5 social media copy`;
        if (mQuery?.heading) text = `${text} heading in ${mQuery?.heading}`;
        if (mQuery?.description)
          text = `${text} and description in ${mQuery?.description}`;
        text = `${text} for ${mPlatform?.name} platform.`;

        text = `${text} Use following to generate the output-`;
        if (keywords) text = `${text} keywords: ${keywords},`;
        if (topic) text = `${text} topic: ${topic},`;
        if (usp) text = `${text} usp: ${usp},`;
        if (tone) text = `${text} tone: ${tone}.`;

        const payload = { text };
        if (temperature) payload.temperature = 0.5;
        if (mTask?._id) payload.task_id = mTask?._id;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;
            console.log("generate final content ", resData);

            const choices = resData?.data?.choices;
            const choiceText = choices?.[0]?.text;
            let options = choiceText?.split?.("\n") || [];
            if (Array.isArray(options) && options.length > 0) {
              options = options.filter((option) => option?.trim?.());
              options = options.map((option, i) => {
                if (option.startsWith(`${i + 1}.`)) {
                  option = option?.replace(`${i + 1}.`, "");
                }

                return option?.trim?.();
              });

              options = options.filter((option) => option?.trim?.());
            }

            setResponses((prevResponses) =>
              Object.assign({}, prevResponses, { [currentStep]: resData })
            );
            setState((prevState) => ({
              ...prevState,
              [platform]: Object.assign({}, prevState[platform], {
                options,
                loading: false,
              }),
            }));
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            setState((prevState) => ({
              ...prevState,
              [platform]: Object.assign({}, prevState[platform], {
                loading: false,
              }),
            }));
          });
      }
    },
    [state, mTask, hasError]
  );

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      if (!state.platforms.length) {
        showNotification("error", "Please select platforms");
      } else {
        setState((prevState) => ({
          ...prevState,
          isLoadingAll: true,
        }));

        state.platforms.forEach((platform) => generateContentApi(platform));
      }
    }
  }, [state.platforms, generateContentApi, hasError]);

  const onSelect = useCallback(
    (e) => {
      let value = e?.target?.value ?? e;
      let finalDraft = state.finalDraft || "";

      if (!finalDraft.replace(/<(.|\n)*?>/g, "").trim()) finalDraft = "";
      if (finalDraft) finalDraft = `${finalDraft}<br>`;
      finalDraft = `${finalDraft}<p>${value}</p>`;

      setState((prevState) => ({ ...prevState, finalDraft }));
      setError({});
    },
    [state.finalDraft]
  );

  const onGoBack = useCallback(() => {
    if (state.currentStep <= 1) {
      navigate(-1);
      return;
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  }, [state.currentStep, navigate]);

  useEffect(() => {
    if (
      prevProps.current?.loading &&
      !openAi?.loading &&
      state.isTaskCreating
    ) {
      const searchParams = {
        task_id: openAi?.newCreatedDataId,
      };

      const navigateTo = {
        search: `?${createSearchParams(searchParams)}`,
      };
      navigate(navigateTo, { replace: true });
      setState((prevState) => ({ ...prevState, isTaskCreating: false }));
    } else if (
      prevProps.current?.loading &&
      !mTask?.loading &&
      state.isTaskSaving
    ) {
      if (!mTask?.error) {
        const navigateTo = routes.OPEN_AI_DASHBOARD;
        navigate(navigateTo, { replace: true });
      }
    }

    return () => {
      prevProps.current = {
        loading: openAi?.loading || mTask?.loading,
        isTaskCreating: state.isTaskCreating,
        isTaskSaving: state.isTaskSaving,
      };
    };
  }, [
    openAi?.newCreatedDataId,
    openAi?.loading,
    state?.isTaskCreating,
    state?.isTaskSaving,
    mTask?.loading,
    mTask?.error,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    const taskId = query.get("task_id");
    if (!taskId) {
      setState((prevState) => ({ ...prevState, isTaskCreating: true }));
      const payload = {
        fields: [...OPEN_AI_TASK_FIELDS],
      };
      payload.fields.forEach((field) => {
        if (field._id === "content_type") {
          field.value = AUTO_GENERATE_CONTENT_TYPES.SOCIAL_MEDIA_COPY.name;
        }
      });
      dispatch(openAiActions.onCreateOneRequest(payload));
    } else {
      dispatch(openAiActions.onGetOneRequest({ _id: taskId }));
    }
  }, [query, dispatch]);

  const handleShowOpenAiTaskModal = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
    }));
  }, []);

  const onSave = useCallback(
    (fields = []) => {
      const { _id } = mTask;

      const payload = {
        _id: _id,
        name: "",
        fields: [],
        draft: false,
      };

      const fieldMap = Object.assign(
        {},
        arrayToMap(mTask?.fields),
        arrayToMap(fields)
      );
      payload.fields = mTask?.fields?.map((field) => {
        const newField = Object.assign({}, field, fieldMap[field?._id]);
        if (newField?._id === "content_type") {
          newField.value = AUTO_GENERATE_CONTENT_TYPES.SOCIAL_MEDIA_COPY.name;
        } else if (newField?._id === "task_name") {
          payload.name = newField.value;
        } else if (newField?._id === "content") {
          newField.value = state.finalDraft;
        }

        return newField;
      });

      setState((prevState) => ({
        ...prevState,
        showOpenAiTaskModal: false,
        isTaskSaving: true,
      }));
      dispatch(openAiActions.onUpdateOneRequest(payload));
    },
    [mTask, state, dispatch]
  );

  if (
    (!openAi.newCreatedDataId && openAi.loading) ||
    state.isTaskCreating ||
    (!mTask?._id && mTask?.loading)
  ) {
    return <Loading />;
  }

  return (
    <GenerateContentContainer
      header={
        state.currentStep === 1 && (
          <Col>
            <Col className="text-2xl font-semibold mb-4">
              Choose Your Platform
            </Col>

            <Col className="w-full">
              <SelectSocialMediaPlatform onChange={handleChange("platforms")} />
            </Col>
          </Col>
        )
      }
    >
      <Row className="content-container">
        {state.currentStep === 1 && (
          <Row className="fw" justify="space-between">
            <Col className="--left-container" span={24}>
              <Col className="title-container mb-4">
                <Row align="middle">
                  <LeftOutlined className="text-2xl mr-3" onClick={onGoBack} />

                  <Col className="title">Generate Your Content</Col>
                </Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">
                    Add your primary or secondary keywords
                  </label>
                </Col>

                <Input
                  className="input input-area"
                  placeholder="Add your primary or secondary keywords"
                  value={state.keywords}
                  onChange={handleChange("keywords")}
                  size="large"
                  disabled={state.isLoadingAll}
                />
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">
                    Describe your product or service
                  </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Input.TextArea
                  className="input input-area"
                  placeholder="Let us know your topic, brand here"
                  value={state.topic}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={handleChange("topic")}
                  size="large"
                  disabled={state.isLoadingAll}
                />

                <Row className="error mt5">{error.topic}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">
                    What is the USP of your product or service
                  </label>
                </Col>

                <Input
                  className="input input-area"
                  placeholder="What is the USP of your product or service"
                  value={state.usp}
                  onChange={handleChange("usp")}
                  size="large"
                  disabled={state.isLoadingAll}
                />

                <Row className="error mt5">{error.usp}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">Select tone of the content</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Select
                  className="custom-select fw"
                  placeholder="Select tone of the content"
                  value={state.tone}
                  onChange={handleChange("tone")}
                  size="large"
                  disabled={state.isLoadingAll}
                >
                  {CONTENT_VOICE_TONES.map((tone, i) => (
                    <Select.Option key={i} title={tone} value={tone} />
                  ))}
                </Select>

                <Row className="error mt5">{error.tone}</Row>
              </Col>

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw mt15"
                    type="primary"
                    loading={state.isLoadingAll}
                    onClick={onSubmit}
                    size="large"
                  >
                    Generate Content
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {state.currentStep === 2 && (
          <Row className="fw" justify="space-between">
            <Col
              className="--left-container"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={11}
              xxl={11}
            >
              {state.platforms.map((platform, i) => {
                const mPlatform = SOCIAL_MEDIA_PLATFORMS[platform];
                const options = state[platform]?.options || [];
                const isLoading = state[platform]?.loading;

                if (!mPlatform) return null;

                return (
                  <Col className="mb-24">
                    <Col className="title-container mb-4">
                      <Row align="middle">
                        {i === 0 && (
                          <LeftOutlined
                            className="text-2xl mr-3"
                            onClick={onGoBack}
                          />
                        )}

                        <Col className="title">
                          <img
                            className="social-media-logo h-7 w-7 mr-3"
                            src={mPlatform.logo}
                            alt={mPlatform?.name}
                          />

                          <span>{mPlatform.name}</span>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="mb15">
                      <MRadioGroup values={options} onChange={onSelect} />
                    </Col>

                    <Col className="mt15">
                      <Button
                        className="fw"
                        type="primary"
                        disabled={isLoading}
                        onClick={() => generateContentApi(platform, 0.5)}
                        size="large"
                      >
                        <Row align="middle" justify="center">
                          <ReloadOutlined className="mr-2.5" spin={isLoading} />
                          Regenerate
                        </Row>
                      </Button>
                    </Col>
                  </Col>
                );
              })}
            </Col>

            <Col
              className="--right-container"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={11}
              xxl={11}
            >
              <Col className="sticky top-0">
                <Col className="h-96 mb-16">
                  <ReactQuill
                    className="input-text-editor h-full"
                    value={state.finalDraft}
                    onChange={handleChange("finalDraft")}
                    theme="snow"
                  />
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Row
                    className="w-full"
                    align="middle"
                    justify="space-between"
                  >
                    {mTask?._id && (
                      <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                        <Button
                          className="w-full"
                          type="success"
                          size="large"
                          loading={mTask?.loading}
                          onClick={() => handleShowOpenAiTaskModal(true)}
                        >
                          {mTask?.loading ? "Saving" : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Col>
            </Col>
          </Row>
        )}
      </Row>

      <OpenAiTaskModal
        visible={state.showOpenAiTaskModal}
        taskId={mTask?._id}
        fields={mTask?.fields?.filter((field) => field?._id === "task_name")}
        onSubmit={onSave}
        handleModal={handleShowOpenAiTaskModal}
      />
    </GenerateContentContainer>
  );
};

export default React.memo(OpenAiSocialMediaCopy);
