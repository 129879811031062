import { Input, Row, Button, Col } from "antd";
import React, { useState, useCallback } from "react";
import logo from "../assets/images/writelyForRequestor.svg";
import "./login.scss";
import axios from "axios";
import {
  validPassword,
  validEmail,
  showNotification,
} from "../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import routes from "../utils/routes";
import AppUrl from "../config/appUrl";
import constants from "../utils/constants";
// import OtsuGoogle from "./OtsuGoogle";
import appUrl from "../config/appUrl";
import { loggedInUserActions } from "../loggedInUser/duck/loggedInUserReducer";
import { useDispatch } from "react-redux";
import GoogleSignInBtn from "./component/GoogleSignInBtn";

const ClientSignup = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
    first_name: "",
  });
  const [error, setError] = useState({ email: null, password: null });

  const hasError = useCallback(() => {
    const { email, password, first_name } = state;
    let error = {};

    if (!email?.trim()) {
      error.email = "Email cannot be blank";
    } else if (!validEmail(email)) {
      error.email = "Email is not valid";
    }
    if (!password?.trim()) {
      error.password = "Password cannot be blank";
    } else if (!validPassword(password)) {
      error.password = "Password length should be of minimum 4 characters";
    }
    if (!first_name?.trim()) {
      error.first_name = "Please enter your name";
    }

    setError(error);

    return !!Object.keys(error)?.length;
  }, [state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((prestate) => ({
        ...prestate,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  const handleSignupRequest = useCallback(async () => {
    setSubmitLoading(true);
    try {
      const userRes = await axios.post(AppUrl.SIGNUP_URL, {
        email: state?.email,
        password: state?.password,
        first_name: state?.first_name,
        role_id: constants.ROLE_IDS.CLIENT,
      });
      let userData = userRes.data;
      if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
        showNotification("error", userData?.meta?.message);
        setSubmitLoading(false);
      } else {
        showNotification(
          "success",
          "An activation link has been sent to your mail, please verify your account."
        );
        navigate(routes.LOGIN);
      }
    } catch (error) {
      console.log("error", error);
      setSubmitLoading(false);
    }
  }, [navigate, state?.email, state?.first_name, state?.password]);

  const handleEmailSubmit = useCallback(() => {
    if (!hasError()) {
      handleSignupRequest();
    }
  }, [handleSignupRequest, hasError]);

  const submitGoogleCredentials = async (accessToken) => {
    let credentials = {
      accessToken: accessToken,
    };
    setLoadingGoogle(true);
    const loginRequest = (data) => {
      return axios({
        method: "POST",
        url: appUrl.GOOGLE_LOGIN_URL,
        mode: "cors",
        data: {
          access_token: accessToken,
          role_id: constants.ROLE_IDS.CLIENT,
        },
      });
    };

    const response = await loginRequest(credentials);

    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      dispatch(loggedInUserActions.onHandleError(data));
    } else {
      const { token } = data.data;
      localStorage.setItem(constants.ACCESS_TOKEN, token);

      navigate(routes.DASHBOARD, {
        state: { autoNavigate: true },
        replace: true,
      });
    }
    setLoadingGoogle(false);
  };

  return (
    <div className="loginContainer">
      <div className="logo-container pointer">
        <img src={logo} alt="Writely" className="logo" />
      </div>

      <div className="container-step-1">
        <Row className="info mt20 mb20">Create your requestor account!</Row>

        <Row className="input-container mb20">
          <label>Name</label>
          <Input
            className="fw"
            placeholder="Enter your name"
            size="large"
            onChange={handleChange("first_name")}
            onPressEnter={handleEmailSubmit}
            value={state?.first_name}
            disabled={submitLoading}
          />
          <Row className="error">{error?.first_name}</Row>
        </Row>
        <Row className="input-container mb20">
          <label>Email</label>
          <Input
            className="fw"
            placeholder="Enter your email address"
            size="large"
            onChange={handleChange("email")}
            onPressEnter={handleEmailSubmit}
            value={state?.email}
            disabled={submitLoading}
          />
          <Row className="error">{error?.email}</Row>
        </Row>

        <Row className="input-container mb20">
          <Col span={24}>
            <Row justify="space-between">
              <label>Password</label>
            </Row>
          </Col>
          <Input
            type="password"
            placeholder="Create a strong password"
            size="large"
            onChange={handleChange("password")}
            value={state?.password}
            onPressEnter={handleEmailSubmit}
            disabled={submitLoading}
          />

          <Row className="error">{error?.password}</Row>
        </Row>

        {/* Buttons Container Start */}
        <Button
          type="primary"
          block
          onClick={handleEmailSubmit}
          loading={submitLoading}
          size="large"
          className="signup-btn mb20"
        >
          Join
        </Button>
        <Row justify="center" className="mb20 bold">
          Or
        </Row>
        <Row className="mb20">
          <GoogleSignInBtn
            text="Join with Google"
            loading={isLoadingGoogle}
            onSignInSuccess={submitGoogleCredentials}
          />

          {/* <OtsuGoogle
            isLoadingGoogle={isLoadingGoogle}
            valueText={"Join with Google"}
            submitGoogleCredentials={submitGoogleCredentials}
          /> */}
        </Row>

        {/* Buttons Container End */}
      </div>
    </div>
  );
};

export default ClientSignup;
