import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  Radio,
  Rate,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  EditOutlined,
  PlusOutlined,
  LeftOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";
import newTab from "../assets/images/newTab.svg";
import TaskChat from "../task/components/TaskChat";
import NotFound from "../components/NotFound";
import Loading from "../components/Loading";

import { itemActions } from "../item/duck/itemReducer";

import {
  formatNumber,
  getStringToOptions,
  showNotification,
} from "../utils/commonFunctions";
import constants from "../utils/constants";
import User from "../models/User";
import Field from "../models/Field";

// import "./ProjectTaskDetails.scss";
import "./ContentTaskDetails.css";
import InputField from "../components/InputField";
import { useRef } from "react";
import NameWithAvatar from "../components/NameWithAvatar";
import { statusTransformer } from "../components/StatusTransformer";

import routes from "../utils/routes";
import SelectFreelancerModal from "../project/components/SelectFreelancerModal";
import TaskAcceptanceModal from "../project/components/TaskAcceptanceModal";
import TaskRatingModel from "../project/components/TaskRatingModel";
import Modal from "./components/Modal";
import ContentModal from "./components/Modal";

const {
  PROJECT_TASK_STATUS,
  PROJECT_STATUS,
  ROLE_IDS,
  CURRENCIES,
  CONTENT_TYPE,
} = constants;
const {
  INITIATE,
  ASSIGNED,
  IN_PROGRESS,
  IN_REVIEW,
  REWORK,
  COMPLETED,
  APPROVED,
} = PROJECT_TASK_STATUS;
const FieldTypes = Field.TYPES;

const DefaultCurrency = CURRENCIES.INR._id;

const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const ContentTaskDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevProp = useRef();

  const { projectId, id } = useParams();

  const [showSelectFreelancer, setShowSelectFreelancer] = useState(false);
  const [showAcceptanceModal, setShowAcceptanceModal] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [state, setState] = useState({});
  const [error, setError] = useState({});
  const [tab, setTab] = useState("task_brief");
  const [recentStatus, setRecentStatus] = useState("");
  const [disableTaskEdit, setDisableTaskEdit] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);

  const projectObj = useSelector(({ project }) => project.map[projectId]);

  const projectTemplateForms = projectObj?.template?.forms;

  const itemObj = useSelector(({ item }) => item.map[id]);

  const createdBy = useMemo(
    () => new User().fromMap(itemObj?.created_by),
    [itemObj?.created_by]
  );

  const assignedFreelancer = useMemo(
    () => new User().fromMap(itemObj?.assigned_freelancer),
    [itemObj?.assigned_freelancer]
  );
  const assignedManager = useMemo(
    () => new User().fromMap(projectObj?.assigned_manager),
    [projectObj?.assigned_manager]
  );
  const assignedClient = useMemo(
    () => new User().fromMap(projectObj?.assigned_client),
    [projectObj?.assigned_client]
  );

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;
      if (name === "cost") {
        value = value.replace(/[^0-9.]/g, "");
      }
      if (name === "3") {
        value = value.replace(/[^0-9]/g, "");
      }

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const isValidContentType = useCallback(
    (showAlert = true) => {
      const contentType = state?.[2];

      const isValid = getStringToOptions(CONTENT_TYPE)
        .map((i) => i.toLocaleLowerCase())
        ?.includes(contentType?.toLocaleLowerCase());
      if (!isValid && showAlert) {
        showNotification(
          "error",
          "The selected content type is not valid. Please select from the list to proceed."
        );
      }
      return isValid;
    },
    [state]
  );

  const hasError = useCallback(() => {
    const { cost, cost_type, currency } = state;
    const error = {};

    itemObj?.fields?.forEach((field, i) => {
      const vlaue = state[i]?.trim?.() ?? state[i];
      if (field.mandatory && !vlaue) {
        error[i] = field.validation;
        setTab("task_brief");
      }
      if (i === 2 && vlaue && !isValidContentType(false)) {
        error[i] = "Invalid Content Type";
        setTab("task_brief");
      }
    });

    const perWordLimit = CURRENCIES?.[currency]?.PER_WORD_LIMIT;
    const perContentLimit = CURRENCIES?.[currency]?.PER_CONTENT_LIMIT;

    if (!cost) {
      error.cost = "Please enter cost for the task";
    } else if (Number(state.cost) <= 0) {
      error.cost = "Cost must be greater than 0";
    } else if (cost_type === "per_word" && cost > perWordLimit) {
      error.cost = `Per word cost cannot be greater than ${perWordLimit}`;
    } else if (cost_type === "per_content" && cost > perContentLimit) {
      error.cost = `Per content cost cannot be greater than ${perContentLimit}`;
    }

    if (error?.cost) setTab("cost");

    setError(error);

    return !!Object.keys(error).length;
  }, [isValidContentType, itemObj?.fields, state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const fields = itemObj?.fields?.map((field, i) => ({
        ...field,
        value: state[i] ?? "",
      }));

      const payload = {
        _id: id,
        fields,
        meta: {
          ...itemObj?.meta,
          cost_type: state?.cost_type ?? "per_word",
          cost: Number(state?.cost),
          currency: state?.currency,
        },
      };
      dispatch(itemActions.onUpdateOneRequest(payload));
    }
  }, [hasError, itemObj?.fields, itemObj?.meta, id, state, dispatch]);

  //   useEffect(() => {
  //     if (!projectObj) {
  //       dispatch(projectActions.onGetOneRequest({ _id: projectId }));
  //     } else if (!itemObj) {
  //       dispatch(itemActions.onGetOneRequest({ _id: id }));
  //     }
  //   }, [dispatch, projectId, id, itemObj, projectObj]);

  useEffect(() => {
    if (itemObj) {
      const fields = {};
      itemObj?.fields?.forEach((field, i) => {
        fields[i] = field?.value ?? "";
      });

      if (!itemObj?.loading) {
        setState({
          ...fields,
          cost_type: itemObj?.meta?.cost_type,
          cost: Number(itemObj?.meta?.cost),
          currency: itemObj?.meta?.currency ?? DefaultCurrency,
        });
        setError({});
      }
    }
  }, [itemObj, disableTaskEdit]);

  useEffect(() => {
    if (prevProp?.current?.loading && !itemObj?.loading) {
      setDisableTaskEdit(true);
      if (recentStatus === COMPLETED && !showRatingModal) {
        setShowRatingModal(true);
      }
    }

    return () => {
      prevProp.current = { loading: itemObj?.loading };
    };
  }, [itemObj?.loading, recentStatus, showRatingModal]);
  const onGoBack = useCallback(() => navigate(-1), [navigate]);
  const assignedTo = useMemo(
    () =>
      itemObj?.current_assignee
        ? new User().fromMap(itemObj?.current_assignee)
        : assignedManager,
    [assignedManager, itemObj?.current_assignee]
  );

  const projectBriefFieldValue = useCallback((type, value) => {
    switch (type) {
      case FieldTypes.SELECT:
      case FieldTypes.DROPDOWN:
        return (
          <Col>
            {getStringToOptions(value).map((option, i) => (
              <Tag key={i}>{option}</Tag>
            ))}
          </Col>
        );

      case FieldTypes.TEXTAREA:
        return (
          <Col
            className="value"
            dangerouslySetInnerHTML={{
              __html: value || "-",
            }}
          />
        );

      default:
        return value || "-";
    }
  }, []);

  const onUpdateAssignmentStatus = useCallback(
    (status, current_assignee) => {
      const payload = {
        _id: id,
        status_description: status,
        current_assignee,
      };
      setRecentStatus(status);
      dispatch(itemActions.onUpdateOneRequest(payload));
    },
    [dispatch, id]
  );

  const canAssignFreelancer = useMemo(
    () =>
      loggedInUser?.role_id === ROLE_IDS.SUPER_ADMIN ||
      loggedInUser?.role_id === ROLE_IDS.ADMIN ||
      loggedInUser?.role_id === ROLE_IDS.MANAGER ||
      loggedInUser?.role_id === ROLE_IDS.EDITOR,
    [loggedInUser?.role_id]
  );

  const canSeeTaskAcceptanceModal = useMemo(() => {
    let show = false;
    if (
      itemObj?.status_description === IN_PROGRESS &&
      itemObj?.meta?.cost &&
      !itemObj?.meta?.isAcceptedByWriter
    )
      show = true;

    switch (loggedInUser?.role_id) {
      case ROLE_IDS.CLIENT:
      case ROLE_IDS.EDITOR:
        show = false;
        break;

      default:
        break;
    }
    return show;
  }, [
    itemObj?.meta?.cost,
    itemObj?.meta?.isAcceptedByWriter,
    itemObj?.status_description,
    loggedInUser?.role_id,
  ]);
  const canSeeBudget = useMemo(() => {
    let show = false;

    switch (loggedInUser?.role_id) {
      case ROLE_IDS.ADMIN:
      case ROLE_IDS.SUPER_ADMIN:
        show = true;
        break;

      default:
        break;
    }
    return show;
  }, [loggedInUser?.role_id]);

  useEffect(() => {
    if (canSeeTaskAcceptanceModal) setShowAcceptanceModal(true);
  }, [canSeeTaskAcceptanceModal]);

  const canSeeCost = useMemo(() => {
    let show;
    switch (loggedInUser?.role_id) {
      case ROLE_IDS.SUPER_ADMIN:
      case ROLE_IDS.ADMIN:
      case ROLE_IDS.CLIENT:
      case ROLE_IDS.MANAGER:
        show = true;
        break;

      default:
        show = false;
        break;
    }
    if (!itemObj?.meta?.cost_type) show = false;
    return show;
  }, [itemObj?.meta?.cost_type, loggedInUser?.role_id]);

  const prefixSelector = useMemo(
    () => (
      <Select
        style={{ width: 130, border: "none" }}
        value={state?.currency}
        suffixIcon={<CaretDownOutlined className="f15" />}
        onChange={handleChange("currency")}
        disabled={itemObj?.loading}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {Object.values(CURRENCIES).map((currObj) => (
          <Select.Option key={currObj._id} value={currObj._id}>
            {currObj._id} ({currObj.SYMBOL})
          </Select.Option>
        ))}
      </Select>
    ),

    [handleChange, itemObj?.loading, state?.currency]
  );

  const costObject = useMemo(() => {
    let costType = "-";
    if (itemObj?.meta?.cost_type) {
      costType =
        itemObj?.meta?.cost_type === "per_content" ? "Per Content" : "Per Word";
    }
    const cost = formatNumber(itemObj?.meta?.cost);
    const currency = itemObj?.meta?.currency ?? DefaultCurrency;

    const currencySymbol = CURRENCIES?.[currency]?.SYMBOL;
    // margin set to 0 as per client demand
    let marginPercent = 0;

    if (
      typeof projectObj?.meta?.skipMargin === "boolean" &&
      projectObj?.meta?.skipMargin
    ) {
      marginPercent = 0;
    }

    const marginAmount = (cost * marginPercent) / 100;
    const budgetAmount = formatNumber(cost - marginAmount);
    const consumedBudget = itemObj?.meta?.allottedAmountToWriter ?? 0;
    const additionalMargin = formatNumber(budgetAmount - consumedBudget);

    return {
      cost,
      costType,
      currencySymbol,
      marginPercent,
      marginAmount,
      budgetAmount,
      consumedBudget,
      additionalMargin,
    };
  }, [
    itemObj?.meta?.allottedAmountToWriter,
    itemObj?.meta?.cost,
    itemObj?.meta?.cost_type,
    itemObj?.meta?.currency,
    projectObj?.meta?.skipMargin,
  ]);
  const isProjectEditable = useMemo(() => {
    let editable = true;

    if (
      projectObj?.archive ||
      projectObj?.status_description === PROJECT_STATUS.CLOSED._id
    ) {
      editable = false;
    }
    return editable;
  }, [projectObj?.archive, projectObj?.status_description]);

  const canApproveTask = useMemo(() => {
    let show = false;

    if (
      assignedFreelancer._id &&
      (loggedInUser?.role_id === ROLE_IDS.SUPER_ADMIN ||
        loggedInUser?.role_id === ROLE_IDS.ADMIN ||
        assignedFreelancer._id === loggedInUser?._id)
    ) {
      if (
        itemObj?.status_description === ASSIGNED ||
        itemObj?.status_description === IN_PROGRESS
      ) {
        show = true;
      }
    }

    if (itemObj?.status_description === IN_REVIEW) {
      if (
        loggedInUser?.role_id === ROLE_IDS.SUPER_ADMIN ||
        loggedInUser?.role_id === ROLE_IDS.ADMIN ||
        projectObj?.assigned_manager?._id === loggedInUser?._id ||
        projectObj?.created_by?._id === loggedInUser?._id
      ) {
        show = true;
      }
    }
    show = isProjectEditable;

    return show;
  }, [
    assignedFreelancer._id,
    loggedInUser?.role_id,
    loggedInUser?._id,
    itemObj?.status_description,
    isProjectEditable,
    projectObj?.assigned_manager?._id,
    projectObj?.created_by?._id,
  ]);

  // check if task is fetching
  if (!itemObj?._id && itemObj?.loading) {
    return <Loading />;
  }
  // check if task exist
  else if (!itemObj?._id) {
    return <NotFound subTitle={"Sorry, this project task does not exist."} />;
  }

  const genExtra = () => (
    <EditOutlined
      onClick={(event) => {
        setShowModal(true);

        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );
  return (
    <div className="content-body app-container project-task-details-page">
      <div className="header-container">
        <Row>
          <Col className="mt10" xs={2} sm={2} md={2} lg={2} xl={2} xxl={1}>
            <Row justify="center">
              <Link className="color-primary" to={routes.INVOICE_WRITER}>
                <LeftOutlined
                  className="text-2xl pointer"
                  /* onClick={onGoBack} */
                />
              </Link>
            </Row>
          </Col>

          <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={23}>
            <Row gutter={[10, 20]}>
              <Col
                className="task-summary-container"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={canApproveTask ? 18 : 24}
                xxl={canApproveTask ? 20 : 24}
              >
                <Row className="page-heading">
                  <Tooltip title={itemObj?.fields?.[0]?.value}>
                    <span className="line-clamp-2">
                      {itemObj?.fields?.[0]?.value}
                    </span>
                  </Tooltip>
                </Row>

                <Row className="sub-header">
                  {itemObj?.task_id && (
                    <>Task Id - #{itemObj?.task_id} &nbsp;</>
                  )}

                  {moment(itemObj?.created_at).isValid() ? (
                    <>
                      | &nbsp;
                      {`Created On - ${moment(itemObj?.created_at).format(
                        "MMM Do, YYYY"
                      )}`}
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}

                  {moment(itemObj?.due_date).isValid() ? (
                    <>
                      | &nbsp;
                      <CalendarOutlined className="mr5 f15" />
                      {`Deadline ${moment(itemObj?.due_date).format(
                        "DD MMM YYYY"
                      )}`}
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="gap-2.5 mt-6">
                  <Row align="middle">
                    <span className="low-opacity mr-2">Created By:</span>

                    <NameWithAvatar user={createdBy} showToolTip />
                  </Row>

                  <Row align="middle">
                    <span className="low-opacity mr-2">Assigned To:</span>

                    <NameWithAvatar user={assignedTo} showToolTip />
                  </Row>

                  <Row align="middle">
                    <span className="low-opacity mr-2">Assigned Writer:</span>

                    {assignedFreelancer._id ? (
                      <Row align="middle" justify="center">
                        <NameWithAvatar
                          user={assignedFreelancer}
                          onClick={() => {
                            if (
                              itemObj?.status_description !== COMPLETED &&
                              itemObj?.status_description !== APPROVED
                            ) {
                              setShowSelectFreelancer(true);
                            }
                          }}
                          showToolTip
                        />
                      </Row>
                    ) : (
                      <Tooltip
                        title={canAssignFreelancer ? "Assign Freelancer" : ""}
                      >
                        <Avatar
                          className="btn-select-freelancer pointer"
                          icon={
                            <PlusOutlined style={{ backgroundColor: "" }} />
                          }
                          onClick={() => {
                            if (canAssignFreelancer && isValidContentType())
                              setShowSelectFreelancer(true);
                          }}
                        />
                      </Tooltip>
                    )}
                  </Row>
                </Row>
              </Col>

              <Col
                className="action-btn-container"
                xs={24}
                sm={20}
                md={18}
                lg={16}
                xl={canApproveTask ? 6 : 0}
                xxl={canApproveTask ? 4 : 0}
              >
                {canApproveTask && (
                  <Row className="h-full fw" align="middle">
                    {itemObj?.status_description === IN_REVIEW &&
                      loggedInUser?.role_id !== "freelancer" && (
                        <Row className="fw" gutter={[10, 10]}>
                          {/* re-assign project task btn start */}
                          <Col xs={12} xl={24}>
                            <Button
                              className="fw"
                              type="primary-outline"
                              size="large"
                              onClick={() =>
                                onUpdateAssignmentStatus(
                                  REWORK,
                                  assignedFreelancer._id
                                )
                              }
                              loading={
                                recentStatus === REWORK && itemObj?.loading
                              }
                            >
                              Rework
                            </Button>
                          </Col>
                          {/* re-assign project task btn end */}

                          {/* approved project task btn satrt */}
                          <Col xs={12} xl={24}>
                            <Button
                              className="fw"
                              type="primary"
                              size="large"
                              onClick={() =>
                                onUpdateAssignmentStatus(
                                  COMPLETED,
                                  assignedClient._id || createdBy._id
                                )
                              }
                              loading={
                                recentStatus === COMPLETED && itemObj?.loading
                              }
                            >
                              Approve
                            </Button>
                          </Col>
                          {/* approved project task btn end */}
                        </Row>
                      )}

                    {itemObj?.status_description === COMPLETED &&
                      loggedInUser?.role_id !== "freelancer" && (
                        <Row className="fw" gutter={[10, 10]}>
                          {/* re-assign completed project task btn start */}
                          <Col xs={12} xl={24}>
                            <Button
                              className="new_button fw"
                              size="large"
                              type="primary-outline"
                              onClick={() =>
                                onUpdateAssignmentStatus(
                                  IN_REVIEW,
                                  projectObj?.assigned_editor?._id
                                )
                              }
                              loading={
                                recentStatus === IN_REVIEW && itemObj?.loading
                              }
                            >
                              Rework
                            </Button>
                          </Col>
                          {/* re-assign project task btn end */}

                          {/* approved project task btn satrt */}
                          <Col xs={12} xl={24}>
                            <Button
                              className="new_button fw"
                              type="primary"
                              size="large"
                              onClick={() =>
                                onUpdateAssignmentStatus(
                                  APPROVED,
                                  createdBy._id
                                )
                              }
                              loading={
                                recentStatus === APPROVED && itemObj?.loading
                              }
                            >
                              Accept
                            </Button>
                          </Col>
                          {/* approved project task btn end */}
                        </Row>
                      )}
                    {/* re-assign completed project task btn end */}

                    {canSeeTaskAcceptanceModal && (
                      <Row className="test fw">
                        <Col xs={12} xl={24}>
                          <Button
                            className="new_button fw"
                            type="primary"
                            size="large"
                            onClick={() => setShowAcceptanceModal(true)}
                          >
                            Accept Cost
                          </Button>
                        </Col>
                      </Row>
                    )}

                    {/* start project task btn start */}
                    {itemObj?.status_description === IN_PROGRESS &&
                      (!itemObj?.meta?.cost ||
                        itemObj?.meta?.isAcceptedByWriter ||
                        !canSeeTaskAcceptanceModal) && (
                        <Row className="fw">
                          <Col xs={12} xl={24}>
                            <Button
                              className="new_button fw"
                              type="primary"
                              size="large"
                              onClick={() =>
                                onUpdateAssignmentStatus(
                                  IN_REVIEW,
                                  projectObj?.assigned_editor?._id ||
                                    itemObj?.project_id?.assigned_editor
                                )
                              }
                              loading={itemObj?.loading}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      )}
                    {/* start project task btn end */}
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="details-continer">
        <div className="header view-buttons">
          <Row justify="space-between" wrap={false}>
            <Radio.Group
              onChange={(e) => setTab(e?.target?.value)}
              defaultValue={"overview"}
              value={tab}
            >
              <Radio.Button value={"task_brief"}>Task Brief</Radio.Button>

              <Radio.Button value={"project_brief"}>Project Brief</Radio.Button>
              {canSeeCost && <Radio.Button value={"cost"}>Cost</Radio.Button>}
              <Radio.Button value={"testing"}>Testing Button</Radio.Button>
            </Radio.Group>
            <Row className="mr20" align="middle">
              {itemObj?.status_description === INITIATE && isProjectEditable ? (
                <Row gutter={[10, 10]} align="middle">
                  {!disableTaskEdit && (
                    <>
                      <Button
                        size="large"
                        className="mr15 new_button"
                        type="primary"
                        loading={itemObj?.loading}
                        onClick={onSubmit}
                      >
                        Update
                      </Button>
                      <Button
                        className="new_button mr15"
                        size="large"
                        type="primary-outline"
                        onClick={() => setDisableTaskEdit(!disableTaskEdit)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {/* <Button
                          className="new_button"
                          size="large"
                          type="primary-outline"
                          onClick={() => setDisableTaskEdit(!disableTaskEdit)}
                        >
                          {disableTaskEdit ? "Edit" : "Cancel"}
                        </Button> */}
                  {disableTaskEdit && (
                    <EditOutlined
                      //   onClick={() => setDisableTaskEdit(!disableTaskEdit)}
                      className="f20 mr20"
                    />
                  )}
                </Row>
              ) : (
                <a
                  className="google-doc-link f15 text-gray-800"
                  style={{ height: 30, padding: 5 }}
                  href={`https://docs.google.com/document/d/${itemObj?.doc}`}
                  target="blank"
                >
                  Content Link
                  <img className="ml5 mb5" width={14} src={newTab} alt="" />
                </a>
              )}
            </Row>
          </Row>
        </div>
        <div className="body">
          {/* Task brief start */}
          {tab === "task_brief" && (
            <Col className="tab-panel-container">
              {/* details section start */}
              {itemObj?.fields?.map?.((field, i) => (
                <Col key={i} className="mb15">
                  {disableTaskEdit ? (
                    <>
                      <Col className="label">{field.label}</Col>
                      {field?.type === FieldTypes.TEXTAREA ? (
                        <Col
                          className="value"
                          dangerouslySetInnerHTML={{
                            __html: field.value || "-",
                          }}
                        />
                      ) : (
                        <Col className="value">{field.value || "-"}</Col>
                      )}
                    </>
                  ) : (
                    <InputField
                      key={i}
                      {...field}
                      value={state[i]}
                      error={error[i]}
                      onChange={handleChange(`${i}`)}
                      disabled={itemObj?.loading}
                    />
                  )}
                </Col>
              ))}
              {/* details section  end */}
            </Col>
          )}
          {/* Task brief end */}

          {/* Content link start */}
          {/* {tab === "content_link" && (
              <Col className="tab-panel-container">
                <Col className="value mb5">Google Doc</Col>
  
                {itemObj?.doc && (
                  <a
                    className="google-doc-link"
                    href={`https://docs.google.com/document/d/${itemObj?.doc}`}
                    target="blank"
                  >
                    {`https://docs.google.com/document/d/${itemObj?.doc}`}
                  </a>
                )}
              </Col>
            )} */}
          {/* Content link end */}

          {/* Project brief start */}
          {tab === "project_brief" && (
            <Col className="tab-panel-container">
              {/* project details section start */}
              {projectTemplateForms?.map?.((form, i) =>
                form?.fields?.map?.((field, j) => (
                  <Col key={`${i}_${j}`} className="mb15">
                    <Col className="label mb5">{field.label}</Col>
                    {projectBriefFieldValue(field.type, field.value)}
                  </Col>
                ))
              )}
              {/* project details section  end */}
            </Col>
          )}
          {/* Project brief end */}

          {/* cost start */}
          {tab === "cost" && canSeeCost && (
            <Col className="tab-panel-container">
              {disableTaskEdit ? (
                <>
                  <Col className="mb15">
                    <Col className="label">Cost Type</Col>
                    <Tag>{costObject?.costType ?? "-"}</Tag>
                  </Col>
                  <Col className="mb15">
                    <Col className="label">Cost</Col>
                    <Col className="value">
                      {costObject?.currencySymbol} {costObject?.cost || 0}
                    </Col>
                  </Col>
                  {canSeeBudget && (
                    <>
                      <Col className="mb15">
                        <Col className="label">Margin %</Col>
                        <Col className="value">
                          {costObject?.marginPercent || 0}%
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Margin Amount</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.marginAmount || 0}
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Budget</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.budgetAmount || 0}
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Consumed Budget</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.consumedBudget || 0}
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Additional Margin</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.additionalMargin || 0}
                        </Col>
                      </Col>
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* Cost Type Start */}
                  <Col className="input-field-container ">
                    <Col className="mb5">
                      <label className="label">Cost Type</label>
                    </Col>

                    <Col className="input-container fw">
                      <Radio.Group
                        onChange={handleChange("cost_type")}
                        defaultValue={"per_word"}
                        value={state?.cost_type}
                        disabled={itemObj?.loading}
                      >
                        <Radio value={"per_word"}>Per Word</Radio>
                        <Radio value={"per_content"}>Per Content</Radio>
                      </Radio.Group>
                    </Col>
                  </Col>
                  {/* Cost Type End */}

                  {/* Cost  Start */}
                  <Col className="input-field-container mt20">
                    <Col className="mb5">
                      <label className="label">Cost</label>
                      <sup style={{ color: "red" }}>*</sup>
                    </Col>

                    <Col className="input-container fw">
                      <Input
                        type="text"
                        placeholder="Enter Cost"
                        className="fw"
                        value={state?.cost}
                        disabled={itemObj?.loading}
                        addonBefore={prefixSelector}
                        onChange={handleChange("cost")}
                      />
                    </Col>

                    <Col className="error">{error?.cost}</Col>
                  </Col>
                  {/* Cost End */}
                </>
              )}
            </Col>
          )}
          {/* cost end */}

          {/* testing tab */}
          {tab === "testing" && canSeeCost && (
            <Col className="tab-panel-container">
              {disableTaskEdit ? (
                <>
                  <Col className="mb15">
                    <Col className="label">Meta</Col>
                    <Tag>
                      {" "}
                      Lorem ipsum dolor sit amet consectetur.
                      {/* {costObject?.costType ?? "-"} */}
                    </Tag>
                  </Col>
                  <Col className="mb15">
                    <Col className="label">Title</Col>
                    <Col className="value">
                      Testing
                      {/* {costObject?.currencySymbol} {costObject?.cost || 0} */}
                    </Col>
                    <Col className="mb15">
                      <Space direction="vertical">
                        <Collapse collapsible="header">
                          <Panel
                            extra={genExtra()}
                            header="This panel can only be collapsed by clicking text"
                            key="1"
                          >
                            <p>{text}</p>
                          </Panel>
                        </Collapse>
                        <Collapse collapsible="header">
                          <Panel
                            extra={genExtra()}
                            header="This panel can only be collapsed by clicking text"
                            key="1"
                          >
                            <p>{text}</p>
                          </Panel>
                        </Collapse>
                        <Collapse collapsible="header">
                          <Panel
                            extra={genExtra()}
                            header="This panel can only be collapsed by clicking text"
                            key="1"
                          >
                            <p>{text}</p>
                          </Panel>
                        </Collapse>
                        <Collapse collapsible="icon">
                          <Panel
                            extra={genExtra()}
                            header="This panel can only be collapsed by clicking icon"
                            key="1"
                          >
                            <p>{text}</p>
                          </Panel>
                        </Collapse>
                        <Collapse collapsible="icon">
                          <Panel
                            header="This panel can't be collapsed"
                            key="1"
                            extra={genExtra()}
                          >
                            <p>{text}</p>
                          </Panel>
                        </Collapse>
                      </Space>
                    </Col>
                  </Col>

                  {/* {canSeeBudget && (
                    <>
                      <Col className="mb15">
                        <Col className="label">Margin %</Col>
                        <Col className="value">
                          {costObject?.marginPercent || 0}%
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Margin Amount</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.marginAmount || 0}
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Budget</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.budgetAmount || 0}
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Consumed Budget</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.consumedBudget || 0}
                        </Col>
                      </Col>
                      <Col className="mb15">
                        <Col className="label">Additional Margin</Col>
                        <Col className="value">
                          {costObject?.currencySymbol}
                          {costObject?.additionalMargin || 0}
                        </Col>
                      </Col>
                    </>
                  )} */}
                </>
              ) : (
                <>
                  {/* Cost Type Start */}
                  {/* <Col className="input-field-container ">
                    <Col className="mb5">
                      <label className="label">Cost Type</label>
                    </Col>

                    <Col className="input-container fw">
                      <Radio.Group
                        onChange={handleChange("cost_type")}
                        defaultValue={"per_word"}
                        value={state?.cost_type}
                        disabled={itemObj?.loading}
                      >
                        <Radio value={"per_word"}>Per Word</Radio>
                        <Radio value={"per_content"}>Per Content</Radio>
                      </Radio.Group>
                    </Col>
                  </Col> */}
                  {/* Cost Type End */}

                  {/* Cost  Start */}
                  {/* <Col className="input-field-container mt20">
                    <Col className="mb5">
                      <label className="label">Cost</label>
                      <sup style={{ color: "red" }}>*</sup>
                    </Col>

                    <Col className="input-container fw">
                      <Input
                        type="text"
                        placeholder="Enter Cost"
                        className="fw"
                        value={state?.cost}
                        disabled={itemObj?.loading}
                        addonBefore={prefixSelector}
                        onChange={handleChange("cost")}
                      />
                    </Col>

                    <Col className="error">{error?.cost}</Col>
                  </Col> */}
                  {/* testing End */}
                </>
              )}
            </Col>
          )}
        </div>
        <div className="footer">
          <Row className="value" align="middle">
            Status &nbsp;&nbsp;
            {statusTransformer(itemObj?.status_description)}
          </Row>
          <Row className="value" align="middle">
            Word Count
            <Badge
              size="large"
              style={{
                marginLeft: 15,
                backgroundColor: "#EA605A",
                borderColor: "#EA605A",
                height: 25,
                paddingTop: 4,
                fontSize: 15,
              }}
              showZero={true}
              count={itemObj?.word_count ?? 0}
              overflowCount={Math.max}
            />
          </Row>
          <Row className="value" align="middle">
            Iteration
            <Badge
              size="large"
              style={{
                color: "#000",
                marginLeft: 15,
                backgroundColor: "#F3BC00",
                borderColor: "#F3BC00",
                height: 25,
                paddingTop: 4,
                fontSize: 15,
              }}
              showZero={true}
              count={itemObj?.iteration ?? 0}
              overflowCount={Math.max}
            />
          </Row>
          {itemObj?.status_description === APPROVED && itemObj?.ratings && (
            <Row
              className="value"
              style={{ marginLeft: "auto" }}
              align="middle"
            >
              Rating &nbsp;
              <Rate disabled value={itemObj?.ratings} />
              <Tooltip title="Editor's feedback">
                <InfoCircleOutlined
                  className="ml10 text-xl"
                  onClick={() => setShowRatingModal(true)}
                />
              </Tooltip>
            </Row>
          )}
        </div>
      </div>

      <div className="sidebar-container">
        <TaskChat
          id={id}
          onModel={constants.ON_MODEL.PROJECT_ITEM}
          disabled={!isProjectEditable}
        />
      </div>

      {canAssignFreelancer && (
        <SelectFreelancerModal
          visible={showSelectFreelancer}
          projectTaskId={id}
          handleModal={() => setShowSelectFreelancer(false)}
          editable={isProjectEditable}
          costObject={costObject}
        />
      )}
      {/* select freelancer end */}
      {/* select freelancer start */}
      {isProjectEditable && (
        <>
          <TaskAcceptanceModal
            visible={showAcceptanceModal}
            projectTaskId={id}
            handleModal={() => setShowAcceptanceModal(false)}
            costObject={costObject}
            editable={isProjectEditable}
          />
          <TaskRatingModel
            visible={showRatingModal}
            projectTaskId={id}
            handleModal={() => setShowRatingModal(false)}
            editable={isProjectEditable}
          />
        </>
      )}
      {/* select freelancer end */}
      {showModal && (
        <ContentModal showModal={setShowModal} isModalOpen={showModal} />
      )}
    </div>
  );
};

export default ContentTaskDetails;
