import { call, put, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import MSaga from "../../helper/MSaga";

import appUrl from "../../config/appUrl";
import { projectActions } from "./projectReducer";
import { showNotification } from "../../utils/commonFunctions";
const {
  onUpdateSectionFieldsRequest,

  archiveRequest,
  onArchiveRequestSuccess,
  onArchiveRequestFailure,

  getInsightDetailsRequest,
  getInsightDetailsSuccess,

  onShareWithRequest,
  reopenRequest,
} = projectActions;

// update section fields start
const updateSectionFieldsApi = (payload = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.PROJECT}/field/${payload?._id}`,
    data: payload.data,
  });
};

function* handleUpdateSectionFields(action) {
  try {
    const response = yield call(updateSectionFieldsApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(projectActions.onUpdateOneFailure(data));
    } else {
      const mSectionId = action.payload.data[0].section_id;

      const mProject = data?.data;
      const mTemplateForms = mProject?.template?.forms || [];
      const lastSectionId =
        mTemplateForms[mTemplateForms.length - 1].header.fid;

      if (mSectionId === lastSectionId) {
        const payload = { _id: mProject._id, draft: false };
        yield put(projectActions.onUpdateOneRequest(payload));
      } else {
        yield put(projectActions.onUpdateOneSuccess(mProject));
      }
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("Update Section Fields ", error);

    error._id = action.payload?._id;
    yield put(projectActions.onUpdateOneFailure(error));
  }
}
// update section fields end

// share with other start
const shareWithApi = (payload = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.SHARE_WITH}/${payload?._id}`,
    data: payload,
  });
};

function* handleShareWithRequest(action) {
  try {
    const response = yield call(shareWithApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(projectActions.onUpdateOneFailure(data));
    } else {
      data.data._id = action.payload?._id;
      yield put(projectActions.onUpdateOneSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("Update Section Fields ", error);

    error._id = action.payload?._id;
    yield put(projectActions.onUpdateOneFailure(error));
  }
}
// share with other end

// Handle archive request start
const handleArchiveRequestApi = (data = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.PROJECT}/${data?._id}`,
    data,
  });
};

function* handleArchiveRequest(action) {
  try {
    const response = yield call(handleArchiveRequestApi, action.payload.data);
    const { data } = response;
    data.filter = action.payload?.filter;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onArchiveRequestFailure(data));
    } else {
      yield put(onArchiveRequestSuccess(data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("Archive failed ", error);
    yield put(onArchiveRequestFailure(error));
  }
}

// Handle archive request end

// Get project insights request start
const handleInsightDetailsRequestApi = (params = {}) => {
  return axios({
    method: "GET",
    url: `${appUrl.PROJECT_INSIGHTS}/${params?._id}`,
  });
};

function* handleInsightDetailsRequest(action) {
  try {
    const response = yield call(handleInsightDetailsRequestApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(projectActions.onGetOneFailure(data));
    } else {
      data.data._id = action.payload?._id;
      yield put(getInsightDetailsSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("Get project insights failure ", error);
    const mError = {
      ...error,
      _id: action.payload?._id
    }
    yield put(projectActions.onGetOneFailure(mError));
  }
}
// Get project insights request end

// Handle reopen request start
const handlereopenRequestApi = (data = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.PROJECT_STATUS_UPDATE}/${data?._id}`,
  });
};

function* handlereopenRequest(action) {
  try {
    const response = yield call(handlereopenRequestApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(projectActions.onUpdateOneFailure(data));
    } else {
      yield put(projectActions.onUpdateOneSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("Reopening failed ", error);
    yield put(onArchiveRequestFailure(error));
  }
}
// Handle reopen request end

const projectSaga = new MSaga({
  name: "project",
  apiEndPoint: appUrl.PROJECT,
  actions: projectActions,
  messages: {
    create: {
      success: "New Project Created",
      error: "Error while creating project",
    },
    updateOne: {
      success: "Project Updated",
      error: "",
    },
  },
});

function* watchProjectSaga() {
  yield fork(projectSaga.getWatchSaga());
  yield takeLatest(
    onUpdateSectionFieldsRequest.type,
    handleUpdateSectionFields
  );
  yield takeLatest(onShareWithRequest.type, handleShareWithRequest);

  yield takeLatest(archiveRequest.type, handleArchiveRequest);
  yield takeLatest(getInsightDetailsRequest.type, handleInsightDetailsRequest);

  yield takeLatest(reopenRequest.type, handlereopenRequest);
}

export default watchProjectSaga;
