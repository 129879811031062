import React, { useCallback, useMemo, useState } from "react";
import { Col, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import OpenAiContentTypeCard from "./components/OpenAiContentTypeCard";

import constants from "../utils/constants";

const { AUTO_GENERATE_CONTENT_TYPES } = constants;

const OpenAiTemplates = () => {
  const [state, setState] = useState({});

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value;
      setState((prevState) => ({ ...prevState, [name]: value }));
    },
    []
  );

  const contentTypes = useMemo(() => {
    let search = state.search;
    search = typeof search === "string" && search.trim().toLowerCase();

    let contentTypes = Object.keys(AUTO_GENERATE_CONTENT_TYPES);
    if (search) {
      contentTypes = contentTypes.filter((contentTypeKey) => {
        const contentType = AUTO_GENERATE_CONTENT_TYPES[contentTypeKey];
        const contentTypeName = contentType?.name?.toLowerCase();

        return contentTypeName?.includes?.(search);
      });
    }

    return contentTypes;
  }, [state.search]);

  return (
    <Col className="content-body open-ai open-ai-dashboard">
      {/* search input start */}
      <Col className="mb-12">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={12} xxl={8}>
            <Input
              className="fw"
              size="large"
              prefix={<SearchOutlined />}
              placeholder="Search Here..."
              value={state.search}
              onChange={handleChange("search")}
            />
          </Col>

          {/* <Col xs={24} sm={6} md={5} lg={4} xl={3} xxl={3}>
            <Button className="fw" type="primary" size="large">
              Search
            </Button>
          </Col> */}
        </Row>
      </Col>
      {/* search input end */}

      {/* open ai content types list start */}
      <Col className="mb-12">
        <Col className="mb-4">
          <Row align="middle" justify="space-between">
            <Row className="page-subheading">Templates</Row>
          </Row>

          <Row className="description">
            Kickstart your journey with any of the following content templates!
          </Row>
        </Col>

        <Row gutter={[10, 10]}>
          {contentTypes.map((contentType, i) => (
            <OpenAiContentTypeCard key={i} _id={contentType} />
          ))}
        </Row>
      </Col>
      {/* open ai content types list end */}
    </Col>
  );
};

export default OpenAiTemplates;
