import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "../../models/User";
import routes from "../../utils/routes";
import newTab from "../../assets/images/newTab.svg";
import "./TaskListRow.scss";
import NameWithAvatar from "../../components/NameWithAvatar";
import { Col, Row, Tag, Tooltip } from "antd";
import moment from "moment-timezone";
import { statusTransformer } from "../../components/StatusTransformer";
import { CalendarOutlined } from "@ant-design/icons";
import { abbreviateNumber, textWrapper } from "../../utils/commonFunctions";

const TaskListRow = (props) => {
  const { id } = props;

  const navigate = useNavigate();

  const itemObj = useSelector(({ item }) => item.map[id]);
  const fields = useMemo(() => itemObj.fields, [itemObj.fields]);

  const createdBy = useMemo(
    () => new User().fromMap(itemObj?.created_by),
    [itemObj?.created_by]
  );
  const assignedFreelancer = useMemo(
    () => new User().fromMap(itemObj?.assigned_freelancer),
    [itemObj?.assigned_freelancer]
  );
  const currentAssignee = useMemo(() => {
    if (itemObj?.current_assignee) {
      return new User().fromMap(itemObj?.current_assignee);
    } else {
      return new User().fromMap(itemObj?.project_id?.assigned_manager);
    }
  }, [itemObj]);

  const task_type = useMemo(() => {
    let costType = "-";
    if (itemObj?.meta?.cost_type) {
      costType =
        itemObj?.meta?.cost_type === "per_content" ? "Per Content" : "Per Word";
    }
    return costType;
  }, [itemObj?.meta?.cost_type]);

  const onProjectTaskDetails = useCallback(() => {
    const navigateTo = routes.PROJECT_TASK_DETAIL.replace(
      ":projectId",
      itemObj?.project_id?._id
    ).replace(":id", id);

    navigate(navigateTo);
  }, [itemObj?.project_id?._id, id, navigate]);

  return (
    <Col
      className="project-item-row-card fw "
      span={24}
      onClick={onProjectTaskDetails}
    >
      <Row className="card-container fw" align="middle" justify="space-between">
        <Col>#{itemObj?.task_id}</Col>
        <Col className="name">
          <Row align="middle" wrap={false} justify={"space-between"}>
            {textWrapper(fields?.[1].value, 20, 26)}
            {itemObj?.doc && (
              <Tooltip title="Content Link">
                <Row
                  className="mr15"
                  wrap={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `https://docs.google.com/document/d/${itemObj?.doc}`,
                      "_blank"
                    );
                  }}
                >
                  <img className="ml5 mb5" width={18} src={newTab} alt="" />
                </Row>
              </Tooltip>
            )}
          </Row>
        </Col>
        <Col>
          <Row className="name">{textWrapper(fields?.[2].value)}</Row>
        </Col>
        <Col className="name">
          <NameWithAvatar user={createdBy} showToolTip />
        </Col>
        <Col className="name">
          <NameWithAvatar user={assignedFreelancer} showToolTip />
        </Col>
        <Col className="name">
          <NameWithAvatar user={currentAssignee} showToolTip showRole />
        </Col>
        <Col>{statusTransformer(itemObj?.status_description)}</Col>

        <Col className="name">
          <CalendarOutlined className="mr5" />
          {moment(itemObj?.created_at).isValid()
            ? moment(itemObj?.created_at).format("DD MMM YYYY")
            : "-"}
        </Col>
        <Col className="name">
          <CalendarOutlined className="mr5" />
          {moment(itemObj?.due_date).isValid()
            ? moment(itemObj?.due_date).format("DD MMM YYYY")
            : "-"}
        </Col>
        <Col>
          <Row className="name pl20">
            {abbreviateNumber(Number(itemObj?.word_count || 0), 1)}
          </Row>
        </Col>

        <Col className="name">
          <Tag>{task_type}</Tag>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(TaskListRow);
