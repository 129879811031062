import React, { useMemo } from "react";
import { Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

import { ReactComponent as OpenAiTaskIcon } from "../../assets/images/open-ai-task-icon.svg";

import constants from "../../utils/constants";

const { AUTO_GENERATE_CONTENT_TYPES } = constants;

const OpenAiContentTypeCard = (props) => {
  const { _id } = props;

  const navigate = useNavigate();

  const contentType = useMemo(() => AUTO_GENERATE_CONTENT_TYPES[_id], [_id]);

  if (!contentType || !contentType.active) return null;

  return (
    <Col
      className="template-card-container"
      xs={24}
      sm={12}
      md={12}
      lg={8}
      xl={6}
      xxl={4}
      onClick={() => navigate(contentType.url)}
    >
      <Card
        className="template-card container-shadow pointer fw"
        bordered={false}
      >
        <Card.Meta
          title={
            <Row align="middle">
              <OpenAiTaskIcon className="task-icon mr5" />

              <span className="line-clamp-1">{contentType.name}</span>
            </Row>
          }
          description={
            <span className="line-clamp-2">{contentType.description}</span>
          }
        />
      </Card>
    </Col>
  );
};

export default React.memo(OpenAiContentTypeCard);
