import React, { useMemo } from "react";
import { Col, Row, Input, Select } from "antd";
import ReactQuill from "react-quill";
import Field from "../models/Field";
import { CaretDownOutlined } from "@ant-design/icons";

import { getStringToOptions } from "../utils/commonFunctions";

import "./InputField.scss";

const FieldTypes = Field.TYPES;

const InputField = (props) => {
  const { help_text, read_only, value, disabled } = props;

  const mField = useMemo(() => {
    const type = props?.type;
    switch (type) {
      case FieldTypes.TEXT:
        return (
          <Input
            className="input input-area"
            placeholder={help_text}
            value={value}
            size="large"
            disabled={disabled}
            onChange={props.onChange}
          />
        );

      case FieldTypes.TEXTAREA:
        return (
          <ReactQuill
            style={{
              height: "250px",
              marginBottom: "50px",
            }}
            placeholder={help_text}
            value={value}
            readOnly={disabled}
            onChange={props.onChange}
            theme="snow"
          />
          // <Input.TextArea
          //   className="input input-area"
          //   placeholder={help_text}
          //   value={value}
          //   disabled={disabled}
          //   autoSize={{ minRows: 4, maxRows: 6 }}
          //   onChange={props.onChange}
          // />
        );

      case FieldTypes.SELECT:
      case FieldTypes.DROPDOWN:
        const mOptions = getStringToOptions(props?.options || "");

        return (
          <Select
            mode={props?.multiselct ? "multiple" : undefined}
            className="input fw "
            showSearch
            size="large"
            placeholder={help_text}
            value={value}
            suffixIcon={<CaretDownOutlined className="f15" />}
            onChange={props.onChange}
            disabled={disabled}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {mOptions.map((option, i) => (
              <Select.Option key={i} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        );

      default:
        return <></>;
    }
  }, [props, help_text, read_only, value]);

  return (
    <Col className="input-field-container">
      <Col className="mb5">
        <label className="label">{props.label}</label>
        {props.mandatory && <sup style={{ color: "red" }}>*</sup>}
      </Col>

      <Col className="input-container fw">{mField}</Col>

      <Col className="error">{props.error}</Col>
    </Col>
  );
};

export default React.memo(InputField);
