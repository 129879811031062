import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Input, Button, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import ProjectRowItem from "./components/ProjectRowItem";
import { SearchOutlined } from "@ant-design/icons";

import { projectActions } from "./duck/projectReducer";
import { paramsToString } from "../utils/commonFunctions";
import "./ProjectList.scss";
import MyTable from "../components/MyTable";
import { useLocation } from "react-router-dom";

const ProjectArchived = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [boardKey, setBoardKey] = useState("{draft: false,archive:true}");
  const [state, setState] = useState({ search: "", callDebounce: true });

  const project = useSelector(({ project }) => project);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = project.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [project, boardKey]);

  const filters = useMemo(
    () => project?.filters?.[location?.pathname],
    [location?.pathname, project?.filters]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 10) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { search } = state;

        const params = {
          pathname: location?.pathname,
          draft: false,
          archive: true,
          offset,
          limit,
        };
        if (search) params.search = search;

        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, location?.pathname, meta?.totalCount, state]
  );

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      setState({
        callDebounce: false,
        ...JSON.parse(filters),
      });
    } else {
      loadMore();
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!!list && !loading && state?.callDebounce) {
        loadMore();
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [state]);

  const handleChange = (name) => (event) => {
    let value = event?.target?.value ?? event;

    setState((preState) => ({
      ...preState,
      [name]: value,
      callDebounce: true,
    }));
  };
  const columns = [
    { key: 1, title: "Name" },
    { key: 2, title: "Progress" },
    { key: 3, title: "Word Count" },
    { key: 4, title: "Created By" },
    { key: 5, title: "Assigned To" },
    { key: 6, title: "Status" },
  ];

  return (
    <div className="content-body app-container project-list-container">
      <Row justify="start" gutter={[10, 10]}>
        <Col xs={24} sm={10} md={8} lg={5} xl={4} xxl={3}>
          <Button className="new_button fw" type="primary" size="large">
            <Row align="middle" wrap={false}>
              <span className="project-counter project-counter-btn">
                {meta?.totalCount || 0}
              </span>
              &nbsp; All Projects
            </Row>
          </Button>
        </Col>
        <Col xs={24} sm={14} md={16} lg={12} xl={8} xxl={8}>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Search Project Name"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Col>
      </Row>
      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          renderItem={(item, i) => (
            <ProjectRowItem key={i} id={item} boardKey={boardKey} />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>
    </div>
  );
};

export default React.memo(ProjectArchived);
