import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Button, Col, Row, Skeleton, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined, UserAddOutlined } from "@ant-design/icons";

import axios from "axios";
import { itemActions } from "../../item/duck/itemReducer";
import { paramsToString, showNotification } from "../../utils/commonFunctions";
import PlusIcon from "../../assets/images/plusIcon.svg";
import constants from "../../utils/constants";
import fileDownload from "js-file-download";
// import "./ProjectTaskList.scss";
import AppUrl from "../../config/appUrl";
import moment from "moment-timezone";

import { useMemo } from "react";
import NameWithAvatar from "../../components/NameWithAvatar";
import User from "../../models/User";
import { useLocation } from "react-router-dom";
import ContentTaskItem from "./ContentTaskItem";

const { DEFAULT_PAGING, PROJECT_TASK_STATUS, ROLE_IDS } = constants;
const { COMPLETED, APPROVED } = PROJECT_TASK_STATUS;

const ContentTaskList = (props) => {
  const {
    projectId,
    taskStatus,
    filter = {},
    isProjectEditable,
    handleModal = () => {},
  } = props;
  const { pathname } = useLocation();

  const [boardKey, setBoardKey] = useState("{}");

  const dispatch = useDispatch();
  const projectObj = useSelector(({ project }) => project?.map?.[projectId]);
  const project_name = useMemo(() => projectObj?.name, [projectObj?.name]);

  const assignedClient = useMemo(
    () => new User().fromMap(projectObj?.assigned_client),
    [projectObj?.assigned_client]
  );

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const { list, meta, loading } = useSelector(({ item }) => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = item.boards;
    const mBoard = boards[mFilter];

    return Object.assign({}, mBoard);
  });

  const loadMore = useCallback(
    (offset = DEFAULT_PAGING.offset, limit = DEFAULT_PAGING.limit) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          pathname,
          project_id: projectId,
          status_description: taskStatus,
          offset: offset,
          limit: limit,
        };

        if (filter.task_name) params.task_name = filter.task_name;
        if (filter.start_date) {
          params.start_date = moment(filter.start_date)
            .startOf("day")
            .utc()
            .format();
        }
        if (filter.end_date) {
          params.end_date = moment(filter.end_date).endOf("day").utc().format();
        }

        if (!filter.status_description) {
          if (PROJECT_TASK_STATUS.COMPLETED === taskStatus) {
            params.status_description = [COMPLETED, APPROVED];
          }
        } else if (filter.status_description === "approved") {
          if (PROJECT_TASK_STATUS.COMPLETED === taskStatus) {
            params.status_description = APPROVED;
          } else {
            params.status_description = "empty";
          }
        }

        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [dispatch, list, pathname, meta, projectId, taskStatus, filter]
  );

  useEffect(() => {
    loadMore();
  }, [filter]);

  const handleScroll = useCallback(
    ({ target }) => {
      if (
        Math.ceil(target.scrollTop) + window.innerHeight >=
        target.scrollHeight
      ) {
        if (!loading && list?.length < meta?.totalCount) {
          loadMore(list?.length);
        }
      }
    },
    [list?.length, loadMore, loading, meta?.totalCount]
  );

  // const downloadTask = useCallback(() => {
  //   const mParams = JSON.parse(boardKey);
  //   axios({
  //     method: "GET",
  //     url: `${AppUrl.ITEMS}/export`,
  //     params: {
  //       project_name,
  //       ...mParams,
  //     },
  //     responseType: "arraybuffer",
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         let filename = response.headers["content-type"].split("=");
  //         fileDownload(response.data, filename[1]);
  //         showNotification("success", "File downloaded.");
  //       } else {
  //         showNotification("error", "Error while downloading file.");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       showNotification("error", "Something went wrong");
  //     });
  // }, [boardKey, project_name]);

  return (
    <Col className="project-task-list-container pri-box-shadow">
      {/* header start */}
      <Row
        className="header pri-box-shadow"
        align="top"
        justify="space-between"
      >
        <Row className="list-name" wrap={false}>
          {props.listName} (0)
          {/* ({meta?.totalCount || 0}) */}
          &nbsp;
          {props?.listName === "To-Do List" && (
            <Button
              style={{ marginLeft: "48px" }}
              // type="primary"
              size="medium"
              // icon={<img src={PlusIcon} alt="plus icon" />}
            >
              Upload File
            </Button>
          )}
          {taskStatus === PROJECT_TASK_STATUS.COMPLETED &&
            loggedInUser?.role_id === ROLE_IDS.SUPER_ADMIN && (
              <>
                {assignedClient?._id ? (
                  <NameWithAvatar
                    user={assignedClient}
                    // onClick={() => handleModal(true)}
                    avatarOnly
                  />
                ) : (
                  isProjectEditable && (
                    <Tooltip title="Assign Client">
                      <Avatar
                        className="btn-select-manager pointer"
                        icon={<UserAddOutlined />}
                        // onClick={() => handleModal(true)}
                      />
                    </Tooltip>
                  )
                )}
              </>
            )}
          <span className="subheading">{props.subHeading}</span>
        </Row>

        {/* <Row wrap={false}>
          {meta?.totalCount > 0 && (
            <Col className="ml5">
              <Tooltip title="Download as Excel" placement="bottomLeft">
                <DownloadOutlined
                  style={{ fontSize: 18, marginLeft: "auto" }}
                  // onClick={downloadTask}
                />
              </Tooltip>
            </Col>
          )}
        </Row> */}
      </Row>
      {/* header end */}

      {/* task list body container start */}
      <Col className="body">
        {!list?.length && loading ? (
          <Col className="project-task-kanban-item-container m15">
            <Skeleton active={loading} paragraph={{ rows: 15 }} />
          </Col>
        ) : (
          <Col className="list-container" onScroll={handleScroll}>
            {list?.map((item, i) => (
              <ContentTaskItem
                key={i}
                id={item}
                projectId={projectId}
                isProjectEditable={isProjectEditable}
              />
            ))}
            {!!list?.length && loading && (
              <Col className="project-task-kanban-item-container m15">
                <Skeleton active={loading} paragraph={{ rows: 2 }} />
              </Col>
            )}
          </Col>
        )}
      </Col>
      {/* task list body container end */}
    </Col>
  );
};

export default React.memo(ContentTaskList);
