import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Input, Radio, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";

import CustomModal from "../../components/CustomModal";
import InputField from "../../components/InputField";

import { itemActions } from "../../item/duck/itemReducer";

import "./ProjectTaskModal.scss";
import constants from "../../utils/constants";
const { PROJECT_TASK_STATUS, CURRENCIES } = constants;

const ProjectTaskModal = (props) => {
  const { visible, projectId } = props;

  const prevProps = useRef();
  const dispatch = useDispatch();

  const projectObj = useSelector(({ project }) => project.map[projectId]);
  const item = useSelector(({ item }) => item);

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (visible) {
      if (prevProps.current?.loading && !item?.loading) {
        if (!item?.error) {
          props.handleModal();
        }
      }
    } else {
      setState({ cost_type: "per_word", currency: CURRENCIES.INR._id });
    }

    return () => {
      prevProps.current = { loading: item?.loading };
    };
  }, [visible, item?.loading, item?.error, props]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      if (name === "cost") {
        value = value.replace(/[^0-9.]/g, "");
      }
      if (name === "3") {
        value = value.replace(/[^0-9]/g, "");
      }

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    const { cost, cost_type, currency } = state;
    const error = {};

    projectObj.itemFields?.forEach((field, i) => {
      const vlaue = state[i]?.trim?.();
      if (field.mandatory && !vlaue) {
        error[i] = field.validation;
      }
    });
    const perWordLimit = CURRENCIES[currency].PER_WORD_LIMIT;
    const perContentLimit = CURRENCIES[currency].PER_CONTENT_LIMIT;

    if (!cost) {
      error.cost = "Please enter cost for the task";
    } else if (Number(state.cost) <= 0) {
      error.cost = "Cost must be greater than 0";
    } else if (cost_type === "per_word" && cost > perWordLimit) {
      error.cost = `Per word cost cannot be greater than ${perWordLimit}`;
    } else if (cost_type === "per_content" && cost > perContentLimit) {
      error.cost = `Per content cost cannot be greater than ${perContentLimit}`;
    }

    setError(error);

    return !!Object.keys(error).length;
  }, [state, projectObj.itemFields]);

  const prefixSelector = useMemo(
    () => (
      <Select
        style={{ width: 130, border: "none" }}
        size="large"
        value={state?.currency}
        suffixIcon={<CaretDownOutlined className="f15" />}
        onChange={handleChange("currency")}
        disabled={item?.loading}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {Object.values(CURRENCIES).map((currObj) => (
          <Select.Option key={currObj._id} value={currObj._id}>
            {currObj._id} ({currObj.SYMBOL})
          </Select.Option>
        ))}
      </Select>
    ),

    [handleChange, item?.loading, state?.currency]
  );

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const filter = JSON.stringify({
        project_id: projectId,
        status_description: PROJECT_TASK_STATUS.INITIATE,
      });
      const fields = projectObj.itemFields.map((field, i) => ({
        ...field,
        value: state[i] ?? "",
      }));

      const payload = {
        fields,
        project_id: projectId,
        meta: {
          cost_type: state?.cost_type,
          cost: Number(state?.cost),
          currency: state?.currency,
          history: [],
        },
        filter,
      };
      dispatch(itemActions.onCreateOneRequest(payload));
    }
  }, [hasError, state, projectId, dispatch, projectObj.itemFields]);

  return (
    <CustomModal
      className="create-project-task-modal-container"
      visible={visible}
      title={"Create New Task"}
      onCancel={props.handleModal}
      footer={
        <Row className="p10">
          <Button
            className="new_button"
            type="primary"
            size="large"
            onClick={onSubmit}
            loading={item?.loading}
          >
            Create
          </Button>
          <Button
            className="new_button"
            type="primary-outline"
            size="large"
            onClick={props.handleModal}
          >
            Cancel
          </Button>
        </Row>
      }
    >
      <Col className="content-container">
        {projectObj.itemFields?.map?.((field, i) => (
          <Fragment key={i}>
            {i === projectObj.itemFields?.length - 1 && (
              <>
                {/* Cost Type Start */}
                <Col className="input-field-container ">
                  <Col className="mb5">
                    <label className="label">Cost Type</label>
                  </Col>

                  <Col className="input-container fw">
                    <Radio.Group
                      onChange={handleChange("cost_type")}
                      defaultValue={"per_word"}
                      value={state?.cost_type}
                      disabled={item?.loading}
                    >
                      <Radio value={"per_word"}>Per Word</Radio>
                      <Radio value={"per_content"}>Per Content</Radio>
                    </Radio.Group>
                  </Col>
                </Col>
                {/* Cost Type End */}

                {/* Cost  Start */}
                <Col className="input-field-container mt20">
                  <Col className="mb5">
                    <label className="label">Cost</label>
                    <sup style={{ color: "red" }}>*</sup>
                  </Col>

                  <Col className="input-container fw">
                    <Input
                      style={{
                        borderRadius: 6,
                      }}
                      type="text"
                      size="large"
                      placeholder="Enter Cost"
                      className="fw"
                      value={state?.cost}
                      disabled={item?.loading}
                      addonBefore={prefixSelector}
                      onChange={handleChange("cost")}
                    />
                  </Col>

                  <Col className="error">{error?.cost}</Col>
                </Col>
                {/* Cost End */}
              </>
            )}
            <InputField
              key={i}
              {...field}
              value={state[i]}
              error={error[i]}
              onChange={handleChange(`${i}`)}
              disabled={item?.loading}
            />
          </Fragment>
        ))}
      </Col>
    </CustomModal>
  );
};

export default React.memo(ProjectTaskModal);
