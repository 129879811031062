import React, { useCallback, useState } from "react";
import { Button, Col, Input, Result, Row } from "antd";

import "./MRadioGroup.scss";

const MRadioGroup = ({
  values = [],
  value = "",
  onChange = (value) => value,
  onChangeValues = (values = []) => values,
  showSelectBtn = true,
  canTextEdit = false,
  ...props
}) => {
  const [activeEditItem, setActiveEditItem] = useState(null);

  const onChangeValueItem = useCallback(
    (i = 0) =>
      (e) => {
        let value = e?.target?.value ?? e;

        if (props.value && props.value === values[i]) {
          onChange(value);
        }

        let newValues = [...values];
        newValues[i] = value;
        onChangeValues(newValues);
      },
    [props.value, values, onChangeValues, onChange]
  );

  const onEdit = useCallback(
    (i) => {
      setActiveEditItem((prevItem) => prevItem !== i && i);
      onChange("");
    },
    [onChange]
  );

  const onDelete = useCallback(
    (i) => {
      const newValues = values.filter((_, itemIndex) => itemIndex !== i);

      setActiveEditItem(false);
      onChangeValues(newValues);
    },
    [values, onChangeValues]
  );

  return (
    <Col className="m-radio-group-container">
      {values?.length ? (
        values.map((v, i) => {
          const canEdit = activeEditItem === i;

          return (
            <Col key={i} className="m-radio-group-item pb-5 mb-5 border-b">
              <Col className="item-value mb-5">
                {canEdit ? (
                  <Col>
                    <Input
                      className="input input-area"
                      value={v}
                      onChange={onChangeValueItem(i)}
                      size="large"
                    />
                  </Col>
                ) : (
                  v
                )}
              </Col>

              {(typeof activeEditItem !== "number" || i === activeEditItem) && (
                <Col>
                  <Row align="middle">
                    {canTextEdit && (
                      <>
                        {v && (
                          <Button
                            className="mr-2"
                            type={canEdit ? "primary" : "default"}
                            onClick={() => onEdit(i)}
                          >
                            {canEdit ? "Done" : "Edit"}
                          </Button>
                        )}

                        <Button className="mr-2" onClick={() => onDelete(i)}>
                          Remove
                        </Button>
                      </>
                    )}

                    {showSelectBtn && i !== activeEditItem && (
                      <Button
                        className="mr-2"
                        type={value === v ? "primary" : "default"}
                        onClick={() => onChange(v)}
                      >
                        Select
                      </Button>
                    )}
                  </Row>
                </Col>
              )}
            </Col>
          );
        })
      ) : (
        <Result status={404} subTitle={"Sorry, no result found."} />
      )}
    </Col>
  );
};

export default React.memo(MRadioGroup);
