export const templateColumns = () => {
    const columns = [
        {
            title: "S.no",
            dataIndex: "sn",
            key: "sn",
            width: '5%',
            align: "center",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: '25%',
        },

        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            width: '25%',
        },

        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            width: '15%',
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: '10%',
            align: "center",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: '15%',
            align: "center",
        },
    ];

    return columns;
};
