import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import DashboardCounterCard from "./components/DashboardCounterCard";
import OpenAiContentTypeCard from "./components/OpenAiContentTypeCard";
import OpenAiTaskCard from "./components/OpenAiTaskCard";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import RecentlyGeneratedContentTable from "./components/RecentlyGeneratedContentTable";

import { openAiActions } from "./duck/openAiReducer";

import { paramsToString } from "../utils/commonFunctions";
import routes from "../utils/routes";
import constants from "../utils/constants";

import "./OpenAi.scss";

const { OPEN_AI_DASHBOARD_COUNTERS, AUTO_GENERATE_CONTENT_TYPES } = constants;

const contentTypes = Object.keys(AUTO_GENERATE_CONTENT_TYPES);

const OpenAiDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({});

  const [boardKey, setBoardKey] = useState("{}");
  const { list, meta, loading } = useSelector(({ openAi }) => {
    const boards = openAi.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const loadMoreTasks = useCallback(
    (offset = 0, limit = 5) => {
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const params = {
          offset: offset,
          limit: limit,
          draft: false,
          page_name: "dashboard",
        };

        setBoardKey(paramsToString(params));
        dispatch(openAiActions.onGetAllRequest(params));
      }
    },
    [dispatch, list, meta, loading]
  );

  useEffect(() => {
    loadMoreTasks();
    dispatch(openAiActions.onGetDashboardRequest());
  }, []);

  const onShowAllContentTypes = useCallback(() => {
    navigate(routes.OPEN_AI_TEMPLATES);
  }, [navigate]);

  const onShowAllTasks = useCallback(() => {
    navigate(routes.OPEN_AI_TASKS);
  }, [navigate]);

  const handleShowOpenAiTaskModal = useCallback((show, taskId) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
      taskId,
    }));
  }, []);

  return (
    <Col className="content-body open-ai open-ai-dashboard">
      {/* counter container start */}
      <Col className="mb-12">
        <Row className="counters-container" gutter={[20, 20]}>
          {OPEN_AI_DASHBOARD_COUNTERS.map((counter, i) => (
            <DashboardCounterCard key={i} {...counter} />
          ))}

          <Col
            className="counter-card-container"
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            xxl={4}
            onClick={onShowAllContentTypes}
          >
            <Card
              className="counter-card container-shadow fw bg-primary pointer"
              bordered={false}
            >
              <Row
                className="text-base font-semibold text-white mb10"
                align="middle"
                justify="center"
              >
                Let’s Get Started
              </Row>

              <Row
                className="text-3xl font-semibold text-white"
                align="middle"
                justify="center"
              >
                <PlusCircleFilled />
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      {/* counter containers end */}

      {/* open ai template/content types list start */}
      <Col className="mb-12">
        <Col className="mb-4">
          <Row align="middle" justify="space-between">
            <Row className="page-subheading">Templates</Row>

            {contentTypes.length > 5 && (
              <Button type="link" onClick={onShowAllContentTypes}>
                See All Templates
              </Button>
            )}
          </Row>

          <Row className="description">
            Kickstart your journey with any of the following content templates!
          </Row>
        </Col>

        <Row className="templates-container" gutter={[20, 20]}>
          {contentTypes.map(
            (cId, i) => i < 5 && <OpenAiContentTypeCard key={i} _id={cId} />
          )}
        </Row>
      </Col>
      {/* open ai template/content types list end */}

      {/* open ai tasks list start */}
      {Array.isArray(list) && list.length > 0 && (
        <Col className="mb-12">
          <Row className="mb-4" align="middle" justify="space-between">
            <Row className="page-subheading">Projects</Row>

            {meta?.totalCount > 5 && (
              <Button type="link" onClick={onShowAllTasks}>
                See All Projects
              </Button>
            )}
          </Row>

          <Row className="tasks-container" gutter={[20, 20]}>
            {list.map((taskId, i) => (
              <OpenAiTaskCard
                key={i}
                _id={taskId}
                onShowDetails={handleShowOpenAiTaskModal}
              />
            ))}
          </Row>
        </Col>
      )}
      {/* open ai tasks list end */}

      {/* open ai recently generated content start */}
      <RecentlyGeneratedContentTable />
      {/* open ai recently generated content end */}

      {/* open ai tasks model start */}
      <OpenAiTaskModal
        visible={state.showOpenAiTaskModal}
        taskId={state.taskId}
        handleModal={handleShowOpenAiTaskModal}
      />
      {/* open ai tasks model end */}
    </Col>
  );
};

export default OpenAiDashboard;
