import { socket } from "../config/Socket";
import { itemActions } from "../item/duck/itemReducer";
import { projectActions } from "../project/duck/projectReducer";
import { userActions } from "../users/duck/userReducer";
import { commentActions } from "../comment/duck/commentReducer";
import { roleActions } from "../role/duck/roleReducer";
import { notificationActions } from "../notifications/duck/notificationReducer";
import Store from "../config/store";
import constants from "./constants";
import { assessmentTestActions } from "../assessmentTest/duck/assessmentTestReducer";

const { TASK_TYPE, PROJECT_TASK_STATUS } = constants

const { dispatch } = Store;

const itemSocket = () => {
  socket.on("items", (item) => {
    console.log("item socket", item?.meta?.action, item);
    const mData = item?.data

    const loggedUserId = Store.getState()?.loggedInUser?.data?._id
    const assigned_freelancer = mData?.assigned_freelancer?._id;

    const project_id = mData?.project_id?._id
    const status_description = PROJECT_TASK_STATUS.INITIATE
    const taskTypes = [TASK_TYPE.ALL._id]
    const payload = {
      data: mData,
    }

    switch (item.meta.action) {
      case "create":
        taskTypes.push(loggedUserId === mData?.current_assignee?._id ? TASK_TYPE.MY_TASK._id : TASK_TYPE.OTHER_TASK._id)
        // for task list page
        taskTypes.forEach(taskType => {
          payload.filter = JSON.stringify({ taskType })
          dispatch(itemActions.onCreateOneSuccess(payload))
        })
        // for kanban view
        payload.filter = JSON.stringify({ project_id, status_description })
        dispatch(itemActions.onCreateOneSuccess(payload))
        // for list view
        payload.filter = JSON.stringify({ project_id })
        dispatch(itemActions.onCreateOneSuccess(payload))
        break;

      case "update":
        if (loggedUserId === assigned_freelancer && mData?.status_description
          === PROJECT_TASK_STATUS.IN_PROGRESS && !mData?.meta?.isAcceptedByWriter
        ) {
          // for freelancer dashboard
          payload.filter = JSON.stringify({ assigned_freelancer })
          dispatch(itemActions.onCreateOneSuccess(payload))

          // for freelancer task list
          taskTypes?.push(TASK_TYPE.MY_TASK._id)

          taskTypes.forEach(taskType => {
            payload.filter = JSON.stringify({ taskType })
            dispatch(itemActions.onCreateOneSuccess(payload))
          })

        } else {
          dispatch(itemActions.onUpdateOneSuccess(item?.data))
        }
        break;

      case "delete":
        dispatch(itemActions.onDeleteOneSuccess(item?.data))
        break;

      default:
        break;
    }
  });
};

const projectSocket = () => {
  socket.on("projects", (project) => {
    console.log("project socket", project?.meta?.action, project);

    switch (project.meta.action) {
      case "create":
        const payload = {
          data: project?.data,
          filter: JSON.stringify({
            draft: false,
            archive: false
          })
        }
        dispatch(projectActions.onCreateOneSuccess(payload))
        break;


      case "update":
        dispatch(projectActions.onUpdateOneSuccess(project?.data))
        break;

      default:
        break;
    }
  });
};

const userSocket = () => {
  socket.on("users", (user) => {
    console.log("user socket", user?.meta?.action, user);
    const payload = {
      data: user?.data,
    }
    let filters = [JSON.stringify({})]
    switch (user.meta.action) {
      case "create":
        if (user?.data?.role_id === "freelancer") {
          filters.push(JSON.stringify({ role_id: "freelancer" }))
        }
        filters.forEach((filter) => {
          payload.filter = filter
          dispatch(userActions.onCreateOneSuccess(payload))
        }
        )

        break;

      case "update":
        dispatch(userActions.onUpdateOneSuccess(user?.data))
        break;

      default:
        break;
    }
  });
};

const commentSocket = () => {
  socket.on("comments", (comment) => {
    console.log("comments socket", comment?.meta?.action, comment);
    switch (comment.meta.action) {
      case "create":
        const payload = {
          data: comment?.data,
          filter: JSON.stringify({ model_id: comment?.data?.model_id?._id }),
        }
        dispatch(commentActions.onCreateOneSuccess(payload))
        break;

      default:
        break;
    }
  });
};
const notificationSocket = () => {
  socket.on("notifications", (notification) => {
    console.log("notifications socket", notification?.meta?.action, notification);
    const nMeta = Store.getState()?.notification?.boards?.["{}"]?.meta

    switch (notification.meta.action) {
      case "create":
        const payload = {
          data: notification?.data,
          filter: JSON.stringify({}),
          meta: { totalUnreadCount: nMeta?.totalUnreadCount + 1 }
        }

        dispatch(notificationActions.onCreateOneSuccess(payload))
        break;

      default:
        break;
    }
  });
};
const assessmentSocket = () => {
  socket.on("assessments", (assessment) => {
    console.log("assessments socket", assessment?.meta?.action, assessment);

    switch (assessment.meta.action) {
      case "create":
        const payload = {
          data: assessment?.data,
          filter: JSON.stringify({ assigned_freelancer: assessment?.data?.user_id }),
        }
        dispatch(assessmentTestActions.onCreateOneSuccess(payload))
        break;
      case "update":
        dispatch(assessmentTestActions.onUpdateOneSuccess(assessment?.data))
        break;

      default:
        break;
    }
  });
};


const roleSocket = () => {
  socket.on("roles", (role) => {
    console.log("role socket update", role?.meta?.action, role);
    switch (role.meta.action) {
      case "update":
        dispatch(roleActions.onUpdateOneSuccess(role?.data))
        break;

      default:
        break;
    }
  });
};

export {
  itemSocket,
  projectSocket,
  userSocket,
  commentSocket,
  roleSocket,
  assessmentSocket,
  notificationSocket
};