import { Radio, Row, Select, Skeleton } from "antd";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { graphDataBuilder } from "../../utils/commonFunctions";
import constants from "../../utils/constants";
const { CURRENCIES } = constants;

const RevenueGraph = () => {
  const [view, setView] = useState("days");
  const [currency, setCurrency] = useState(CURRENCIES.INR._id);
  const { dashboard, loading } = useSelector(({ dashboard }) => dashboard);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setView(value);
    },
    []
  );

  const options = useMemo(
    () => ({
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            const value = Number(tooltipItem?.value) || 0;
            const label = `${CURRENCIES?.[currency]?.SYMBOL}${value}k`;
            return label;
          },
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              callback: (label) => `${label}k`,
            },
            gridLines: {
              display: false,
            },
          },
        ],

        xAxes: [
          {
            stacked: true,
          },
        ],
      },
    }),
    [currency]
  );

  const mData = useMemo(() => {
    const {
      revenue_chart_inr,
      revenue_chart_usd,
      revenue_chart_euro,
      revenue_chart_gbp,
    } = dashboard ?? {};
    let chart;

    switch (currency) {
      case CURRENCIES.USD._id:
        chart = revenue_chart_usd;
        break;
      case CURRENCIES.EURO._id:
        chart = revenue_chart_euro;
        break;
      case CURRENCIES.GBP._id:
        chart = revenue_chart_gbp;
        break;

      default:
        chart = revenue_chart_inr;
        break;
    }

    const data = chart?.[view];

    const newD = data?.map((o) => ({
      ...o,
      data: Number((o?.data / 1000).toFixed(2)),
    }));

    return newD;
  }, [currency, dashboard, view]);

  const chartData = graphDataBuilder(mData, view);

  return (
    <div className="container pri-box-shadow">
      <div className="title-container pri-box-shadow">
        <Row align="middle" justify="space-between" wrap={false}>
          <p className="header-text">
            Revenue Generated&nbsp;
            <Select
              style={{ width: 130, border: "none" }}
              size="large"
              value={currency}
              onChange={(e) => setCurrency(e)}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {Object.values(CURRENCIES).map((currObj) => (
                <Select.Option key={currObj._id} value={currObj._id}>
                  {currObj._id} ({currObj.SYMBOL})
                </Select.Option>
              ))}
            </Select>
          </p>

          <Radio.Group
            className="darg"
            onChange={handleChange("view")}
            value={view}
          >
            <Radio.Button value="days">Daily</Radio.Button>
            <Radio.Button value="week">Weekly</Radio.Button>
            <Radio.Button value="month">Monthly</Radio.Button>
          </Radio.Group>
        </Row>
      </div>
      <div className="project-graph-container">
        {loading ? (
          <Skeleton active={loading} paragraph={{ rows: 5 }} />
        ) : (
          <div className="chart-container">
            <Line
              data={{
                labels: chartData?.labels,
                datasets: [
                  {
                    data: chartData?.data,

                    borderWidth: 4,
                    borderColor: "#000000",
                    fill: false,
                    pointBorderWidth: 8,
                    pointStyle: "circle",
                    pointHoverBackgroundColor: "#4082A6",

                    pointHoverBorderColor: "#4082A6",
                  },
                ],
              }}
              options={options}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RevenueGraph;
