import React, { useMemo } from "react";
import { Button, Col, Row, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadFile } from "../../utils/commonFunctions";
import Attachment from "../../models/Attachment";

import "./FileItem.scss";

const FileItem = (props) => {
  const { file } = props;
  const mAttachment = useMemo(() => {
    const attachment = new Attachment();
    attachment.fromMap(file);
    return attachment;
  }, [file]);

  if (!mAttachment._id) {
    return <></>;
  }

  return (
    <Col className="file-item">
      <Tooltip title={mAttachment.name}>
        <Col className="file-item-container">
          <Col className="thumbnail">
            {mAttachment.getFileTypeIcon()}
            <Col className="file-name">{mAttachment.name}</Col>
          </Col>

          <Col className="hover-options-container">
            <Row className="hover-options-header" align="middle" justify="end">
              <Button
                icon={<DownloadOutlined />}
                size="small"
                onClick={() =>
                  downloadFile(mAttachment?.url, mAttachment?.name)
                }
                download={true}
              />
            </Row>
          </Col>
        </Col>
      </Tooltip>
    </Col>
  );
};

export default React.memo(FileItem);
